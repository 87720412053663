export const getProjectCardList = () => {
  const projectListOne = [
    {
      title: '国电投内蒙古公司',
      content: '调运指挥中心项目',
      img: require('@/assets/img/project/projectList/project1.png'),
      images: [
        require('@/assets/img/project/projectDetail1/image1.png'),
        require('@/assets/img/project/projectDetail1/image2.png'),
        require('@/assets/img/project/projectDetail1/image3.png'),
        require('@/assets/img/project/projectDetail1/image4.png'),
      ],
      detail:
        '项目通过构建基于互联网+、大数据、云计算等技术的调运指挥中心中枢平台，以智慧运销管理系统为支撑，对煤炭产销计划、请车调度、装车发运以及交付结算等业务环节实时动态管控，同时整合集团产、运、销、储、用各板块业务数据资源，实现煤炭全供应链数据共享，促进产业协同融合发展，推动运销管理数字化转型战略实施。',
    },
    {
      title: '晋能控股',
      content: '煤炭运销监管平台',
      img: require('@/assets/img/project/projectList/project2.png'),
      images: [
        require('@/assets/img/project/projectDetail2/image1.png'),
        require('@/assets/img/project/projectDetail2/image2.jpeg'),
        require('@/assets/img/project/projectDetail2/image3.png'),
      ],
      detail:
        '为解决集团汽运煤监管不到位、动态管理困难等问题，项目以集团公司《煤炭销售集中统一管理办法》为指导，规划煤炭运销管理数字化升级解决方案。以煤炭运销监管系统为中心平台，配合汽运煤监控系统、无人值守磅房系统、视频监控装车系统、红外防作弊系统等外部子系统，将集团公司与煤矿、洗煤厂、发运站、港口、驻外分公司的业务流程实现有机互联和数据全面共享，全面掌握采购、合同、计划、汽运、船运及联运等运销业务，实现运销业务全流程数字化管理。',
    },
    {
      title: '陕西能源集团',
      content: '凉水井煤矿无人调运项目',
      img: require('@/assets/img/project/projectList/project3.png'),
      images: [
        require('@/assets/img/project/projectDetail3/image1.png'),
        require('@/assets/img/project/projectDetail3/image2.png'),
        require('@/assets/img/project/projectDetail3/image3.jpeg'),
      ],
      detail:
        '项目以智能化安全调运流程控制作业准确、高效为目标，通过建设煤场无人安全调运信息化系统，实现车辆自动化排队、调拨单管理、过磅管理、远程集控装车自动化管理、销售管理的标准化、电子化和集成化；规范各业务节点流程实现闭环管理，加快向专业化、自动化、智能化方向发展。',
    },
    {
      title: '陕煤集团',
      content: '红柳林煤矿运销自动化项目',
      img: require('@/assets/img/project/projectList/project4.png'),
      images: [
        require('@/assets/img/project/projectDetail4/image1.jpeg'),
        require('@/assets/img/project/projectDetail4/image2.png'),
        require('@/assets/img/project/projectDetail4/image3.png'),
      ],
      detail:
        '红柳林煤矿项目以实际运销业务入手，从客户调煤量下发、客户派车、进矿车辆排队、无人值守过磅、远程集控装车自动化、矿区车辆自动化运行控制、结算、统计等环节建设一套智能化运销装车管理信息系统。实现了“现场无人值守、远程集控智能化装车”等目标，使煤矿销售、运输、排队、称重、装车等业务专业化、自动化、智能化。通过该系统实现降本增效，企业的日常业务在信息系统的协助下得以变得高效、快捷。让管理公司在集成化的办公平台下实现数据、单据、报表、等资源的共享。并对煤炭流向和销售数据进行分析，实现煤炭销售的精细化管理进而便于销售决策。',
    },
    {
      title: '中国华能集团',
      content: '照金煤电燃料调运管理平台',
      img: require('@/assets/img/project/projectList/project5.png'),
      images: [
        require('@/assets/img/project/projectDetail5/image1.jpeg'),
        require('@/assets/img/project/projectDetail5/image2.png'),
        require('@/assets/img/project/projectDetail5/image3.png'),
      ],
      detail:
        '该项目针对华能铜川照金煤电有限公司存在的供煤单位多、运输线路长、运输过程难管控、运输车辆质量参差不齐等问题，从电厂燃料调运实际出发进行调研与分析，研发出适配电厂实际情况的大宗货物智能全闭环管理系统，解决了企业在燃料调运中计划调度、拉煤区域与线路监控、位置服务、信息反馈、数据共享等需求，通过对计划量、车辆进行统一调度信息管理，实现了电厂煤炭调运的安全化、透明化、信息化、智能化管理目标。该项目在2018年由集团各专家组织通过燃料调运闭环管理平台通过集团升级2.0的验收工作，并被评为华能集团“标杆中的标杆”，其中我公司提供的燃料调运闭环管理平台深受专家的一致好评。',
    },
    {
      title: '华电新疆发电公司',
      content: '固（危）废运输车辆实时监管平台',
      img: require('@/assets/img/project/projectList/project6.png'),
      images: [
        require('@/assets/img/project/projectDetail6/image1.png'),
        require('@/assets/img/project/projectDetail6/image2.png'),
      ],
      detail:
        '该项目系统建设，通过对华电新疆发电有限公司所属基层单位物料运输车辆进行定位及实时跟踪，实现对物料的运输、储存、处置合规性的有效监督。整体项目周期两年，分阶段具体完成了针对华电公司5家火电企业负责运输粉煤灰、炉渣等固体废物的约132辆运输车辆安装定位设备，并接入运输车辆实时监督管理平台，享受网络平台及流量和服务。',
    },
  ]
  const projectListTwo = [
    {
      title: '太原东山煤电集团',
      content: '智能运销称重管理系统',
      img: require('@/assets/img/project/projectList/project7.png'),
      images: [
        require('@/assets/img/project/projectDetail7/image1.jpeg'),
        require('@/assets/img/project/projectDetail7/image2.jpeg'),
        require('@/assets/img/project/projectDetail7/image3.jpeg'),
      ],
      detail:
        '东山集团智能称重管理系统的设计思想是为煤矿现场管理提供一个全面的信息管理平台，涵盖集团运销系统、视频监控平台、智慧大屏、竞价平台、无人值守系统。具体帮助客户实现了平台自助发货派车、车辆自助验证入厂、远程筒仓装车控制、自动控制超载、业务报表分析、平台竞价信息发布、合同跟踪及结算支付等功能。',
    },
    // {
    //   title: '保当物流',
    //   content: '大保当集装箱物流无人值守称重系统',
    //   img: require('@/assets/img/project/projectList/project8.png'),
    //   images:[
    //     require('@/assets/img/project/projectDetail8/image1.png'),
    //     require('@/assets/img/project/projectDetail8/image2.png'),
    //     require('@/assets/img/project/projectDetail8/image3.png'),
    //   ],
    //   detail:'大保当物流园区拥有国内先进的自动受煤系统、自动立体仓储系统，自动场内导引车系统以及集装箱堆场自动化信息管理系统，年发运能力1000万吨，远期达到1200万吨，承载能力，装卸效率和运输能力都处于先进水平。本次项目建设大保当物流园区采销业务的整体运销系统，以及14条汽车衡的无人值守过磅，涵盖合同管理、计划管理、财务管理、生产管理、库存管理、化验管理、磅单管理、费用管理、报表管理等功能。'
    // },
    {
      title: '世德集团世忻铁路',
      content: '冯家川站台无人值守称重系统',
      img: require('@/assets/img/project/projectList/project9.png'),
      images: [
        require('@/assets/img/project/projectDetail9/image1.jpeg'),
        require('@/assets/img/project/projectDetail9/image2.jpeg'),
        require('@/assets/img/project/projectDetail9/image3.jpeg'),
      ],
      detail:
        '兴保铁路所处的位置是晋、陕、蒙煤炭资源的核心位置，下游与中南出海通道瓦日铁路瓦塘站接轨，上游与神（木）瓦（塘）铁路冯（家川）红（柳林）段相连。工业园区的集运装车站是山西省乃至国内同类项目中最大的大型集运站，最大存储能力达54万吨。2020年发运量为2513万吨，近期运量可达3000万吨，远期可增为4500万吨，是保德、河曲及周边地区煤炭外运主要通道和集运线，也是山西省煤炭外运通道的重要组成部分。本次项目建设涵盖园区18条汽车衡的无人值守，以及自动采样系统对接，实现园区汽运销售采购无人值守一体化，司机不下车即可完成全链条业务，大幅提升了站台运行效率。',
    },
    {
      title: '淮南矿业',
      content: '煤炭运销管理系统',
      img: require('@/assets/img/project/projectList/project10.png'),
      images: [
        require('@/assets/img/project/projectDetail10/image1.jpeg'),
        require('@/assets/img/project/projectDetail10/image2.png'),
      ],
      detail:
        '建设集团公司煤炭营销统一业务管理信息系统，覆盖本土、西部及贸易三个煤炭板块业务，实现运销全过程管理。在合同、计划、调度、发运、结算等各关键业务环节进行流程化管控，规范业务流程，控制风险，提升管理水平，为构建大营销体系提供信息平台保障。具体实现了运销全过程管理与风险控制、调度控制实时化及多样化统计分析。',
    },
  ]

  return { projectListOne, projectListTwo }
}

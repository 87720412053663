import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Table, Rate, Empty } from 'ant-design-vue/es';
import { checkShipmentCommentDriver } from '@/API/modules/common';
export default defineComponent({
  components: {
    Empty,
    'a-table': Table,
    'a-rate': Rate
  },
  setup() {
    const columns = [{
      title: '评价人',
      width: 260,
      dataIndex: 'driverCode',
      key: 'driverCode'
    }, {
      title: '评价对象',
      dataIndex: 'consignerName',
      width: 280,
      key: 'consignerName'
    }, {
      title: '评分',
      width: 260,
      dataIndex: 'consignerCode',
      key: 'consignerCode'
    }, {
      title: '评价内容',
      key: 'content',
      width: 260,
      dataIndex: 'content'
    }, {
      title: '评价单号',
      dataIndex: 'shipmentCode',
      key: 'shipmentCode',
      width: 200
    }, {
      title: '评价时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      width: 180
    }];
    function createTableData() {
      const list = [];
      // for (let index = 0; index < 100; index++) {
      //   const o = {
      //     key: index + '',
      //     name: 'John Brown ' + index,
      //     age: 5 + index,
      //     address: '',
      //     tags:
      //       index +
      //       '运费支付慢,装卸货地点不真实,克扣运费,卸货等待时间长,装货等待时间长时间长,装货等待时间长====',
      //   }
      //   list.push(o)
      // }
      return list;
    }
    const data = createTableData();
    const state = reactive({
      columns,
      data
    });
    onMounted(() => {
      checkShipmentCommentDriver().then(res => {
        state.data = res;
      });
    });
    return {
      ...toRefs(state)
    };
  }
});
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-66cdd7b0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "press-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SonBannerTabs = _resolveComponent("SonBannerTabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "banner-box",
    style: _normalizeStyle({
      height: _ctx.bannerHeight
    })
  }, [_createElementVNode("div", {
    class: "banner-name me-max-title dis-space-4",
    style: _normalizeStyle({
      padding: _ctx.boxPadding
    })
  }, " 新闻中心 ", 4), _createVNode(_component_SonBannerTabs, _mergeProps({
    ref: "bannerTabsRef"
  }, {
    tabList: _ctx.tabList,
    componentName: _ctx.componentName
  }, {
    onCutTab: _ctx.handleCutTab
  }), null, 16, ["onCutTab"])], 4), (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentActiveMenu)))]);
}
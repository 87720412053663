const getFinanceCardList = function () {
    const industryList = [
      {
        title: '核心节点流转效率低下',
        content: '采购、生产制造、仓储、运输等节点数据零散、系统关联度低，企业间信息不透明，交易融资环节需反复验证',
      },
      {
        title: '风险评估控制难',
        content: '物流运输信息化程度低，非标纸质单据，数据共享难，形成数据孤岛',
      },
      {
        title: '中小企业融资难',
        content: '传统供应链金融通常服务于核心企业及其直接关联的上下游，对于供应链环节中的其他中小企业的融资需求通常无法满足',
      },
      {
        title: '业务管理及运营复杂',
        content: '供应链涉及众多业务环节，细分产业运作流程又各不相同，上下游企业履约能力参差等等都造成了管理及运营的复杂局面',
      },
    ]
    const safeness = [
      {
        title: '额度高',
        content: '个人车队长最高可贷1000w',
        img:require('@/assets/img/product/finance/finance-safeness1.png')
      },
      {
        title: '全线上',
        content: '授信、用款、还款全部通过线上完成',
        img:require('@/assets/img/product/finance/finance-safeness2.png')
      },
      {
        title: '体验好',
        content: '直接授信无需担保抵押，按天计息，额度循环使用',
        img:require('@/assets/img/product/finance/finance-safeness3.png')
      }
    ]
    const loanList = [
      { 
        content: '登录运销宝企业端',
      },
      { 
        content: '额度申请',
      },
      { 
        content: '合同签署',
      },
      { 
        content: '风控审核',
      },
      { 
        content: '线上放贷',
      },
    ]
    const loanAdv = [
      {
        title: '快速到账',
        content: '基于货达平台授信极速通过，最快15分钟到账，省时省力',
        img:require('@/assets/img/product/finance/finance-jd2.png')
      },
      {
        title: '全站式服务',
        content: '客户经理全流程跟进指导，为企业定制专属资金解决方案',
        img:require('@/assets/img/product/finance/finance-jd3.png')
      },
      {
        title: '还款灵活',
        content: '随借随还，快捷便利 按日计息，一天起借',
        img:require('@/assets/img/product/finance/finance-jd4.png')
      }
    ]
    return {industryList, safeness, loanList,loanAdv}
  }
  
  export { getFinanceCardList }
  
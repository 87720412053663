import "ant-design-vue/es/carousel/style/css";
import _Carousel from "ant-design-vue/es/carousel";
import "ant-design-vue/es/button/style/css";
import _Button from "ant-design-vue/es/button";
import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, computed, ref } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import { Goods } from '@/utils/scrollWatch';
export default defineComponent({
  components: {
    'a-button': _Button,
    'a-carousel': _Carousel,
    LeftCircleOutlined,
    RightCircleOutlined
  },
  props: {
    newsCards_one: {
      type: Array,
      default: []
    },
    newsCards_two: {
      type: Array,
      default: []
    },
    newsCards_three: {
      type: Array,
      default: []
    },
    newsCards_four: {
      type: Array,
      default: []
    },
    newsCards_five: {
      type: Array,
      default: []
    },
    newsCards_Six: {
      type: Array,
      default: []
    },
    newsCards: {
      type: Object,
      default: {}
    },
    pageType: {
      type: String,
      default: 'company'
    }
  },
  setup(props) {
    const sessionGridShowTime = Goods.getters('pressGrid');
    const ssessionPressShowTime = Goods.getters('sessionPress');
    const windowGlobal = Goods.getters('windowGlobal');
    const height = ref(Number(windowGlobal.windowHeight) - 60 + 'px');
    const grid = computed(() => {
      return sessionGridShowTime.value;
    });
    const router = useRouter();
    const goNewsDetail = id => {
      router.push({
        path: '/newsDetail',
        query: {
          id,
          pageType: props.pageType,
          listLength: props.newsCards_one.length + props.newsCards_two.length + props.newsCards_three.length
        }
      });
    };
    const newsGather = reactive({
      goNewsDetail,
      sectionDataType: '',
      height,
      getSectionDataType: function (data) {
        if (typeof data === 'string') {
          newsGather.sectionDataType = 'string';
          return 'string';
        } else if (typeof data === 'object') {
          if (data.title) {
            newsGather.sectionDataType = 'title';
            return 'title';
          }
        }
      }
    });
    return {
      grid,
      ssessionPressShowTime,
      ...toRefs(newsGather)
    };
  }
});
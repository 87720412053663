import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NewsListShow = _resolveComponent("NewsListShow");
  return _openBlock(), _createBlock(_component_NewsListShow, _normalizeProps(_guardReactiveProps({
    newsCards_one: _ctx.newsCards_one,
    newsCards_two: _ctx.newsCards_two,
    newsCards_three: _ctx.newsCards_three,
    newsCards_four: _ctx.newsCards_four,
    newsCards: _ctx.newsCards,
    newsCards_five: _ctx.newsCards_five,
    newsCards_Six: _ctx.newsCards_Six,
    pageType: 'company'
  })), null, 16);
}
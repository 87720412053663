import _ from 'lodash'
import { message } from 'ant-design-vue/es'
const Debounce = (fn, wait?, option?) => {
  const f = _.debounce(fn, wait, option)
  return f
}
const Throttle = (fn, wait?, option?) => {
  const f = _.throttle(fn, wait, option)
  return f
}

message.config({
  top: '100px',
  duration: 2,
  maxCount: 3,
})

const successMssage = (msg, duration?) => {
  message.success(msg, duration)
}

const errorMssage = (msg, duration?) => {
  message.error(msg, duration)
}

export { Debounce, Throttle, successMssage, errorMssage }

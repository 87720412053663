import { getServiceImagePath } from '@/utils'

export function useNewsList() {
  const newsCards_one = [
    {
      newsImgSrc: require('@/assets/img/press/enterprise20240119/20240119-01.png'),
      newsTitle: '微光已现，何惧远航丨货达销售中心2023年终会议',
      newsYearAndMonth: '2024-01',
      newsDays: '19',
      newsId: 1,
    },
    {
      newsImgSrc: getServiceImagePath('press/enterprise20231106/20231106-1.png'),
      newsTitle: '鏖战年终丨货达集团2023年Q4季度销售大会启动冲刺之战',
      newsYearAndMonth: '2023-11',
      newsDays: '06',
      newsId: 2,
    },
    {
      newsImgSrc: getServiceImagePath('press/enterprise20230807/0807-1.png'),
      newsTitle: '素拓团建丨货达网络项目中心夏日消暑计划，不负美好时光',
      newsYearAndMonth: '2023-08',
      newsDays: '07',
      newsId: 3,
    },

    {
      newsImgSrc: getServiceImagePath('press/enterprise2023-07-10/2023-07-10-1.jpeg'),
      newsTitle: '“聚平凡，创不凡” —— 货达2023年中会议及团队拓展活动圆满结束！',
      newsYearAndMonth: '2023-07',
      newsDays: '10',
      newsId: 4,
    },

    {
      newsImgSrc: getServiceImagePath('press/newsList/enterprise/news1.jpg'),
      newsTitle: '“码出未来，码到橙功”——NO BUG!1024货达程序员节',
      newsYearAndMonth: '2022-10',
      newsDays: '24',
      newsId: 5,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/enterprise/news3.jpg'),
      newsTitle: '温情中秋，暖意货达——直击中秋活动现场！',
      newsYearAndMonth: '2022-9',
      newsDays: '10',
      newsId: 6,
    },
  ]

  const newsCards_two = [
    {
      newsImgSrc: getServiceImagePath('press/newsList/enterprise/news2.jpg'),
      newsTitle: '抓住八月的尾巴，DIY玩转集体生日会',
      newsYearAndMonth: '2022-8',
      newsDays: '30',
      newsId: 7,
    },
  ]
  return { newsCards_one, newsCards_two }
}

import { Goods } from "../scrollWatch"
const figureEnertgyScroll = (scroll) => {
    scroll('station', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('station', { showTime: showTime })
        const team = Goods.getters('station')
    })
    scroll('target', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('target', { showTime: showTime })
        const team = Goods.getters('target')
    })
}

export default figureEnertgyScroll
import { createApp, App } from 'vue'
import appView from './mobile.vue'
import router from './router/mobile'
import '@/styles/less/main-mobile.less'
import '@/styles/fonts/iconfont.css'
import setupRequest from '@/plugins/axios'
import countTo from 'vue3-count-to'
const app: App = createApp(appView)
app.use(countTo)
app.use(router)

setupRequest({ app, router })
app.mount('#mobile')

import { Goods } from "../scrollWatch"
const pressScroll = (scroll) => {
    scroll('company-info', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('companyInfo', { showTime: showTime })
    })
    scroll('honor-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('honorContent', { showTime: showTime })
    })
    scroll('course-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('courseContent', { showTime: showTime })
    })
    scroll('connect-us', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('connectUs', { showTime: showTime })
    })
}

export default pressScroll
import { getServiceImagePath } from '@/utils'

export function useNewsOneShow() {
  const newsCards = {
    newsImgSrc: require('@/assets/img/press/enterprise20240119/20240119-01.png'),
    // newsImgSrc: getServiceImagePath('press/enterprise20231106/20231106-1.png'),
    newsOneTitle: '微光已现，何惧远航丨货达销售中心2023年终会议',
    newsTowTitle: '',
    newsContent: `
    1月18日，<span class="font-bold">西安货达网络科技有限公司</span>（以下简称“货达网络”）2023年销售中心年终会议圆满举办。来自各大区的销售团队、业务负责人等团聚一堂，携手回顾2023年丰硕工作成果，共同迎接甲辰龙年新气象，擘画未来发展蓝图！
    `,
    newsYearAndMonth: '2024-01',
    newsDays: '19',
    newsId: 1,
  }

  //   const newsCards = {
  //     newsImgSrc: require('@/assets/img/press/enterprise2023-07-10/2023-07-10-1.jpeg'),
  //     newsOneTitle: '“聚平凡，创不凡”',
  //     newsTowTitle: '—— 货达2023年中会议及团队拓展活动圆满结束！',
  //     newsContent:
  //       '2023年7月10日，西安货达网络科技有限公司（以下简称“货达网络”）2023年中会议在西安高新希尔顿酒店圆满举办。来自全国各地的销售团队、大区经理等业务代表齐聚一堂，与总部同事们一起回顾上半年的工作情况，共同为开启下半场“接力赛”打响头阵！',
  //     newsYearAndMonth: '2023-07',
  //     newsDays: '10',
  //     newsId: 1,
  //   }
  return { newsCards }
}

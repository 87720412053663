import { createNameComponent } from '../createNode'
const mobileUsRoute = [
  {
    path: '/aboutHuoda',
    name: 'AboutHuoda',
    component: createNameComponent(() => import('@/views-mobile/us/aboutHuoda/index.vue')),
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    component: createNameComponent(() => import('@/views-mobile/us/joinUs/index.vue')),
  }
]
export default mobileUsRoute

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "title-wrap"
};
const _hoisted_3 = {
  class: "me-sub-title title"
};
const _hoisted_4 = {
  class: "date"
};
const _hoisted_5 = {
  class: "article-wrap"
};
const _hoisted_6 = {
  class: "article-c",
  id: "arcical-detail"
};
const _hoisted_7 = {
  class: "section-wrap"
};
const _hoisted_8 = ["innerHTML"];
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = {
  key: 1,
  class: "inscribe"
};
const _hoisted_11 = {
  key: 2,
  class: "inscribe-title"
};
const _hoisted_12 = {
  class: "img-wrap"
};
const _hoisted_13 = {
  key: 0
};
const _hoisted_14 = ["src"];
const _hoisted_15 = ["src"];
const _hoisted_16 = {
  key: 2,
  class: "img-bottom-label"
};
const _hoisted_17 = {
  key: 1
};
const _hoisted_18 = {
  class: "handle-wrap me-content-lt"
};
const _hoisted_19 = ["src"];
const _hoisted_20 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImgPanel = _resolveComponent("ImgPanel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.newsDetailData[_ctx.id].title), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.newsDetailData[_ctx.id].date), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsDetailData[_ctx.id].article, (articleItem, articleIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: articleIndex
    }, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articleItem.section, (textItem, textIndex) => {
      return _openBlock(), _createElementBlock("div", {
        class: "section me-content-lt",
        key: textIndex
      }, [_ctx.getSectionDataType(textItem) === 'string' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: {
          "margin-top": "5px"
        },
        class: "section-item-content",
        innerHTML: textItem
      }, null, 8, _hoisted_8)) : _createCommentVNode("", true), _ctx.getSectionDataType(textItem) === 'title' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "section-title me-content-lt",
        style: _normalizeStyle(textItem.style)
      }, _toDisplayString(textItem.title), 5)) : _createCommentVNode("", true)]);
    }), 128))]), articleItem.sonTitle ? (_openBlock(), _createElementBlock("h2", {
      key: 0,
      style: {
        "text-align": "center"
      },
      innerHTML: articleItem.sonTitle
    }, null, 8, _hoisted_9)) : _createCommentVNode("", true), articleItem.inscribe ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(articleItem.inscribe), 1)) : _createCommentVNode("", true), articleItem.inscribeTitle ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(articleItem.inscribeTitle), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articleItem.sectionImg, (imgItem, imgIndex) => {
      return _openBlock(), _createElementBlock("div", {
        key: imgIndex
      }, [Array.isArray(imgItem) ? (_openBlock(), _createElementBlock("div", _hoisted_13, [!imgItem[2] ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: imgItem[0],
        alt: "#"
      }, null, 8, _hoisted_14)) : _createCommentVNode("", true), imgItem[2] ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: imgItem[0],
        alt: "#",
        style: _normalizeStyle(imgItem[2])
      }, null, 12, _hoisted_15)) : _createCommentVNode("", true), imgItem[1] ? (_openBlock(), _createElementBlock("label", _hoisted_16, _toDisplayString(imgItem[1]), 1)) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_17, [(_openBlock(), _createBlock(_component_ImgPanel, {
        path: imgItem,
        key: {
          imgItem
        }
      }, null, 8, ["path"]))]))]);
    }), 128))])]);
  }), 128))])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", {
    class: "back-page",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.upPage && _ctx.upPage(...args))
  }, [_createElementVNode("img", {
    src: require('@/assets/img/press/back.png'),
    alt: ""
  }, null, 8, _hoisted_19), _createTextVNode(" 上一篇")]), _createElementVNode("div", {
    class: "back-list",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.backList && _ctx.backList(...args))
  }, "返回列表"), _ctx.id !== _ctx.listLength ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "next-page",
    onClick: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.nextPage && _ctx.nextPage(...args))
  }, [_createTextVNode("下一篇"), _createElementVNode("img", {
    src: require('@/assets/img/press/up.png'),
    alt: ""
  }, null, 8, _hoisted_20)])) : _createCommentVNode("", true)])]);
}
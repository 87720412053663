// <span style="font-weight: bold;">
//<label class="primary-color">
//sonTitle

import { getServiceImagePath } from '@/utils'

export function useNewsCompanyDetail() {
  const newsCompanyDetailData = {
    1: {
      title: '货达网络携手陕西交控举办第二届“货车积分推广活动”圆满收官',
      date: '2024/01/23',
      article: [
        {
          section: [
            `1月23日，由<span class="font-bold">陕西交通控股集团有限公司指导、陕西交控运营管理公司主办</span>，西安货达网络科技有限公司参与协办的“货车积分推广”活动圆满落下帷幕。活动以回馈广大司乘为初心，以<span class="font-bold">“货车走高速，积分豪礼送不停”为主题，通过“</span>高速里程赚积分，积分可以当钱花”，为广大货车车主派送出5000余万元权益豪礼，带来实际益处和福利。`,
          ],
          sectionImg: [require('@/assets/img/press/new20240123/20240123-01.png')],
        },
        {
          section: [
            `
            <span class="font-bold">共同推动货运行业数字化转型，普惠高速货车车主。</span>此次“货车积分推广”活动主要依托“陕易通”小程序进行，通过每日抽奖、走进物流企业、企业帮、高速互助会和高速讲堂等多样活动举措综合提升服务。
            `,
            `
            活动自2023年10月20日开幕至2024年1月23日结束，<span class="font-bold">累计组织织了8场公益活动，共计70余家媒体参与宣传报道，吸引了4000余家物流企业与40余万名用户通过线上、线下形式参与。</span>让广大卡友们在享受高速通行便利的同时，还可以收获贴心关怀与丰厚福利，增强货车司机的获得感、幸福感和归属感。
            `,
          ],
          sectionImg: [require('@/assets/img/press/new20240123/20240123-02.png')],
        },
        {
          section: [
            `作为一家专注于大宗商品流通领域的产业互联网集团企业，<span class="font-bold">货达网络一直致力于为广大货主及车主朋友们提供便捷高效的物流解决方案。</span>我们深知货车积分推广活动对于陕西交通运输行业的发展具有重要意义。因此，货达充分发挥自身优势，为活动的顺利进行提供了全方位的支持。
            `,
            `
            货达网络数字货运平台<span class="font-bold">运销宝</span>以专业的应用平台和丰富的物流资源，同时帮助参与活动的卡友们提供业务服务。通过“运销宝”平台，司机可以轻松接单、查看线路、结算支付，另有车后服务带来不少便利，有效提高了整体运输效率。
            `,
          ],
          sectionImg: [require('@/assets/img/press/new20240123/20240123-03.png')],
        },
        {
          section: [
            `
            未来，货达将持续关注广大卡友车主的业务需求与服务体验，提供更优质的物流及供应链解决方案。同时，助力加快推进<span class="font-bold">“智慧交通”</span>建设，为实现业态更优、实力更强、效益更好、发展更可持续的交通运输生态体系而不懈努力！
              `,
          ],
        },
      ],
    },
    2: {
      title: '全国示范丨货达入选工信部“第五批服务型制造示范”名单',
      date: '2023/11/27',
      article: [
        {
          sectionImg: [getServiceImagePath('press/new20231127/2023-11-27-1.png')],
          section: [
            `近日，工业和信息化部公示了第五批服务型制造示范名单。货达旗下“数字供应链平台”（货达数智创新平台）<span class="font-bold">成功上榜服务型制造示范平台名单，是陕西省唯一一个入选第五批示范平台的大宗供应链案例。</span>`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20231127/2023-11-27-2.png')],
          section: [
            `<span class="font-bold">服务型制造</span>是一种将制造与服务融合发展的新产业形态，其将生产的产品经济和基于消费的服务经济有机结合，是基于制造的服务和面向服务的制造。发展服务型制造，是增强产业链韧性的重要抓手，是制造业转型变革的大势所趋，也是企业拓展盈利空间、重塑市场新竞争力的必由之路。`,
            `<span class="font-bold">货达数字供应链平台</span>深入生产资料行业应用场景，不断通过物联设备与信息数据交互，实现“产运销”上下游核心环节数据联通和业务协同，促进以数据驱动生产运营的闭环优化。同时有效整合生态资源，为大宗生产型企业在业务规范、降本、提质、增效等多方面创造价值`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20231127/2023-11-27-3.png')],
          section: [
            `2022年，货达就曾成功入选工信部<span class="font-bold">《大数据产业发展试点示范项目名单》</span>。时隔一年，此次再荣获第五批“服务型制造示范平台”称号，是对货达网络深耕大宗供应链产业链领域取得成果的肯定和鼓励，也是对公司发展近十载的实力认证。这无疑激励着我们继续秉承“以客户价值为核心”的服务理念，加大技术创新和服务升级力度，持续赋能客户成功。`,
            `<span class="font-bold">未来，货达将继续发挥平台的引领作用，同时依托平台生态，以数智化建设作为发展趋势导向。</span>积极响应国家关于推动制造业高质量发展的号召，携手广大客户和合作伙伴，共同探索数字化供应链的无限可能，为建设制造强国提供有力支撑！`,
          ],
        },
      ],
    },
    3: {
      title: '喜讯丨货达网络荣获2023年陕西省“专精特新”企业荣誉',
      date: '2023/11/21',
      article: [
        {
          sectionImg: [getServiceImagePath('press/new20231121/2023-11-21-1.png')],
          section: [
            `11月21日，陕西省工信厅发布《关于2023年陕西省“专精特新”中小企业拟认定和复核通过名单的公示》，<span class="font-bold">西安货达网络科技有限公司成功入选榜单，荣获“专精特新”企业称号。</span>`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20231121/2023-11-21-2.png')],
          section: [
            `<span class="font-bold">专精特新，即专业化、精细化、特色化、新颖化。</span>陕西省“专精特新”中小企业是指经陕西省工业和信息化厅认定，专注于细分市场、掌握关键核心技术、创新能力强、市场占有率高、质量效益优，在专业化、精细化、特色化、新颖化等方面表现突出的中小企业。其代表认定企业在产业链上具备一定的话语权，聚焦核心主业，创新能力和抗风险能力较强，同时在细分行业领域已经建立了竞争优势。`,
            `此次获评，标志着货达在数字化建设、精细化管理、特色化产品服务、创新化技术方案等多方面取得了良好成果，也为公司今后的高质量发展奠定了重要基础。`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20231121/2023-11-21-3.png')],
          section: [
            `长期以来，货达以新一代信息技术赋能产业链上下游业务场景，持续投入信息化、数字化产品的研发、推广以及创新应用。围绕“发运收”核心环节打造<span class="font-bold">运销宝数字货运平台</span>，整合百万运力，现已成为西北地区规模最大的网络货运平台。同时聚焦数字运销等一体化业务场景解决方案，在煤炭、电力、化工、铁路货场等典型场景下均有成功实践案例。`,
            `<span class="font-bold">下一步，货达将坚持走好“专精特新”发展之路。</span>立足大宗供应链产业链，不断夯实物流数字底座建设基础，针对“断点”、“堵点”等空白领域持续深耕，聚焦主业、苦练内功、强化创新，抢抓新一轮数字科技变革和产业转型升级的机遇，为客户提供更优产品、更好服务，助推产业高质量可持续性发展！`,
          ],
        },
      ],
    },
    4: {
      title: '发展动态丨货达集团参与制定《煤炭数字化仓库基本要求》团体标准顺利通过审查',
      date: '2023/11/03',
      article: [
        {
          section: [
            `11月3日，中国物流与采购联合会副会长兼秘书长、团体标准化技术委员会主任崔忠付在京主持召开《钢铁数字化仓库基本要求》（项目编号：2021-TB-016）《煤炭数字化仓库基本要求》（项目编号：2021-TB-017）两项团体标准项目的审查会。`,
          ],
          sectionImg: [getServiceImagePath('press/new20231103/20231103-1.png')],
        },
        {
          section: [
            `这两项标准是中物联于2021年第四季度下达的团体标准项目计划，由中物联物联网技术与应用专业委员会组织相关单位起草。来自相关协会、大专院校、科研院所、企业的专家组成的审查组在听取标准起草组对标准制定总体情况、主要技术内容及其来源和依据、征求意见情况及处理的介绍后，对标准逐条进行了审查，提出了修改意见和建议。最后专家一致同意通过了标准审查。`,
          ],
          sectionImg: [getServiceImagePath('press/new20231103/20231103-2.png')],
        },
      ],
    },
    5: {
      title: '货达网络CEO折大伟受邀出席榆林市青年民营企业家代表座谈会并作发言',
      date: '2023/10/13',
      article: [
        {
          section: [
            `<span style="font-weight: bold;">10月11日，榆林市青年民营企业家代表座谈会举行</span>。市委书记张晓光，市委常委、统战部部长王华胜，市发改委、市工信局、市市场监管局、市行政审批局等主要负责人，市委统战部、市工商联领导班子及全市企业家代表共同参会。<span style="font-weight: bold;">货达网络创始人兼CEO 折大伟受邀出席本次座谈，并发表汇报演讲。</span>`,
          ],
          sectionImg: [getServiceImagePath('press/new20231013/20231013-1.png')],
        },
        {
          section: [
            `<span style="font-weight: bold;">市委书记张晓光在讲话中强调，全市青年民营企业家要牢记肩上的责任和使命，传承老一辈榆林企业家艰苦奋斗、敢为人先、勇于担当、义利兼顾的精神，努力在高质量发展、现代化建设中当先锋、作表率、立新功。</span>`,
            `<span style="font-weight: bold;">市委常委、统战部部长王华胜主持座谈会。</span>市发改委、工信、行政审批等相关部门负责人分别围绕全市经济社会发展和营商环境、支持民营经济高质量发展、提高政务服务效能等方面进行了介绍。与会青年民营企业家作交流发言。`,
          ],
          sectionImg: [getServiceImagePath('press/new20231013/20231013-2.png')],
        },
        {
          section: [
            `<span style="font-weight: bold;">货达网络CEO 折大伟从公司深耕大宗物流领域近10年经验出发，针对货运物流数字化和工矿企业数字化发展，分享汇报了行业及公司现状。</span>`,
            `货达网络旗下<label class="primary-color">数字货运平台运销宝</label>，实现了物流从业主体、物流单据、资金结算、加油加气等全过程的在线化，<span style="font-weight: bold;">有效改造和推动了传统物流行业转型升级，提高了大宗商品供应链的韧性与可控性。</span>`,
            `同时，公司面向煤炭、电力、冶金、化工、建材、铁路货场等大宗商品领域，<label class="primary-color">通过软硬件一体化解决方案，深入链接实体企业</label>，<span style="font-weight: bold;">实现全流程“无人化”、“无纸化”、“智能化”，</span>以数字化管理系统与数据分析辅助决策，现已成为企业提升运营效率和降低成本的重要手段。`,
          ],
        },
        {
          sonTitle: '<label class="pass-title-red ">牢记责任使命，传承优秀精神</label>',
        },
        {
          section: [
            `货达网络是发源于榆林，在三秦大地上不断成长、发展壮大的企业。<span style="font-weight: bold;">未来，我们将持续专注于大宗商品流通领域内的降本增效，积极探索新能源、自动驾驶、碳交易等新技术、新模式在大宗物流领域的应用，</span>携手生态伙伴共同推动物流领域的精益化、智能化、绿色化发展，努力为国家数字经济高质量发展提供新动能！`,
          ],
        },
      ],
    },
    6: {
      title: '数实融合 链创未来丨货达网络受邀参加2023第四届中国供',
      date: '2023/09/28',
      article: [
        {
          section: [
            `9月27日，由商务部指导，中国物流与采购联合会、厦门市人民政府主办的<span style="font-weight: bold;">2023第四届中国供应链管理年会暨全国供应链创新与优秀成果展</span>在厦门国际会展中心盛大召开。本届展会聚焦国内供应链领域优秀龙头企业的先进经验和典型范例，旨在促进行业交流，共同推动大宗商品现代供应链发展、加速数实融合创新。<span style="font-weight: bold;">西安货达网络科技有限公司（以下简称“货达网络”）受邀参展，</span>展示了公司在大宗商品流通领域内联通上下游的一体化供应链解决方案服务能力及数字化建设成果。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230928/2023-09-28-1.png')],
        },
        {
          sonTitle: '数智创新，产业赋能',
        },
        {
          section: [
            `货达网络利用AIoT、大数据、云计算等新一代数字技术，通过软硬件一体化综合解决方案链接大宗产业上下游实体企业、贸易商等环节主体，<span style="font-weight: bold;">提供覆盖煤炭、电力、化工、建材等领域内贸易、物流、车后及金融全流程的数字供应链服务。</span>
            同时，货达基于自主研发的<label class="primary-color">“运销宝”数字货运平台</label>，构建起物流大数据平台及数据中台，深挖供应链上要素数据价值，不断以数智创新手段优化改造业务场景，实现供应链端到端协同共享、全流程透明可视。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230928/2023-09-28-2.png')],
        },
        { sonTitle: '稳链保供，工业互联' },
        {
          section: [
            `产业链供应链是工业经济的命脉，其稳定畅通对工业经济平稳运行至关重要。货达网络立足西北辐射全国，自2015年成立以来深耕能化产业，持续下沉大宗业务场景，同时加强数字底座建设，以节点数字化改造不断提升支撑服务能力。<label class="font-bold">在积极布局工业生产资料供应链产业过程中，以上下游延伸、左右链配套的方式，不断提升大宗商品供应链产业链韧性和安全水平，助力强链补链延链。</label>`,
            `货达网络持续优化配置产业资源，有机整合并调整业务结构，通过先进的技术与运营手段进一步提高生产效率、降低人力成本，解决了传统业务环节中的一系列痛点难点，为绿色低碳可持续发展增添助力，其创新成果惠及超过百万的物流及能化从业者。`,
          ],
        },
        {
          sonTitle: '提质增效，绿色发展',
        },
        {
          sectionImg: [getServiceImagePath('press/new20230928/2023-09-28-3.png')],
        },
        {
          section: [
            `数字科技赋能现代供应链创新发展是当前及未来的趋势， <label class="font-bold">货达网络将继续携手上下游生态伙伴，共同推动供应链产业链的精益化、协同化、智能化、绿色化发展，持续加深数字经济与实体经济的融合，为大宗商品流通及供应链管理发展注入新的活力！</label>`,
          ],
        },
      ],
    },
    7: {
      title: '货达网络与芜湖江北新区招商引资项目签约仪式成功举行',
      date: '2023/09/14',
      article: [
        {
          section: [
            `9月13日，《财富》中国揭晓了<span style="font-weight: bold;">“2023年中国最具社会影响力的创业公司榜单”，</span>入选企业以其科技与业务模式创新、突出的商业落地表现，展示出越来越强大的社会影响力。<span style="font-weight: bold;">货达网络作为大宗商品物流领域科创型互联网集团企业成功入选，是此次唯一上榜的数字供应链解决方案服务厂商。</span>`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230914/2023-09-14-1.png')],
        },
        {
          section: [
            `《财富》作为享誉全球的权威商业杂志，榜单设立目标在于找出并呈现目前中国“跨过初创期，以科技创新或商业模式创新为支撑进入高成长期，有效解决社会热点或难点问题的创业企业”。这些<span style="font-weight: bold;color:#27b57d;">“do well while doing good”</span>的公司，<span style="font-weight: bold;">首先已被证实在商业上是成功的。同时，其商业路径和业务内容本身就已经包含社会责任的要素，或者说，</span>这些企业的经营行为本身就是在履行某种社会责任。它们获得利润以及利润的增长，代表着它们推动世界改变的成功以及这一成功的延续。`,
            `<span style="font-weight: bold;">货达网络在取得商业成功的同时，也将社会责任纳入企业发展使命，致力于让大宗商品流通像快递一样简单。</span>长期以来，用数字科技推动大宗物流与供应链的转型升级，深入链接实体企业，优化配置社会零散资源，通过技术手段将人类从繁琐、冗杂、混乱的业务困境中解放出来，赋能超过百万的物流及能化从业者。一方面，<span style="font-weight: bold;">解决企业成本与效率问题，优化供应链管理与协同能力；</span>另一方面，也<span style="font-weight: bold;">解决了大宗商品物流行业痛点难点，加速上下游生态资源的整合与运用，推动行业数智化发展。</span>`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230914/2023-09-14-2.png')],
        },
        {
          section: [
            `<span style="font-weight: bold;">如今，货达不断完善产品矩阵体系，重视核心关键技术的突破与应用。</span>基于煤炭、电力、化工、建材、铁路货站等典型场景，围绕用户业务需求，打造数字运销、智能调运等系列软硬件一体化解决方案。服务客户超过5000家，业务遍及陕西、山西、内蒙古、甘肃、宁夏、新疆、河北、安徽等省市自治区。`,
            `此次入选《财富》“中国最具社会影响力的创业公司”榜单，既是对货达网络社会影响力及价值贡献的认可，也是对其推动行业发展，促进大宗物流与供应链数字化转型过程中取得多项成果的充分肯定。<span style="font-weight: bold;">未来，货达将坚持以技术创新提质增效，贯彻绿色低碳发展目标，加速数字货运新业态发展以及行业数字化解决方案落地，持续为产业转型升级贡献力量。</span>`,
          ],
        },
        {
          section: [`《财富》2023年“中国最具社会影响力的创业公司”完整榜单如下↓↓↓`],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230914/2023-09-14-3.png')],
        },
      ],
    },
    8: {
      title: '货达网络与芜湖江北新区招商引资项目签约仪式成功举行',
      date: '2023/09/11',
      article: [
        {
          section: [
            `近日，由一级市场及科创板领域的权威媒体《科创板日报》联合上海科学技术情报研究所（ISTIS）发起，星矿数据提供支持的2023科创先锋人物榜单正式发布。`,
            `历时5个月，通过信息收集、数据核实、多维度打分，<span style="font-weight: bold;">货达网络创始人、CEO折大伟作为大宗物流赛道内的科创新星，入选“未来数智领袖”榜单 ↓↓↓</span>`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230911/2023-09-11-1.png')],
        },
        {
          section: [
            `据悉，<span style="font-weight: bold;">该榜单</span>深度关注国内科创领域的企业家、投资人、地方产业基金、政府园区等各核心参与方，覆盖新一代信息技术、人工智能、智能制造、新能源、半导体等新兴科创领域，此次评选共产生4大榜单，75位科创先锋人物。其中，科学家创业先锋15位、硬科技商业领袖25位、女性科创先锋15位、未来数智领袖20位。`,
          ],
        },
        {
          sectionImg: [
            [getServiceImagePath('press/new20230911/2023-09-11-2.png'), '', { width: '50%' }],
          ],
        },
        {
          section: [
            `<span style="font-weight: bold;">作为货达网络创始人、CEO折大伟带领团队始终专注于大宗物流行业内的降本增效、绿色低碳发展。</span>他认为，数字技术帮助解决了传统大宗行业“零散乱”、 “效率低”、“信息不透明”等众多痛点问题。利用产数融合，让数字化下沉更多业务环节、物流节点，才能充分释放和发挥其价值优势，辅助决策从而推动企业增长。
            历经多年深耕，货达网络已成长为大宗商品物流领域内的知名品牌。<span style="font-weight: bold;">此次获评“未来数智领袖榜”，不仅是对折大伟科创及数智领袖能力的认可，同时也是对货达取得成果的肯定。</span>`,
            `未来，货达网络将持续探索以数字科技赋能大宗商品物流与供应链的数字化转型，以“数智化”撬动行业快速高质量发展！`,
          ],
        },
      ],
    },
    9: {
      title: '货达网络与芜湖江北新区招商引资项目签约仪式成功举行',
      date: '2023/09/06',
      article: [
        {
          section: [
            `2023年9月6日，西安货达网络科技有限公司（以下简称“货达网络”）与芜湖江北新区“双招双引”项目签约仪式在芜湖建筑科技产业园举行。货达网络CEO折大伟，芜湖市委副书记、市长徐志、市领导、市政府相关秘书长、市直部门、江北新区管委会有关负责人等共同出席本次活动。双方将结成战略合作伙伴关系，政企携手共同推动新区平台总部经济及生态伙伴高质量发展。`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230906/2023-9-6-1.png')],
        },
        {
          section: [
            `此次签约仪式是贯彻落实中央、国务院关于平台经济的决策部署，加强政企合作，互利互惠的核心要义。面向平台总部企业及生态伙伴，将在科技创新、经营服务、产业优化等方面提供全面支持。至此，标志着货达网络与芜湖市江北新区的招商引资合作迈入了新篇章，同时也为后续合作共赢、共谋发展拉开了崭新的帷幕。`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230906/2023-9-6-2.png')],
        },
        {
          section: [
            `在相关领导的陪同下，折大伟赴江北新区规划展示馆参观，了解有关新区发展布局、在建重点工程、产业培育及未来规划等情况。切身感受当地发展的创新活力后，折大伟对新区的发展潜力给予充分认可，同时表示，芜湖作为省域副中心，又是安徽发展最好最快的城市之一。在新能源、物联网、高端制造等新兴产业中占据发展高地，未来希望能够结合货达自身优势，深化战略合作，加快产融结合步伐，推动项目早日落地。`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230906/2023-9-6-3.png')],
        },
        {
          section: [
            `货达网络自成立以来，始终专注于大宗商品物流领域，聚焦数字货运核心业务，依托网络货运平台链接上下游各要素主体，持续下沉大宗能化产业链上业务场景，针对数字化解决方案积累了丰富的实践经验及成功案例，以专业标准推动企业高质量发展，为实体经济降本提质增效。`,
            `今后，货达将积极响应国家发展战略，抢抓平台经济发展机遇，进一步整合产业资源，持续投入创新研发及产品服务升级，全面构建现代数字供应链体系，推动数字经济与物流业、工业制造业深度融合。`,
          ],
        },
      ],
    },
    10: {
      title: '喜讯！货达网络旗下子公司通过5A级网络货运平台企业现场评估',
      date: '2023/09/04',
      article: [
        {
          section: [
            `2023年9月4日，中国物流与采购联合会（以下简称“中物联”）网络货运平台A级企业评估委员会第七次会议在京召开。会上，货达网络货运平台迎来了中物联等级评估委员会专家组，专家组由中物联副会长兼秘书长崔忠付、中物联副秘书长、评估办主任杨国栋等多位审核员组成。`,
            `经过严格审查，货达网络旗下子公司的运营规模、平台服务能力、管理能力、系统支撑能力、风控能力以及资质合规性均符合5A级网络货运企业必备指标要求，顺利通过现场评估。`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230904/2023-9-4-1.png')],
        },
        {
          section: [
            `网络货运平台企业评估工作以团体标准《网络货运平台服务能力评估指标》为依据，对于规范网络货运业态经营发展，提升企业服务能力和水平、树立行业标杆典范具有积极指导作用。在场委员们表示，希望网络货运企业能够进一步延伸拓宽业务领域辐射面，深挖业务价值和潜力，加大规范管理和宣传，通过数字化转型助力行业健康、有序发展。`,
            `长期以来，货达网络通过物联网、云计算、人工智能、大数据等新一代互联网信息技术，线上线下结合，链接实体企业与社会零散资源，为大宗货主企业提供智能发运、在途监管、结算支付等全链路物流与增值服务，打造集货主、物流企业、运力、车后市场、金融于一体的物流货运服务平台。2016年，即获选全国首批无车承运人试点企业；2020年，西安货达网络科技有限公司获评全国5A级网络货运企业。`,
          ],
        },
        {
          sectionImg: [getServiceImagePath('press/new20230904/2023-9-4-2.png')],
        },
        {
          section: [
            `公司旗下运销宝数字货运平台在功能服务、风险控制、业务合规等方面具备优势，开拓供应链金融板块，能够确保客户资金结算安全、便捷，从下单到跟踪到最后结算实现业务、信息、票据、资金、物流的“五流合一”，为客户提供一站式优质服务。
            未来，货达网络将以本次子公司顺利评估为新的起点，不忘初心，不断探索网络货运业态发展之下的新增长点，持续助力客户成功！`,
          ],
        },
      ],
    },
    11: {
      title: '精益管理丨货达网络携手金蝶，共同推进业财融合的企业数字化建设',
      date: '2023/08/16',
      article: [
        {
          sectionImg: [getServiceImagePath('press/new20230816/0816-1.png')],
        },
        {
          section: [
            `
            近日，西安货达网络科技有限公司 (以下简称“货达网络”）财务信息化建设项目启动会在总部西安成功召开。此次启动会标志着货达网络与金蝶软件（中国）有限公司（以下简称“金蝶”）强强联合，双方以价值共创为导向，携手共同推进业财融合的企业数字化转型建设。
                  `,
            `
            货达网络CEO折大伟、CFO章小花、销售副总裁杨耿、财务管理吕悦、业财管理王勃、信息化管理王剑楠，其他相关部门负责人，以及金蝶陕西分公司总经理李建军、销售总监于泳、项目交付总监田武军，项目管理部、解决方案中心等相关负责人出席本次会议。
            `,
          ],
          sectionImg: [[getServiceImagePath('press/new20230816/0816-2.png'), '货达 CEO-折大伟']],
        },
        {
          section: [
            `
            启动会上，货达网络CEO折大伟谈到，货达网络始终秉持着以客户为中心的价值观，拥抱数字化转型，积极探索多元化的跨界业务发展新举措，持续为客户提供更优质的物流与供应链解决方案。当前传统的管理模式已不能满足日益增长的业务需求。
            `,
            `
            通过此次与金蝶公司的深度合作，我们也将进一步推动财务管理与业务运营的高度融合，实现信息的无缝流通和共享。这不仅将提升内部决策的准确性和效率，还将为我们面向的大宗行业客户提供更为精准的服务，实现多方共赢局面。
            `,
          ],
          sectionImg: [[getServiceImagePath('press/new20230816/0816-3.png'), '货达 业财管理-王勃']],
        },
        {
          section: [
            `
            金蝶公司作为企业管理数字化解决方案的领军企业，拥有丰富的行业经验和先进的技术实力。货达网络业财管理王勃为项目做开场介绍，他表示团队经过多轮对标调研及合作洽谈，最终选择金蝶作为合作伙伴。此次项目启动，对于提升公司业财管控能力，打开主业发展新增长局面有着重要作用。希望双方团队能够精诚合作，保质保量、实施到位，为货达带来财务管理上的有效提升！
            `,
          ],
          sectionImg: [
            [getServiceImagePath('press/new20230816/0816-4.png'), '金蝶 项目经理-孙佳飞'],
          ],
        },
        {
          section: [
            `
            金蝶项目经理孙佳飞向大家详细介绍了EBC整体方案及流程关键节点、内容要素。他说到，在本次项目的执行过程中，金蝶会以专业的数字技术以及多年沉淀的管理经验助力货达网络完成财务信息化建设。全体项目组成员也将会及时洞察问题需求，与货达团队紧密合作，保障货达网络业财融合目标的圆满达成。
            `,
          ],
          sectionImg: [[getServiceImagePath('press/new20230816/0816-5.png'), '双方合影留念']],
        },
        {
          section: [
            `
            财务信息化建设项目的启动，意味着公司将迎来企业数字化转型发展的新篇章。未来，货达网络将继续深入与金蝶合作，依托其在信息化领域的丰富经验，进一步加速管理变革，重构数字战斗力，向行业领军品牌发展大步迈进！
              `,
          ],
        },
      ],
    },
    12: {
      title: '2023海纳汇软件产业联盟全国巡展西安站圆满召开，货达网络精彩亮相',
      date: '2023/08/14',
      article: [
        {
          sectionImg: [getServiceImagePath('press/new20230814/0814-1.png')],
        },
        {
          section: [
            `8月11日，由西文数字经济研究院、海纳汇软件产业联盟主办，陕西省软件行业协会协办的<span style="font-weight: bold;">2023智改数转 · 软件赋能——海纳汇软件产业联盟全国巡展</span>活动-西安站圆满举办成功！来自软件行业的各参展商、代理商及渠道伙伴齐聚一堂，聚焦数字经济与实体经济深度融合，共同探讨数智化转型趋势之下的新产业、新业态、新模式，为全面赋能高质量发展，提供了深度交流的空间。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230814/0814-2.png')],
        },
        {
          section: [
            `<span style="font-weight: bold;">西安货达网络科技有限公司</span>资深售前工程师参与此次活动并进行《为大宗产业升级打造数字化解决方案》主题演讲，其中提到公司专注大宗能化产业，基于AIoT、云计算、大数据等新一代信息技术，深入煤炭、电力、化工等行业客户业务场景，帮助解决痛点问题。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230814/0814-3.png')],
        },
        {
          section: [
            `针对数字供应链解决方案，马菁以煤炭行业为切入，从业务逻辑角度介绍了<span style="font-weight: bold;">“发-运-收”</span>核心环节，大宗商品运销面临的节点数字化问题及传统转型需求。同时分享了中国华能、中国华电、陕西能源等客户的成功案例，<span style="font-weight: bold;">全面展示了公司对于实现供应链中各业务数据端到端协同共享，链接运输上下游主体，实现全流程可视化的实践能力。<span>`,
          ],
          sectionImg: [getServiceImagePath('press/new20230814/0814-4.png')],
        },
        {
          section: [
            `伴随着物流与供应链领域产业结构变更、技术迭代及市场环境发展变革加剧，上下游供应商、贸易商、货主企业等面临的机遇和挑战与日俱增。作为<span style="font-weight: bold;">大宗商品数字供应链服务商</span>，货达网络不仅在技术服务领域建立了自身的专业优势和领先地位，同时希望能够积极发展渠道合作伙伴，携手构建整体市场生态，加速实现增长发展的共同目标，为中国软件行业“智改数转”贡献力量！`,
          ],
        },
      ],
    },
    13: {
      title: '凝心聚力，砥砺前行，货达2023项目中心年中会议召开',
      date: '2023/08/11',
      article: [
        {
          sectionImg: [getServiceImagePath('press/new20230811/0811-1.png')],
        },
        {
          section: [
            `2023年8月，<span style="font-weight: bold;">西安货达网络科技有限公司</span>（以下简称“货达网络”）项目中心年中会议在西安总部绿地中心召开，公司CEO折大伟、CFO章小花、项目中心总经理简毅、各部门主要负责人及项目中心全体员工参加本次会议。`,
          ],
          sectionImg: [
            [getServiceImagePath('press/new20230811/0811-2.png'), '货达 CEO-折大伟致辞'],
          ],
        },
        {
          section: [
            `货达网络CEO折大伟在会议伊始，即做了致辞发言。他谈到，大宗商品物流行业历史悠久、源远流长，公司自18年开始逐步以<span style="font-weight: bold;">数字技术“下沉”供应链业务节点，持续赋能实体企业完成转型发展，当前在煤炭、电力行业，已经占据领先位置。</span>针对传统业务环节，采运销一体化解决方案、称重计量为核心的数字化改造仍在进行，而未来以数字货仓、数字货场为发展方向的业务布局也亟待构建。<span style="font-weight: bold;">回溯项目中心历史成果，</span>杨伙盘煤矿、红柳林煤矿等等，都留下了众多同事驻守一线，勤奋工作、艰苦奋斗的身影......今年Q2季度，两个千万级的项目更是将部门发展推上了新台阶。未来，希望大家能够带有正视问题、自我审视和自我批判的态度，在工作中持续精进。`,
          ],
          sectionImg: [
            [getServiceImagePath('press/new20230811/0811-3.png'), '货达 项目中心总经理-简毅'],
            [getServiceImagePath('press/new20230811/0811-4.png'), '货达 项目中心-黄凯'],
          ],
        },
        {
          section: [
            `结合项目中心上半年各项指标完成情况及重点工作推进情况，负责人简毅向大家介绍了对于<span style="font-weight: bold;">夯实数字化底座，实现公司战略</span>的整体规划，同时从部门业务整合重组、工作计划拆解、组织架构升级等板块阐述了落地的可执行方案，为大家擘画了2023下半年业务发展的新蓝图。运营管理黄凯从项目业务运作的流程管理机制、管理体系与日常组织等方面，详细解读了汇报方案内容，让众人对于下一步工作目标的达成有了更坚定的信心。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230811/0811-5.png')],
        },
        {
          section: [
            `从前期商机储备到方案支持，过程中的效率优化，再到后期的交付保障等，每一次业务实施的研讨、每一例成功案例的达成，都少不了项目团队工作执行中的彼此配合、并肩作战。业绩贡献奖、老员工授牌.......各部门负责人陆续上台为获奖者颁奖并致以祝福；同时，CEO折大伟也特意对远赴客户现场而未能到场参会的同事颁发奖杯，感谢其对于公司的辛劳付出。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230811/0811-6.png')],
        },
        {
          section: [
            `台下掌声连绵，现场氛围满满。主持人精心准备的台词、设备组场内及场外远程连线支持，精美的茶歇、精心筹备的礼品.....项目中心2023年中会议的一幕幕美好瞬间在快门声中逐一被记录。<span style="font-weight: bold;">未来，项目中心全体的愿景与规划也将镌刻于心、落实于行！</span>`,
          ],
        },
      ],
    },
    14: {
      title: '喜讯！货达网络与托克托县政府签署合作协议，共同推动县域智慧物流高质量发展',
      date: '2023/07/24',
      article: [
        {
          section: [
            `
            <span class="font-bold">7</span>月<span class="font-bold">24</span>日上午，内蒙古自治区托克托县政府与西安货达网络科技有限公司签署投资合作协议，双方就共同推动托克托县智慧物流产业快速高质量发展，立足当地特色资源优势深化合作，将集约、高效、绿色作为传统物流转型发展的方向等方面内容达成共识。
              `,
          ],
          sectionImg: [getServiceImagePath('press/new20230724/2023-07-24/2023-07-24-1.jpeg')],
        },
        {
          section: [
            `会上，托克托县县委副书记、县长高正、县城乡投资集团总经理李银亮与西安货达网络科技有限公司<span class="font-bold">CEO</span>折大伟签署投资合作协议。县政府办、发改委、财政局、工科局、税务局、交通局、投资促进中心等部门出席会议。`,
          ],
          sectionImg: [getServiceImagePath('press/new20230724/2023-07-24/2023-07-24-2.jpeg')],
        },
        {
          section: [
            `托克托县（简称“托县”）位于内蒙古自治区中部，地处呼、包、鄂“金三角”开发区腹地，历史悠久、文化底蕴深厚。托克托县城乡投资集团有限公司成立于<label class='font-bold'>1993</label>年，是托县投资建设领域综合性国有企业，在政策规划及产业投资等方面具有丰富经验和广泛资源。`,
            `西安货达网络科技有限公司（简称“货达网络”）成立于<span class="font-bold">2015</span>年<label class='font-bold'>2</label>月，是国内领先的大宗商品物流及供应链互联网集团企业，全国首批<label class='font-bold'>5A</label>级网络货运企业，陕西煤炭交易中心和榆林能化交易中心战略合作企业、指定承运商。公司致力于大宗商品流通领域内的降本增效，以数字科技赋能产业转型发展，为全国超过<label class='font-bold'>5000</label>家企业提供优质的平台产品与解决方案服务。
            `,
          ],
          sectionImg: [
            getServiceImagePath('press/new20230724/2023-07-24/2023-07-24-3.jpeg'),
            getServiceImagePath('press/new20230724/2023-07-24/2023-07-24-4.jpeg'),
          ],
        },
        {
          section: [
            `根据协议，今后双方将围绕产业规划、智慧物流、招商咨询等领域展开业务合作，并在人才培养、项目管理及运营等方面实现优势互补、合作共赢。`,
            `下一步，货达网络将以此次合作签约为契机，深化与内蒙古自治区托克托县的沟通交流，推动双方在更高层次、更大范围、更宽领域内开展合作，共同将托县特色区域优势转化为经济发展优势、竞争优势，争取打造一批标杆物流项目，为不断提升经济发展能级和县域综合实力，实现物流产业数字化转型升级而贡献力量！
                `,
          ],
        },
      ],
    },
    15: {
      title: '货达网络亮相第18届中国新疆国际煤炭工业博览会，以数智科技赋能大宗商品物流',
      date: '2023/07/19',
      article: [
        {
          sectionImg: [getServiceImagePath('press/new20230719/2023-07-19-1.png')],
        },
        {
          section: [
            `
            7月18日，第十八届中国新疆国际煤炭工业博览会在新疆国际会展中心盛大开幕。本届展会由商务部批准，新疆维吾尔自治区科学技术厅指导，新疆维吾尔自治区人民政府国家305项目办公室特别协办。博览会立足新疆煤炭资源形式，
            积极探索 <label class="primary-color">“碳达峰、碳中和”</label>在煤炭、矿产领域的生产实践，同时邀请800余家行业知名中外企业参与展会及论坛活动，约20000余人次的专业观众到场洽谈合作。
            <label class="font-bold">货达网络受邀参与展会，以数智科技赋能大宗商品物流与供应链建设，为加快推进行业数字化转型发展贡献力量</label>。
            `,
          ],
          sectionImg: [getServiceImagePath('press/new20230719/2023-07-19-2.png')],
        },
        {
          section: [
            '此次，货达携“运销宝”数字货运平台，智能运销、智能调运一体化解决方案亮相会场，全面展示了针对煤矿、煤厂、电厂等典型业务场景数字化转型之下，常见的管理困境剖析、痛点解决阐述及最新落地的成功项目案例，吸引了与会的行业专家、企业友商及相关部门代表驻足关注。',
          ],
          sectionImg: [
            getServiceImagePath('press/new20230719/2023-07-19-3.png'),
            getServiceImagePath('press/new20230719/2023-07-19-4.png'),
          ],
        },
        {
          section: [
            '作为大宗商品数字供应链服务商，货达网络以自身行业领先的数字货运平台、物流大数据管理与应用、供应链节点数字化建设能力，自主研发的智运盒等软硬件专利产品，为客户提供全流程标准化、一体化的业务解决方案。有效推动传统业务模式的革新，消除信息孤岛、加强业务协作、提高流程效率，帮助企业实现精细化管理，达成降本增效。',
          ],
          sectionImg: [getServiceImagePath('press/new20230719/2023-07-19-5.png')],
        },
        {
          section: [
            '新疆作为能源产业重地及西部重要物流枢纽，立足区域禀赋优势，正在加速推进资源优势向经济优势转换。未来，货达网络将持续深耕，以数字化技术为支撑，为更多供应链上下游企业解决痛点、创造价值，为当地煤炭行业数智化建设目标的实现提供助力！',
          ],
        },
      ],
    },
    16: {
      title:
        '第四届西部数博会盛大召开！货达网络接待吉林省、内蒙古自治区、高新区工信局等多位领导莅临调研',
      date: '2023/06/16',
      article: [
        {
          section: [
            '6月16日，第四届西部数字经济博览会（以下简称“西部数博会”）在西安临空会展中心盛大召开。作为当前西北地区最大的数字主题盛会，西部数博会已成为搭建数字头部企业合作桥梁、引领数字经济发展、推进西部数字经济高质量发展的新引擎。',
          ],
          sectionImg: [getServiceImagePath('press/new20230616/06-16-1.jpeg')],
        },
        {
          section: [
            '本届西部数博会以“数字创享 原启未来”为主题，以“1+3+9+N”为模式，紧密结合产业发展，重点围绕“以秦创原为引领的创新驱动”、“数字经济与实体经济深度融合”两条主线展开。货达网络作为国家大数据示范项目企业，通过数字化、智能化的物流与供应链解决方案，加速数字科技在传统大宗商品流通领域内业务场景的渗透，充分发挥产业链上下游协同引领作用，持续助力数字经济快速高质量发展。',
          ],
          sectionImg: [getServiceImagePath('press/new20230616/06-16-2.jpeg')],
        },
        {
          section: [
            '聚焦以数智硬科技赋能物流与供应链发展，驱动产业数字化转型、数字产业化发展。展位现场通过数字业务场景还原、标杆项目案例讲解及平台操作演示等方式，充分展示了公司在行业内实践探索的创新成果。同时，货达展位接待了吉林省、内蒙古自治区、西安市高新区工信局等领导莅临调研、指导交流。',
          ],
          sectionImg: [
            getServiceImagePath('press/new20230616/06-16-3.jpeg'),
            getServiceImagePath('press/new20230616/06-16-4.jpeg'),
            getServiceImagePath('press/new20230616/06-16-5.jpeg'),
          ],
        },
        {
          section: [
            '未来，货达网络将以数字货运为核心抓手，逐步构建集业务创新应用、场景服务融合、产业跨界融通的生态体系。充分发挥物流大数据和丰富的大宗业务场景应用优势，促进数字经济与实体经济深度融合，为壮大经济发展新引擎提供动能！ ',
          ],
        },
      ],
    },
    17: {
      title: '呼包鄂榆乌物流产业联盟正式启动：货达网络CEO折大伟应邀出席并代表榆物联发言',
      date: '2023/05/29',
      article: [
        {
          section: [
            '​5月29日，内蒙古自治区呼和浩特市、包头市、鄂尔多斯市、乌兰察布市、陕西省榆林市两省区五市物流产业联盟第一次会议暨产业联盟启动大会在鄂尔多斯市康巴什区盛大召开。榆林市物流与采购联合会会长、西安货达网络科技有限公司CEO折大伟受邀出席会议并作代表发言。',
          ],
          sectionImg: [getServiceImagePath('press/new20230529/2023-05-29-1.jpeg')],
        },
        {
          section: [
            '1.五市汇聚，协同奋进——共筑发展新格局',
            '“十四五”现代物流发展规划中明确指出，鼓励物流企业通过兼并重组、联盟合作等方式进行资源优化整合，培育一批具有国际竞争力的现代物流企业，提升一体化供应链综合服务能力。区域产业联盟的成立在构建现代流通体系、促进形成区域市场、推动行业高质量发展，发挥着先导性、基础性、战略性作用。',
            '此次会议宣告了呼包鄂榆乌物流产业联盟正式启动，同时也为后续深化区域政府和企业多元互动、协同发展、合作共赢，共同构筑区域物流产业高质量发展打开了新格局。',
          ],
          sectionImg: [getServiceImagePath('press/new20230529/2023-05-29-2.jpeg')],
        },
        {
          section: [
            '2.细抓落实,稳中求进——贯彻发展新议题',
            '榆林市物流与采购联合会（以下简称“榆物联”），成立于2021年12月，是陕西省榆林市发改委和榆林市民政局批准监管的榆林市唯一一家物流与采购行业综合性服务社会组织。货达网络CEO折大伟作为企业家代表在会上进行发言。',
            '折大伟表示，榆物联充分发挥链接政府和企业间的桥梁纽带作用，在推动传统物流企业转型、物流产业改革发展、优化行业资源配置等众多方面发挥了积极作用。作为联盟主席团之一，榆物联将秉承服务宗旨，完整、准确、全面贯彻产业联盟的新发展理念，加快构建物流行业新发展格局。为产业联盟的后续发展，折大伟同时提出如下建议：',
            '一、引导物流企业发掘细分市场需求，做精做专、创新服务，使物流企业专业化、精益化、品质化发展，增强专业化市场竞争力，提高规范化运作水平，形成一批“专、精、特、新”现代物流企业。',
            '二、促进物流产业供应链韧性和安全水平提升，推动物流行业提质、增效、降本，为建设现代产业体系、形成区域市场、推动高水平内外发展提供有力支撑。',
            '三、响应落实政府政策，加强行业自律和规范发展，发掘培育行业典型，助力物流行业规划落地实施。',
          ],
        },
        {
          section: [
            '3.智慧引领,创新驱动——货达助力新发展',
            '作为一家以“数字货运”核心业务为抓手，基于物联网、云计算、大数据等新一代信息技术发展的产业互联网企业，货达网络致力于以数字科技驱动大宗商品物流与供应链转型升级，将创新技术应用于业务场景进行深度实践，纵向延伸大宗商品物流产业链，横向融通金融与车后业务市场，为供应链上下游企业提供一体化的数字供应链解决方案服务。',
            '未来，货达网络也将积极响应产业发展联盟的号召，持续深耕业务领域。 通过自主研发的技术与产品为行业提供降本增效的数字化解决方案，助力区域物流产业高质量发展。最后，再次祝贺呼包鄂榆乌物流产业发展联盟的成立！此番新起点新征程，我们将砥砺奋进再出发！',
          ],
        },
      ],
    },
    18: {
      title: '鄂尔多斯煤博会“C位首秀”丨货达网络以数智赋能，引领煤炭数字化转型',
      date: '2023/05/08',
      article: [
        {
          section: [
            '5月6日-5月8日，第十七届鄂尔多斯国际煤炭与能源工业博览会成功举办，货达网络携旗下运销宝数字货运平台及针对煤炭能化行业提供的智能运销、智能调运业务解决方案、自有专利产品等硬件设备展品亮相东胜区展会现场。',
          ],
          sectionImg: [
            getServiceImagePath('press/new20230508/2023-05-08-1.jpeg'),
            getServiceImagePath('press/new20230508/2023-05-08-2.jpeg'),
          ],
        },
        {
          section: [
            '鄂尔多斯国际煤博会是我国煤炭能源领域内最具影响力的品牌盛会之一。本届展会以“绿色低碳、科技引领、共赢未来”为主题，旨在加强能源领域的交流合作，推动煤炭产业的创新发展。现场邀请了能化领域相关参展商及专业观众共聚展会，分享行业最新资讯、交流研讨最新产品及技术解决方案。',
          ],
          sectionImg: [
            getServiceImagePath('press/new20230508/2023-05-08-3.jpeg'),
            getServiceImagePath('press/new20230508/2023-05-08-4.jpeg'),
          ],
        },
        {
          section: [
            '深耕煤炭等能化领域，货达网络作为大宗商品数字供应链服务商，在数字技术、运营模式、产品服务等方向上不断创新，持续强化能力价值，积极锻造行业领军品牌助力客户成功。现已在基于信息化、数字化、智能化建设的多项供应链业务场景中落地，面向煤矿、电力、铁路货站、焦化冶金等行业积累了丰富的实践经验与成功案例。',
          ],
          sectionImg: [getServiceImagePath('press/new20230508/2023-05-08-5.jpeg')],
        },
        {
          section: [
            '作为重点展品之一，货达网络全面展示了运销宝数字货运平台及其业务生态建设的最新成果。运销宝数字货运平台是基于物联网、云计算、人工智能、大数据等互联网信息技术，整合货源、运输、仓储、支付等供应链服务的在线物流管理交易平台。其依托“无车承运人”模式，整合金融、车后等增值业务板块，综合实现“五流合一”，为客户提供安全、便捷的一站式货运服务，实现了企业降本增效、货车司机增效减负。',
          ],
          sectionImg: [getServiceImagePath('press/new20230508/2023-05-08-6.jpeg')],
        },
        {
          section: [
            '目前运销宝已推出货主PC端、司机PC端、司机APP/小程序等多端协同类产品，涵盖了协同管理、监控定位、智能识别、数据报表等多种功能，同时结合AIoT智能硬件设备，实现了对货物运输全程的数字化监控，保障了货运安全性和高效性。截止2022年底，平台注册司机超100万，运单总数累计3000万+，货运总量逾13亿吨。',
            '未来，公司将持续以业务场景实践推进产业数字化转型升级，为成为大宗商品数字供应链服务引领者、采运销云平台开拓者、称重计量工业互联网奠基者而踔厉前行！',
          ],
        },
      ],
    },
    19: {
      title: '货达网络接待格尔木市委副书记、副市长严仕吉，发改委、工信局、陆港办等多位领导莅临调研',
      date: '2023/04/22',
      article: [
        {
          section: [
            '2023年4月20日-22日，由西安市人民政府主办，西安市商务局、市发改委、市工信局、市交通局、市国资委、市投资局、市金融工作局、西安贸促会组织，浙江省海港集团冠名，国家能源集团协办的第十三届中国西部国际物流产业博览会在西安国际会展中心（浐灞）成功举行。作为立足西部物流交通枢纽、中欧班列集结地的西安本土展会，本届博览会共吸引300多家企业参与，展览面积1.6万平方米，专业观众到场人数超过3万，另累计50余万人次在线观展。',
          ],
          sectionImg: [getServiceImagePath('press/new20230422/04-22-1.png')],
        },
        {
          section: [
            '本届展会为期三天，整体围绕“数字赋能 共建大通道 畅通双循环”的主题展开。货达网络聚焦以数字科技赋能物流供应链发展，致力于为大宗能化产业提供全流程的数字供应链解决方案，全面展示了公司在数字转型发展、技术引领创新，“发、运、收”产品生态体系建设及软硬件一体化等方面取得的最新成果。',
          ],
          sectionImg: [getServiceImagePath('press/new20230422/04-22-2.png')],
        },
        {
          section: [
            '参展期间，货达展位人气高涨，吸引客商络绎不绝，现场配备专业售前顾问、资深大客户经理等多位工作人员负责业务咨询洽谈。同时，货达网络接待了格尔木市委副书记、副市长严仕吉，格尔木市发改委、工信局、陆港办，榆林市发改委、山西省等各级各界领导、团队到访调研交流。',
          ],
          sectionImg: [getServiceImagePath('press/new20230422/04-22-3.png')],
        },
        {
          section: ['格尔木市委副书记、副市长严仕吉带队调研'],
          sectionImg: [
            getServiceImagePath('press/new20230422/04-22-4.png'),
            getServiceImagePath('press/new20230422/04-22-5.png'),
            // getServiceImagePath('press/new20230422/04-22-6.png'),
          ],
        },
      ],
    },

    20: {
      title: '以“煤”会友丨货达网络携多款数字化产品与解决方案亮相第十七届煤博会',
      date: '2023/04/10',
      article: [
        {
          section: [
            '4月7日-9日，第十七届榆林煤炭暨高端能源化工产业博览会在榆林会展中心成功举办。货达网络携旗下运销宝 数字货运平台、数字金融产品及针对煤炭能化行业提供的智能运销业务解决方案、自有专利产品智运盒等硬件设备展品亮相展会。',
          ],
          sectionImg: [getServiceImagePath('press/new20230410/04-10-1.png')],
        },
        {
          section: [
            '榆林国际煤炭博览会是陕西省政府在榆林保留的唯一大型展会，是煤炭能化产业内国际知名、国内一流的品牌展示平台。本届展会举办整体围绕绿色化、智能化、数字化展开，以“高端引领、多元融合、低碳发展”为主题，邀请能化产业相关参展商与专业观众共聚现场，了解行业最新资讯、交流研讨最新产品及技术解决方案。',
          ],
          sectionImg: [getServiceImagePath('press/new20230410/04-10-2.png')],
        },
        {
          section: [
            '榆林国际煤炭博览会是陕西省政府在榆林保留的唯一大型展会，是煤炭能化产业内国际知名、国内一流的品牌展示平台。本届展会举办整体围绕绿色化、智能化、数字化展开，以“高端引领、多元融合、低碳发展”为主题，邀请能化产业相关参展商与专业观众共聚现场，了解行业最新资讯、交流研讨最新产品及技术解决方案。',
            '专注行业降本增效、低碳绿色发展，货达网络以数字技术赋能大宗商品物流上下游全链路业务场景，为煤炭、电力、化工、铁路货站、能源监管等领域提供软硬件一体化解决方案。',
            '煤炭行业智能运销系统，是基于物联网、大数据、人工智能等新一代信息技术的销售和运输管理系统。',
          ],
          sectionImg: [
            getServiceImagePath('press/new20230410/04-10-3.png'),
            getServiceImagePath('press/new20230410/04-10-4.png'),
          ],
        },
        {
          section: [
            '通过调拨计划、自动识别进出矿、无人值守磅房、远程装车、在途监管及数据分析等功能，针对“运”、“销”核心业务场景数字化建设，帮助煤炭企业实现销售和运输过程的自动化、智能化和高效化“闭环管理”，提高生产效率、降低运营成本，同时减少货物在途风险。',
            '作为全国首批无车承运人试点企业、全国5A级网络货运企业，陕西煤炭交易中心和榆林能化交易中心战略合作企业、指定承运商，国家级高新技术企业，货达网络为全国超过5000家企业提供优质的供应链平台与产品服务，在西安、榆林、太原、合肥、包头、鄂尔多斯等多地设立全资或控股子公司。多年来，凭借优质专业的技术、贴心到位的服务，深受客户与合作伙伴认可和信赖。',
            '未来，公司将持续以业务场景实践推进数字化供应链转型，打造大宗商品领域更具领先性与普惠价值的数智创新平台，以技术驱动、平台赋能发展互联网与工业物流融合新生态。',
          ],
          sectionImg: [],
        },
      ],
    },
    21: {
      title: '西安货达网络数字化成果交流座谈会成功举办！',
      date: '2023/03/01',
      article: [
        {
          section: [
            '2023年3月1日，陕西物流与采购联合会（以下简称“陕物联”）会长乔继岗携众多优秀企业家，到访西安货达网络科技有限公司（以下简称“货达网络”），与CEO折大伟、副总经理宁勤伟等公司高管，就货达网络近年来取得的数字化成果进行亲切友好交流。',
          ],
          sectionImg: [getServiceImagePath('press/new20230301/03-01-1.png')],
        },
        {
          section: [
            '陕物联会长乔继岗指出，数字化建设是国家当前及未来一段时间的发展重点，而数字经济作为新一轮科技革命与产业革新的战略选择，在物流行业数字化转型发展中大家面临新的机遇和挑战。乔会长首先肯定了货达网络作为由本土发展成长起来的企业，在积极推进场景大数据产业化发展、大数据示范试点应用方面取得了良好成效。同时表示，货达网络从无车承运人到网络货运平台再到提供全链路数字化供应链解决方案，始终坚持以数字化、智能化、信息化手段做好能源保供工作，为行业良性发展起到了带头和示范作用。',
          ],
          sectionImg: [getServiceImagePath('press/new20230301/03-01-2.png')],
        },
        {
          section: [
            '货达网络CEO折大伟向到访众位简要介绍了公司发展状况，以华能集团照金煤电燃料调运系统为例，展开介绍了公司典型的数字化解决方案应用成果。同时，就物流环节监管漏洞等长期存在的痛点问题应对措施，货达网络AIoT物联网设备辅助无人化过磅场景落地、电子磅单取代传统纸质承运单据、新能源重卡及换电站布局等案例，详实介绍了公司以数字技术促进“五流合一”，推动整体供应链流转效率与效能提升、推进普惠金融的创新实践。',
          ],
          sectionImg: [
            getServiceImagePath('press/new20230301/03-01-3.png'),
            getServiceImagePath('press/new20230301/03-01-4.png'),
          ],
        },
        {
          section: [
            '出席会谈的还有陕物联监事长孙婷、副会长兼秘书长温格林，货达网络研发中心CTO刘昆、项目中心副总经理龚金柱、供应链金融事业部副总裁罗梁军等。',
            '货达网络作为大宗商品数字供应链服务商，始终专注行业领域内的降本增效，持续推进互联网工业物流与实体企业的深度融合。未来，货达将坚持提升数字技术创新能力，深入支撑和推动产业链向安全高效、绿色低碳方向升级，为供应链数字化发展开创新格局！',
          ],
        },
      ],
    },

    22: {
      title: '陕西省交通运输厅副厅长戴滨华一行莅临我司调研指导工作',
      date: '2022/05/27 12:34:56',
      article: [
        {
          section: [
            '5月27日上午，陕西省交通运输厅党组成员、副厅长戴滨华，省道路运输中心党委书记、主任张惠萍，省交通运输厅运输处副处长辛娟一行莅临西安货达网络科技有限公司调研指导工作，货达网络公司CEO折大伟，货达网络公司CFO章小花，货达网络公司副总经理龚金柱，货达网络公司总经理助理宁勤伟参加调研座谈会。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail1/img1.png')],
        },
        {
          section: [
            '货达网络公司CEO折大伟热烈欢迎陕西省交通运输厅领导一行的到来，并陪同参观了公司的办公环境。座谈会上，折大伟向戴滨华副厅长汇报了目前公司的发展情况、业务运营模式以及当前疫情形势下公司面临的问题和困难。随后，双方就网络货运推动物流运输行业智慧化、平台化、数字化建设进行了交流讨论。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail1/img2.png')],
        },
        {
          section: [
            '戴滨华指出，根据日前召开的国务院常务会议进一步部署稳经济一揽子措施，努力推动经济回归正常轨道、确保运行在合理区间。在疫情影响下，中小企业在生产经营十分困难，省交通运输厅要通过了解企业面临的切实问题并加以解决，帮扶企业纾困、促进经济发展；同时要积极推进国家扶持政策落实到位，让中小企业充分享受到政策红利。最后他提出要加强与企业的联系与沟通，了解企业诉求，为企业发展创造良好环境。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail1/img3.png')],
        },
      ],
    },
    23: {
      title: '货达网络入选我省“上云”平台服务商 助力企业数字化转型',
      date: '2022/05/11 12:34:56',
      article: [
        {
          section: [
            '为贯彻落实《陕西省推动制造业高质量发展实施方案》（陕政办发[2022]1号），加强对制造业企业服务，鼓励中小企业迅速成长，加快推动中小企业“上云上平台”，2022年03月22日，陕西省工业和信息化厅、陕西省财政厅发布《关于做好2022年省级中小企业发展专项资金中小企业上云上平台项目申报工作的通知》，西安货达网络科技有限公司自主研发的运销宝物流云平台被列入《陕西省2022年中小企业“上云”服务平台目录》，共同助力我省中小企业数字化、网络化、智能化转型升级。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail2/img1.png')],
        },
        {
          section: [
            '货达网络积极践行 “上云”战略，推动大宗商品企业数字化转型，加速行业数据资源获取与积累，利用产数融合推进企业降本增效、绿色低碳发展。运销宝物流云平台作为入选的服务平台，为企业提供发运管理、智能调度、运输监管、结算服务、车后金融等全场景业务的全闭环货运生态链，同时也可直接联通产业链上下游企业与转运场站，为全链路用户提供数字化全程覆盖、各环节高效协同、业务运营实时共享的供应链一站式服务。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail2/img2.png')],
        },
        {
          section: [
            '今后货达网络将进一步发挥在工业互联网、云计算、大数据等新兴领域的综合实力，推动物流业制造业融合发展，深化供给侧结构性改革，在省委、省政府的领导下，共同构建具有强大竞争力的工业互联网产业生态体系，为陕西省经济高质量发展贡献力量！',
          ],
          sectionImg: [''],
        },
      ],
    },
    24: {
      title: '货达CEO折大伟：“双碳”背景下大宗商品供应链该如何发展',
      date: '2022/03/10 12:34:56',
      article: [
        {
          section: [
            '“碳达峰，碳中和”的“双碳”背景下，加快推进工业领域低碳技术革新和数字化转型是产业结构化升级的基本路线之一。大宗商品物流作为工业领域数字化转型、产业结构化升级的切入点，将成为能化产业智慧供应链体系建设的关键“基础设施”。',
            '《国家综合立体交通网规划纲要》中提到，到2035年将基本实现物流全要素全周期数字化，实现交通基础设施数字化率90%的目标。十四五规划中也明确提到，打造数字经济新优势 ，充分发挥海量数据和丰富应用场景优势，促进数字技术与实体经济高度融合，赋能传统产业转型升级，催生新产业、新业态、新模式，壮大经济发展新引擎。数字信息技术和实体经济的充分融合，将给供应链的发展带来极大变化，未来上下游的协同、供应链的快速反应，都将成为企业竞争力比拼的重要内容。而基于物联网技术，建设数字化、可视化、智能化的智慧供应链体系，将作为产业物流升级的“新基建”迎来快速发展。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail3/img1.png')],
        },
        {
          section: [
            '在3月4日结束的“全国大宗商品智慧物流生态链发展大会暨第五届网络货运信用体系建设高峰论坛”会议上，货达网络CEO折大伟表示“双碳”政策是整个经济发展的新要求和新变量，货运场景作为物流节能减排的关键，其技术突破与应用场景的优化是节能的两大方向。并指出这一政策的实施，对传统产业的发展提出了更高的要求，将倒逼整个流通体系向全链条智慧化转型升级。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail3/img2.png')],
        },
        {
          section: [
            '在此次大宗商品智慧物流发展大会上，折大伟从“双碳”背景出发，分享了在此背景下他对大宗商品智慧供应链的思考和见解。他表示在数字经济的驱动下，通过多维度、立体化地整合优势资源，为用户提供多场景的服务，以数据为契机，深度融合供应链，打通产业链各环节，充分释放平台和供应链的价值，进而才能实现智慧供应链体系的建设。',
            '他指出物流过程中的信息流最终需要依托硬件设备来实现数据的统计和汇集上传，而这也体现了“物联网”和“互联网”的高度一体化，二者间呈现“高耦合”的状态。设备与设备联网、数据资源合理分享和配置、万物互联集约到一个资源共享平台上通过软件系统向用户提供服务，帮助企业实现转型。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail3/img3.png')],
        },
        {
          section: [
            '因此，在货达，既有为供应链上下游大宗工矿企业提供的SaaS运销系统，又有为电厂、煤矿等企业提供的IOT监管设备。货达将物流的全链路通过物联网、互联网等技术设备实现组织效率的提高和运输过程的透明。折大伟强调货达凭借运销系统、物联网监管等数字运销方案解决了产业全链条“人、车、货、交易”的安全、效率和成本问题，为客户提供端到端的一体化解决方案，帮助大宗物流企业实现数字化转型升级。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail3/img4.png')],
        },
        {
          section: [
            '作为工业互联网流通领域的先行者，货达将继续致力于大宗商品智慧供应链体系的建设，坚持创新驱动发展，助力大宗物流数字化转型，深入支撑和推动全产业链向安全、高效、绿色、低碳方向升级，为壮大现代流通企业，构建以国内大循环为主体、国内国际双循环相互促进的新发展格局提供有力支撑。',
          ],
          sectionImg: [],
        },
      ],
    },
    25: {
      title: '践行劳模精神，数字化赋能陕西物流行业高质量发展',
      date: '2022/04/29 12:34:56',
      article: [
        {
          section: [
            '2022年4月28日下午，西安货达网络科技有限公司受邀参加由陕西省物流与采购联合会主办的“陕西省物流行业劳模代表座谈会”线上会议。会议邀请陕西省总工会党组成员、副主席赵霞，工会基层工作部副部长张志强，陕西省商务厅流通业发展处调研员彭远华等相关领导，全国物流行业劳动模范、先进工作者、以及省市级劳动模范代表，陕西省物流与采购联合会会长乔继岗等联合会相关领导以及会员单位，并围绕进一步弘扬劳模精神、劳动精神、工匠精神，促进物流行业高质量发展展开讨论。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail4/img1.jpeg')],
        },
        {
          section: [
            '座谈会上，赵霞主席向奋战在一线上的劳动模范和广大劳动者表示节日的祝贺和亲切的问候，她表示要聚焦习近平总书记在全国劳动模范和先进工作者表彰大会上的重要讲话精神，发挥工会的政治优势，组织优势，制度优势，资源优势，群众优势，积极弘扬劳模精神，并介绍了省工会在劳模建设方面取得的进展和成果。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail4/img2.jpeg')],
        },
        {
          section: [
            '会议现场劳模代表踊跃发言，提出对我省物流行业发展的建议以及对行业协会发展的期望。陕物联副会长、榆林物流与采购联合会会长、西安货达网络科技有限公司CEO折大伟在线上发表讲话，他表示要积极学习和弘扬劳模精神，推动物流行业健康发展。并在会上汇报了公司发展情况，提出要积极推进数字化建设，加强“双碳”战略研究等建议，为我省物流行业高质量发展进言献策。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail4/img3.jpeg')],
        },
        {
          section: [
            '学习劳模精神，弘扬劳模事迹，“五一”国际劳动节将至，货达网络致敬每一位奋斗在一线的广大劳动者，祝大家节日快乐！',
          ],
          sectionImg: [],
        },
      ],
    },
    26: {
      title: '助力疫情防控，行动书写担当—货达【战疫】出发',
      date: '2022/01/5 12:34:56',
      article: [
        {
          section: [
            '年关将至，冬深严寒。自12月份以来，新冠疫情席卷西安，这座承载了千万人口的古都再次被“狡猾”的病毒缠身。此刻的西安，正面临着自武汉封城以后最严峻的“考验”。',
          ],
        },
        {
          sonTitle: '疫情即命令，防控乃责任',
        },
        {
          section: [
            '疫情即命令，防控乃责任。面对西安这次突如其来、形式严峻、影响巨大的疫情“袭击”，我们货达人责无旁贷。岂曰无衣，与子同袍。前线冲锋，后方支援。自疫情爆发以来，在抗疫前线的工作人员防护产品用量巨大，为坚守岗位的前线工作人员提供充足的防护资源、生活物资，是保证抗疫胜利的重要条件。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail5/img1.png')],
        },
        {
          sonTitle: '货达在行动',
        },
        {
          section: [
            '在公司领导有条不紊的部署和公司员工的积极行动下，1月5日下午，货达网络捐赠的3000余件防护服、N95口罩和防护面罩以及500箱生活物资物资运抵西安市公安局高新分局，为抗疫工作贡献自己的力量。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail5/img2.png')],
        },
        {
          section: [
            '货达网络CEO折大伟表示，抗疫工作者为保障人民群众生命健康，夜以继日奋战在防疫一线，感谢他们的不辞辛苦、坚守岗位。货达网络为奋战一线的英雄们贡献爱心是责任使然，青山一道，风雪同担！作为深耕陕西的本土企业，货达网络用实际行动为那些坚守一线的抗疫工作者了尽绵薄之力，下一步，货达将继续发扬“勠力同心，共战疫情”的精神，在做好自我防控的同时，也将全力以赴支持政府的防疫工作，同时，货达仍将持续密切关注疫情，尽全力提供帮助，助力打赢这场疫情防控阻击战。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail5/img3.png')],
        },
        {
          section: [
            '万众一心，众志成城，同担使命，共克时艰。我们坚信在党和政府的领导下，必将战胜此次疫情，阴霾终将散去，曙光就在前方。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail5/img4.png')],
        },
      ],
    },
    27: {
      title: '北奔重汽党委书记、董事长范志平带队考察西安货达网络科技有限公司',
      date: '2022/04/30 12:34:56',
      article: [
        {
          section: [
            '2021年4月25日，北奔重汽集团党委书记、董事长范志平带队，在总会计师书荆文玉、总经理助理宋雁冰、科技与规划资产部部长白晓龙、新能源事业部书记其尔曼、后市场服务发展项目组组长宝雅龙的陪同下考察了西安货达网络科技有限公司。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail6/img1.png')],
        },
        {
          section: [
            '双方就潜在合作进行了深度沟通。西安货达网络科技有限公司CEO折大伟表示：“未来网络货运平台企业与垂直的供应链、产业链进行深度融合是大势所趋，是国家推动服务业与制造业协同发展，保障供应链产业链的优化与升级的重要手段。网络货运业态的蓬勃发展，也对商用车主机厂提供了供应链产业链延伸的崭新机遇。”',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail6/img2.png')],
        },
        {
          section: [
            '范志平董事长表示：“2021年是北奔重汽的服务年，下一步工作重点是实现北奔从制造业向服务业延伸，加速促进两业融合，为客户提供全生命周期服务，增加客户粘性，实现司机终身可托付的车辆服务。下一步在物流体系中，北奔重汽要在控货和控运力方面下功夫，以网络货运平台为抓手逐步建成北奔服务生态圈系统。”',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail6/img3.png')],
        },
        {
          section: [
            '此次考察增进了双方的互相了解与沟通，货达集团通过与北奔重汽建立合作，标志着货达向新能源汽车、车辆实体运营迈出了坚实一步，会后双方还签署了合资合作框架协议和采购50台北奔新能源牵引车的意向书。 ',
          ],
          sectionImg: [
            getServiceImagePath('press/newsDetail6/img4.png'),
            getServiceImagePath('press/newsDetail6/img5.png'),
          ],
        },
      ],
    },
    28: {
      title:
        '货达网络受邀参加第二届交通智慧物流高端论坛暨2020（第十二届）中国物流与供应链信息化大会',
      date: '2020/11/7 12:34:56',
      article: [
        {
          section: [
            '2020年11月4日，由交通运输部综合规划司、运输服务司、水运局指导，中国交通通信信息中心、中国物流与采购联合会、温州市人民政府共同主办的第二届交通智慧物流高端论坛2020（第十二届）中国物流与供应链信息化大会在温州市盛大召开。来自各地方交通运输管理部门、行业协会、研究机构及产业链相关企业等600余位代表参加大会。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail7/img1.png')],
        },
        {
          section: [
            '本届峰会以“数字生产力，领跑物流供应链新格局”为主题，紧密围绕网络货运平台企业的发展与创新、“智慧航运”新生态、智慧供应链促进大宗商品贸易、物流与金融融合发展、多式联运平台发展与数字化体系建设、新基建时代下的智能物流装备技术新趋势、网络货运实战训练等相关议题，邀请政府相关部门领导、国内行业知名专家学者以及物流企业开展深度互动交流，共同探讨新形势下我国物流与供应链产业的创新发展之路。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail7/img2.png')],
        },
        {
          section: [
            '交通运输部副部长刘小明在论坛上发表视频致辞指出，当前，以互联网、大数据、人工智能为特征的新一轮科技革命迅猛发展，信息技术与传统货运物流深度融合，网络货运、车货匹配等平台经济创新发展，对推动物流降本增效发挥了积极作用。我们要坚定不移地实施“互联网+”战略，充分发挥互联网、大数据、区块链等现代信息技术的优势，在共享经济、现代供应链等领域培育新增长点、形成新动能，以科技创新、数字创新引领带动物流转型升级，加快建设现代流通体系，支撑经济高质量发展。在今年9月21日刘小明副部长调研货达网络并对其在网络货运行业中的价值给予了充分肯定，并指出货达网络在物流与产业链供应链深度融合的方向上走出了一条创新发展的特色道路，勉励企业继续努力，为物流产业降本增效做出更大贡献。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail7/img3.png')],
        },
        {
          section: [
            '行业表彰环节，货达网络荣获“2020中国物流与供应链信息化十佳服务商”、“2020年度物流服务平台优秀案例”奖项。货达网络作为行业内领先的智慧供应链科技公司，将继续积极响应国家号召，应用新技术构建起覆盖全要素、全产业链、全价值链的新型数字供应链体系，助力推动高水平新型基础设施建设。',
          ],
          sectionImg: [],
        },
      ],
    },
    29: {
      title: '交通运输部副部长刘小明调研货达网络',
      date: '2022/09/22 12:34:56',
      article: [
        {
          section: [''],
          sectionImg: [getServiceImagePath('press/newsDetail8/img1.png')],
        },
        {
          section: [
            '9月21日下午，交通运输部党组成员、副部长刘小明，交通运输部运输服务司副司长蔡团结在陕西省交通运输厅党组书记、厅长夏晓中，省交通运输厅党组成员、副厅长戴滨华，交通运输部运输服务司城市交通管理处副处长马明，部运输服务司城市交通管理处三级调研员李良华，省交通运输厅二级巡视员鲁会劳，省交通运输厅办公室主任王建勋，西安市交通运输局局长张健，西安高新技术产业开发区管委会副主任韩红丽等人的陪同下莅临西安货达网络科技有限公司（货达网络）考察网络货运行业发展情况。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail8/img2.png')],
        },
        {
          section: [
            '刘小明副部长一行听取了货达网络CEO折大伟有关工业智慧物流的发展思路及未来展望。折大伟总经理汇报道，交通运输部2016年底启动了首批无车承运试点工作，货达网络紧抓试点机遇，取得了突出的成绩。随着国家“新基建”、物流业制造业融合政策的进一步推出，针对西北地区产业集群特性，货达网络将以网络货运为抓手，深度链接产业链上下游企业，实现垂直产业链产、运、销全闭环管理，推动全行业向无纸化、无人化、智能化转变。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail8/img3.png')],
        },
        {
          section: [
            '听完汇报后，刘小明副部长指出，网络货运的价值一是解决供货双方信息不对称问题，二是解决物流过程中满载率偏低问题，提高货车司机收入，三是解决货运行业“小、散、弱”问题。通过政府及网络货运企业的共同努力，有效提升行业集约度，提高行业运转效率。同时针对工业企业供应链降本、司机群体服务与规范运营等问题与折大伟总经理做了简要交流并提出来合理化的意见和建议。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail8/img4.png')],
        },
        {
          section: [
            '最后，刘小明副部长对于货达网络在行业中的价值给予了充分肯定，并指出货达网络在物流与产业链供应链深度融合的方向上走出了一条创新发展的特色道路，勉励企业继续努力，为物流产业降本增效做出更大贡献！',
          ],
          sectionImg: [],
        },
      ],
    },
    30: {
      title: '货达网络通过5A级网络货运平台物流企业评估',
      date: '2022/08/18',
      article: [
        {
          section: [
            '8月16日，中国物流与采购联合会A级网络货运企业等级评审专家莅临货达网络并召开专家评审会，货达网络顺利通过了国家5A级网络货运企业现场评估。货达网络也成为了全国第二家、中西部地区首家通过5A等级评估的网络货运企业。评估委员会由中国物流与采购联合会评估办主任杨国栋，中国物流与采购联合会网络事业部主任、物流信息服务平台分会秘书长晏庆华，中国物流与采购联合会物流信息服务平台分会综合部主任金妲颖，陕西省物流采购联合会副会长兼评估办主任侯宗科，西安市发改委财金处计炜主任，西安市现代物流与供应链联合会副会长石超峰组成，杨国栋主任担任评估组组长。中央财经大学商学院教授耿勇，陕西省物流与采购联合会会长孙继民，绥德县税务局局长高旭林，绥德县招商局局长韩人杰列席了会议。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail9/img1.png')],
        },
        {
          section: [
            'A级网络货运平台企业评估工作是传统A级物流企业评估工作在物流平台领域的延伸，以团体标准《网络货运平台服务能力评估指标》为依据，对规范网络货运业态，提升行业服务水平，树立行业标杆具有积极引导作用。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail9/img2.png')],
        },
        {
          section: [
            '评审会上，杨国栋主任针对网络货运平台基本信息、平台服务能力、系统支撑能力、平台管理能力、安全与风险管理能力等提出要求，并进行评估。货达网络总经理折大伟就考察指标以及货达网络发展现状、未来发展方向和下一步工作重点向评估委员会进行介绍。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail9/img3.png')],
        },
        {
          section: [
            '根据《网络货运平台服务能力评估指标》及其他相关标准，评审专家组针对网络货运各项服务指标进行了严格审核，一致认为货达网络符合5A级网络货运平台物流企业评估指标要求，货达网络顺利通过5A级物流企业现场评估环节。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail9/img4.png')],
        },
        {
          section: [
            '评审委员会专家表示，货达网络作为业内知名的大宗商品智慧供应链服务商，展现了领先的技术实力和发展理念，希望货达网络再接再厉，引领网络货运走向标准化、规范化、专业化，促进网络货运与供应链深度融合，发挥网络货运最大价值。',
            '获得此项殊荣是对公司发展的一次巨大鼓舞，同时也对公司后续发展提出了更高的要求。公司将继续加大研发投入，通过技术的持续创新为客户提供先进的网络货运平台服务，在持续创造价值的同时，进一步促进大宗商品行业从传统物流向现代化物流转型升级。',
          ],
          sectionImg: [],
        },
      ],
    },
    31: {
      title: '货达网络战略合作中国煤炭市场网，布局大宗商品数字供应链新未来',
      date: '2019/12/05',
      article: [
        {
          section: [
            '2019年12月4日至6日，由中国煤炭工业协会主办，中国煤炭运销协会、日照市人民政府承办的“2020年度全国煤炭交易会”于山东日照成功召开。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail10/img1.png')],
        },
        {
          section: [
            '本届交易会聚焦“互利共赢 高质量发展”主题，举办了煤炭产运需协同发展高峰论坛、企业家座谈会、专场研讨会、展览推介等活动，通过国家有关部门，省级煤炭行业管理部门，煤炭生产、运输（铁路局和港口）、销售、消费企业，以及相关行业协会、金融机构等部门、企业间相互交流，促进煤炭及相关产业高质量发展。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail10/img2.png')],
        },
        {
          section: [
            '西安货达网络科技有限公司（以下简称“货达网络”）受邀参加本次交易会，并与中国煤炭市场网达成新一轮战略合作，双方于12月5日在会议现场正式签订合作协议，由货达网络CEO折大伟与中国煤炭运销协会副理事长、煤炭交易市场专业委员会副主任冯雨分别代表双方签约。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail10/img3.png')],
        },
        {
          section: [
            '本次战略合作立足于习近平总书记交通强国战略的重大决策部署和国务院、交通部推进社会物流业降本增效、调整运输结构的战略举措，强强联合，优势互补。',
            '中国煤炭市场网是全球领先的煤炭数据服务和分析服务商，被评为“能源类行业网站百强”、“最具影响力煤炭行业门户网站”，网站发布的CCTD秦皇岛动力煤价格受到广泛关注，是全国煤炭年度交易长协基础价指定发布机构。在煤炭交易体系建设方面，公司承担全国煤炭交易市场专业委员会日常运营工作，并参与建设多家煤炭交易中心，已在煤炭物流、质检、交易、金融等方面打造生态圈。',
            '货达网络作为大宗商品行业领先的供应链科技公司，凭借领先的技术与完备的服务能力，加快互联网、物联网、大数据、云计算等新一代信息技术与传统大宗物流行业深度融合，转变传统运营管理模式，实现对信息流、商流、物流、资金流的智慧管控，建立弹性、敏捷的供应链体系，构建“互联网+产业”的新型生态圈，推动大宗商品供应链向数字化发展，实现产业降本增效和转型升级。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail10/img4.png')],
        },
      ],
    },
    32: {
      title: '2019年“数字供应链中国行——西安站”成功召开',
      date: '2019/06/24',
      article: [
        {
          section: [
            '2019年6月21日，“数字供应链中国行——西安站”会议在西安蓝溪国际酒店隆重召开。本次会议由中国物流与采购联合会主办，中国物流与采购联合会物联网技术与应用专业委员会、陕西省物流与采购联合会网络货运专业委员会、西安货达网络科技有限公司承办。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail11/img1.png')],
        },
        {
          section: [
            '会议主题围绕能化领域数字供应链生态体系探讨及平台建设展开，共设有三大主题演讲。邀请了政府、协会、高校、能化生产企业及能化物流运输企业、装备制造服务商、信息技术服务企业、金融机构等各界计60余家单位参会，共同探讨了传统能源化工行业体系在信息化、数字化背景下面临的机遇与挑战。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail11/img2.png')],
        },
        {
          section: [
            '出席本次研讨会的有陕西省商务厅流通业发展处调研员彭远华，陕西省道路运输事业发展中心货运处处长艾海燕，陕西省物流与采购联合会会长孙继民以及榆林市商务局、发改委等单位相关领导，陕西煤炭交易中心有限公司、陕西延长石油物流集团有限公司、陕西省物流集团有限责任公司、陕西省大数据集团有限公司、陕西汽车控股集团有限公司、陕西省金融资产管理股份有限公司、华能铜川照金煤电有限公司、尧柏特种水泥集团有限公司、榆林市安通石化物资转运有限公司等省属大型国企及知名民企。',
            '陕西省商务厅流通业发展处调研员彭远华为本次会议发表开幕致辞：国家供应链战略推行2年以来，陕西省商务厅对于现代供应链在商业、农业等经济领域做了大量的宣传和服务推广工作，但在生产资料领域，特别是大宗生产资料领域的供应链体系建设推广工作还是严重不足的。',
            '对于中国物流与采购联合会在全国推广现代供应链体系建设、开展“数字供应链中国行”活动，彭远华表示高度肯定，并希望通过与会专家、学者和企业家准确解读国家和省委、省政府相关政策，帮助地方政府、企业提高对数字供应链体系建设的认识，促进数字供应链体系的研究和发展，同时希望各与会企业也因此切实抓住政策机遇，加快推进企业的数字供应链体系建设，共同参与到国家能化行业的数字供应链体系建设当中来。',
            '会议致辞过后，中物联物联网专委会秘书长吕忠、西安货达网络科技有限公司CEO折大伟、中物联物联网专委会标准部部长沈启星分别就数字供应链、数字供应链助推能化领域“三个经济”、数字供应链标准体系建设等方面发表了精彩演讲。',
            '除数字仓库、数字运输、数字商务、数字金融、数字平台等具体应用场景外，吕忠特别提到了供应链政府公共服务和治理新模式，畅想未来企业发展管理平台化的趋势，并强调现阶段人工智能、工业互联网、物联网等新型基础设施的重要性。',
            '沈启星则主要分享我国现在能化领域数字供应链标准化体系如电子凭证、数字仓库、数据库云平台等标准建设内容，及标准化体系建设对于支撑实体企业及其数字供应链平台体系落地应用，提高供应链环节信息集中度、安全性、规范性的重要意义。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail11/img3.png')],
        },
        {
          section: [
            '陕西省物流与采购联合会会长孙继民指出，数字供应链应用及推广是全省物流行业大事，本次会议作为一次很好的学习推广契机，为各生产制造及流通企业创新发展模式都将带来很大的启发。',
            '陕西省物流学会副秘书长王宏斌对此也表示，未来能化领域数字供应链实践应用，将对于如何实现物流企业弯道超车、落实陕西省三个经济，破除物流企业当下运作难、利润薄的困境等具有关键指导作用。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail11/img4.png')],
        },
        {
          section: [
            '对于上述数字供应链如何落地到传统企业，折大伟在会议上给出了货达网络对于传统能化企业供应链改造及转型升级、助推陕西省发展三个经济的实践应用案例。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail11/img5.png')],
        },
        {
          section: [
            '根据全国公路物流发展现状以及市场格局的分布，折大伟指出，在能化行业，存在大宗整车运输体量大，集中化程度低，运力组织程度低，结算支付在线化程度低，集团型企业物资运输信息平台缺失，运输集团化管控难等问题。货达网络则以陕甘宁蒙晋等区域内大宗商品物流为切入点，自主研发出电厂、煤矿、焦化厂、铁路集运站等能化企业及流通企业智慧供应链信息系统，解决了企业内部集团化管控难题、实现了产、运、销一体化服务，进而推动全链条业务可视化、结算支付在线化程度的提升，服务了淮北矿业集团、中煤集团、华能集团等大型企业。西安货达网络科技有限公司也在本次会议中荣获由中国物流与采购联合会授予的数字供应链西安站优秀团队奖。',
          ],
          sectionImg: [],
        },
      ],
    },
    33: {
      title: '陕西省物流与采购联合会网络货运专业委员会成立',
      date: '2019/05/09',
      article: [
        {
          section: [''],
          sectionImg: [getServiceImagePath('press/newsDetail12/img1.png')],
        },
        {
          section: [
            '陕西省物流与采购联合会会长孙继民与西部数字经济研究院院长、陕西省经济学会会长张鸿教授给陕物联副会长兼陕物联网络货运专业委员会主任、西安货达网络科技有限公司总经理折大伟授牌。',
            '陕西省《关于大力发展“三个经济”的若干政策》的出台，以快陕西发展枢纽经济、门户经济、流动经济，全面构建陕西开放新格局，推动陕西高质量发展，谱写追赶超越新篇章。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img2.png')],
        },
        {
          section: [
            '陕西省物流与采购联合会认真学习领会“三个经济”精神，积极发挥行业协会作用，5月7日成立了“陕西省物流与采购联合会网络货运专业委员会”，以便精准搭建行业企业服务平台，切实有效引领企业实现加快转型，步入高质量发展，为陕西经济发展做出更大贡献。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img3.png')],
        },
        {
          section: [
            '陕西省物流与采购联合会网络货运专业委员会设立在了西安货达网络科技有限公司，以充分发挥行业优秀企业的专业引领作用。',
            '在当天的陕物联网络货运专业委员会成立暨业务发展座谈会上，西部数字经济研究院院长、陕西省经济学会会长、陕西省决策咨询委委员、西安邮电大学经管院院长、陕物联名誉会长张鸿教授，对陕物联网络货运专委会的成立提出了具体指导。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img4.png')],
        },
        {
          section: [
            '张鸿教授特别强调，陕物联网络货运专委会要不断提升陕西省物流业对数字经济与物流发展的认识，能为陕西货运的网络化、数字化、智能化、融合化等发展，探索几种物流货运发展创新新模式。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img5.png')],
        },
        {
          section: [
            '中国物流学会副会长、中国物流与采购联合会物流企业综合评估委员会委员、物流园区专业委员会专家委员会副主任、商务部商贸物流领域专家、中国电商物流产业联盟顾问兼专家委员会副主任、中国电子商务创新推进联盟专家委员会委员、陕西省决策咨询委员会委员、陕西省物流学会会长、西安交大管理学院院长、陕物联名誉会长冯耕中教授，在听取陕物联副会长兼陕物联网络货运专业委员会主任、西安货达网络科技有限公司总经理折大伟对专委会及公司发展业态后强调，我们要肩负起推动陕西省物流业迈上健康、高质量发展的责任！要切实关注、研究影响陕西物流业发展中的问题，积极为省政府建言献策。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img6.png')],
        },
        {
          section: [
            '陕物联网络货运专委会的成立，也得到了中共陕西省委党校教授、中国金融创新发展理事会专家委员会专家、西安交通大学特聘创业导师、陕西省商务厅等特聘物流金融专家、西安市商务局财政局特聘流通专家、陕物联高级顾问白山稳教授的指导，当他调研陕物联副会长单位兼陕物联网络货运专业委员会主任、西安货达网络科技有限公司后表示，陕西省物流发展在这些年轻人努力工作、创新发展的状态中看到了美好未来！',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img7.png')],
        },
        {
          section: [
            '白教授以多角度的视野和全方位的思维，与折大伟及参加座谈的同仁们，为合力推动陕西物流发展进行了广泛深入交流。',
            '座谈会上，陕物联副会长兼陕物联网络货运专业委员会主任、西安货达网络科技有限公司CEO折大伟，针对网络货运可能发展的方向，以及网络货运需要的行业性服务等进行了深入思考讲解。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img8.png')],
        },
        {
          section: [
            '参加座谈会的物流学者、企业负责人，大家就相关议题进行了自由发言，提出自己的发展建议。陕西省物流与采购联合会常务副会长赵志祥还就近期陕物联要开展的工作进行了简要通报，会长孙继民对网络货运专业委员会提出了具体工作要求。',
          ],
          sectionImg: [getServiceImagePath('press/newsDetail12/img9.png')],
        },
      ],
    },
  }

  return { newsCompanyDetailData }
}

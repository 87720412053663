import { Goods } from '@/utils/scrollWatch';
import { Button } from 'ant-design-vue/es';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Cooperation',
  props: {
    title: {
      type: String
    },
    buttonTxt: {
      type: String,
      default: '立即咨询'
    }
  },
  components: {
    'a-button': Button
  },
  emits: ['openConsultModdal'],
  setup(props, {
    emit
  }) {
    const windowGlobal = Goods.getters('windowGlobal');
    const boxPadding = ref(windowGlobal.boxPadding);
    const openModdal = () => {
      emit('openConsultModdal');
    };
    return {
      openModdal,
      boxPadding
    };
  }
});
import { Goods } from "../scrollWatch"
const pressScroll = (scroll) => {
    scroll('rail-container', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('railContainer', { showTime: showTime })
    })
    scroll('idff-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('idffContent', { showTime: showTime })
    })
    scroll('platform-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('platformContent', { showTime: showTime })
    })
    scroll('chart-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('chartContent', { showTime: showTime })
    })
    scroll('software-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('softwareContent', { showTime: showTime })
    })
    scroll('advance-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('advanceContent', { showTime: showTime })
    })
}

export default pressScroll
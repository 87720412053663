import { defineComponent, ref, reactive, toRefs, onMounted, watch } from 'vue';
import SonBannerTabs from '@/components/SonBannerTabs';
import { Goods } from '@/utils/scrollWatch';
import CompanyNews from './components/listPage/companyNews/index';
import PartyNews from './components/listPage/partyNews/index';
import EnterpriseNews from './components/listPage/enterpriseNews/index';
import { tabList } from './enums';
export default defineComponent({
  components: {
    SonBannerTabs,
    CompanyNews,
    PartyNews,
    EnterpriseNews
  },
  setup() {
    const ssessionPressShowTime = Goods.getters('sessionPress');
    const windowGlobal = Goods.getters('windowGlobal');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const bannerTabsRef = ref();
    const menuActive = Goods.getters('menuActive');
    const state = reactive({
      bannerHeight,
      height,
      boxPadding,
      currentActiveMenu: 'CompanyNews'
    });
    // 业务
    const componentName = ref(tabList[0].componentName);
    const handleCutTab = (data, cbFn) => {
      componentName.value = data.componentName;
      bannerTabsRef.value.setActiveTab(data.componentName);
      state.currentActiveMenu = data.componentName;
    };
    onMounted(() => {
      bannerTabsRef.value.setActiveTab(menuActive.value.name);
    });
    watch(menuActive, (n, e) => {
      state.currentActiveMenu = n.name;
      bannerTabsRef.value.setActiveTab(n.name);
    });
    return {
      ...toRefs(state),
      ssessionPressShowTime,
      tabList,
      componentName,
      handleCutTab,
      bannerTabsRef
    };
  }
});
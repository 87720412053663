import { Goods } from "../scrollWatch"
const pressScroll = (scroll) => {
    scroll('energy-idff-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('energyIdffContent', { showTime: showTime })
    })
    scroll('energy-platform-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('energyPlatformContent', { showTime: showTime })
    })
    scroll('energy-chart-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('energyChartContent', { showTime: showTime })
    })
    scroll('energy-software-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('energySoftwareContent', { showTime: showTime })
    })
    scroll('energy-advance-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('energyAdvanceContent', { showTime: showTime })
    })
}

export default pressScroll
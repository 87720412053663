import { saveInfo } from '@/API/modules/common';
import { successMssage, errorMssage } from '@/utils/operator';
import { Modal, Form, FormItem, Input, Textarea, Button } from 'ant-design-vue/es';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    width: {
      type: String,
      default: '40%'
    }
  },
  components: {
    'a-modal': Modal,
    'a-form': Form,
    'a-input': Input,
    'a-form-item': FormItem,
    'a-textarea': Textarea,
    'a-button': Button
  },
  setup(props, ctx) {
    const visible = ref(false);
    const bodyStyle = {
      padding: '0px 50px 30px 50px'
    };
    const formRef = ref();
    const formState = ref({
      name: '',
      phone: '',
      companyName: '',
      remark: ''
    });
    const rules = {
      name: [{
        required: true,
        message: '请填写您的姓名',
        trigger: 'blur'
      }],
      phone: [{
        required: true,
        message: '请填写您的手机号',
        trigger: 'blur'
      }],
      companyName: [{
        required: true,
        message: '请填企业/单位名称',
        trigger: 'blur'
      }]
    };
    const useEvent = () => {
      return {
        afterCloseHandle: () => {
          // formRef.value.resetFields()
          // formRef.value.clearValidate()
          console.log('formRef', formRef);
        },
        open() {
          visible.value = true;
        },
        cancel() {
          visible.value = false;
          formRef.value.resetFields();
          formRef.value.clearValidate();
        },
        async submitHandle() {
          const valida = await formRef.value.validate();
          if (valida) {
            const res = await saveInfo(formState.value);
            if (res) {
              successMssage(res.errmsg);
              setTimeout(() => {
                visible.value = false;
              }, 500);
            } else {
              errorMssage('数据有误');
            }
          }
        }
      };
    };
    return {
      visible,
      bodyStyle,
      formState,
      rules,
      formRef,
      ...useEvent()
    };
  }
});
const getFigureEnertgyList = function () {
    const stationList = [
      {
        title: '加油加气',
        content: '提供柴油汽油、液化天然气等多品类供给，快捷支付',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy1.png')
      },
      {
        title: 'ETC免费办',
        content: '线上资料填录上传，0元免押一键申请，满足货车主出行需求',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy2.png')
      },
      {
        title: '货运保险',
        content: '运输安全、货物安全保险，及时赔付，降低运输风险',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy3.png')
      },
      {
        title: '司机驿站',
        content: '多方联合共建“司机之家”，为货车出行提供贴心服务',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy4.png')
      },
    ]
    const targetList = [
      {
        title: '电动重卡',
        content: '国家政策鼓励大力发展电动重卡，支持“车电分离”等新型商业模式发展',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy5.png')
      },
      {
        title: '换电站覆盖',
        content: '与货达丰富的业务场景共享运营，基于运输通道进行精准站点规划',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy6.png')
      },
      {
        title: '多场景应用',
        content: '可适配矿山、大型钢厂等封闭场景下短倒运输营运作业，技术路线成熟',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy7.png')
      },
      {
        title: '减碳增效',
        content: '新技术使用可实现零排放、零污染的绿色运输，减少企业停产限产天数',
        img:require('@/assets/img/product/figureEnertgy/figureEnertgy8.png')
      }
    ]
    return {stationList, targetList}
  }
  
  export {  getFigureEnertgyList }
  
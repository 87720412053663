import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { deepClone, getDataType } from '@/utils/index'

import { message } from 'ant-design-vue/es'
// import Utils, { deepClone } from '@/utils/inde'
import { config, WH_CONFIG } from '../../config/service'
import { getLocal } from '@/utils/dataParse'

const TIME_OUT = 30000

const SUCCESS_CODE = 1000
const ERROR_PRE_MESSAGE = '网络异常'
const AUTH_FAIL_CODE = 210000
const TOKEN_FAIL_CODE = 900
const ERROR_CODE_500 = 500
const ERROR_CODE_9999 = 9999
const ERROR_LOG_CODE = [500, 503, 403, 405]
//记录所有不拦截的业务code
let I_NEED_CODE = [
  1000, 200, 210004, 210003, 210005, 201046, 210006, 201073, 204006, 204005, 204008, 204009, 204011,
  204012,
]
const batchsActionCode = [202001, 201059, 202015, 204014, 202033, 202011, 202017, 202016]
I_NEED_CODE = I_NEED_CODE.concat(batchsActionCode)

export enum SERVICE_TYPE {
  WH = 'WH',
  LJ = 'LJ',
}

const tools = (function () {
  const t = {
    serviceType: '',
    router: undefined,
    store: undefined,
    preReqest: {},
    setRouter: (router) => {
      t.router = router
    },
    getRouter() {
      return t.router
    },
    setStore(store) {
      t.store = store
      // console.log('getStore store', store)
    },
    getStore() {
      return t.store
    },
    clearStatus() {
      const store: any = t.getStore()
      setTimeout(() => {
        // message.destroy()
      }, 1000)
      store.commit('app/loadingSpin', false)
    },
  }
  return t
})()

// const config = {
// 	development: 'https://dev-crmgateway.huodada.com/',
// 	dev: 'https://dev-crmgateway.huodada.com/',
// 	isTest: 'https://test-crmgateway.huodada.com/',
// 	pre: 'https://pre-crmgateway.huodada.com/',
// 	production: 'https://crm-gateway.huodada.com/'
// }

// 网关错误码--》令牌检验失败:900 接口无权限:901
function request(ctx) {
  const $request = (({ app, router }) => {
    {
      //   console.log(process.env)

      let baseURL
      if (tools.serviceType === SERVICE_TYPE.WH) {
        baseURL = WH_CONFIG[process.env.NODE_ENV]
      } else {
        baseURL = config[process.env.NODE_ENV]
        if (process.env.NODE_ENV === 'development') {
        }
        baseURL = '/api'
      }

      console.log('baseURL', baseURL)

      const service = axios.create({
        baseURL: baseURL,
        timeout: TIME_OUT,
      })

      service.interceptors.request.use(
        (config) => {
          //   const TOKEN = getLocal('TOKEN', tools.getStore())
          //   // console.log('config', config)
          //   if (TOKEN) {
          //     config.headers.common['Authorization'] = TOKEN
          //   }
          return config
        },
        (error) => {
          tools.clearStatus()
          Promise.reject(error)
        },
      )

      service.interceptors.response.use(
        (response: AxiosResponse<any>): any => {
          if (response.status === 200) {
            const data = response.data

            // console.log('data', data)
            // clearErrorStatus(response)
            return responseStatus(data)
          } else {
            return undefined
          }
          async function responseStatus(res) {
            const { code, data, message } = res

            const { config } = response
            const { url, method, data: queryData } = config

            if (I_NEED_CODE.includes(Number(code))) {
              return new Promise((resolve, rejeact) => {
                resolve(res)
              })
            } else {
              return new Promise((resolve, rejeact) => {
                resolve(res)
              })
            }
          }
        },
        (error) => {
          console.log('==============================================')
          //   errorMessage(`${ERROR_PRE_MESSAGE} 请耐心等候`)
          //   loggerErrorStatus(error)
          //   tools.clearStatus()
          return Promise.reject(error)
        },
      )

      const $Request = {
        axios: service,
        get: function (url: string, params, options) {
          tools.serviceType = ''
          if (options) {
            tools.serviceType = options.serviceType
          }

          const serviceSetting: AxiosRequestConfig = {
            url: url,
            method: 'get',
            params: params,
          }

          return service(serviceSetting)
        },
        post: function (url, params, options) {
          tools.serviceType = ''
          if (options) {
            tools.serviceType = options.serviceType
          }

          console.log('tools.serviceType', tools.serviceType, url)

          return service({
            url: url,
            method: 'post',
            data: params,
          })
        },
        file: function (url, params, options) {
          //todo
        },
      }

      return $Request
    }
  })(ctx)

  request.prototype.service = $request

  return $request
}

const getRequest = () => {
  return request.prototype.service
}

const loggerAxiosOpen = () => {
  const store: any = tools.getStore()
  store.dispatch('app/errorStatus', {
    type: 'axiosOpen',
    code: 0,
  })
}

const clearErrorStatus = (response) => {
  const store: any = tools.getStore()
  const { config, data } = response
  const errorStatus = deepClone(store.getters.errorStatus)
  if (errorStatus) {
    const path = config.url.split('/')
    const url: string = path[path.length - 1]
    if (Reflect.has(errorStatus, 'unKnown')) {
      delete errorStatus['unKnown']
      store.dispatch('app/errorStatus', errorStatus)
    }
    if (errorStatus[url]) {
      delete errorStatus[url]
      store.dispatch('app/errorStatus', errorStatus)
    }
  }
}

const loggerErrorStatus = (error) => {
  console.log('error', error.response)

  const store: any = tools.getStore()
  const response = error.response
  const errorStatus = {
    ...store.getters.errorStatus,
  }
  if (errorStatus) {
    const errorType = getDataType(error)

    if (!response) {
      errorStatus['unKnown'] = {
        type: 'networkError',
        code: 9595,
        data: undefined,
      }
    } else if (response) {
      const { config, data } = response
      const path = config.url.split('/')
      const url: string = path[path.length - 1]
      errorStatus[url] = {
        type: 'axiosError',
        code: data.status,
        data: response.data,
        currentpath: path[path.length - 1],
        path,
      }
    }

    console.log('errorStatus', errorStatus)

    store.dispatch('app/errorStatus', errorStatus)
  }
}

export { getRequest }

export default function ({ app, router }: any) {
  tools.setRouter(router)
  //   tools.setStore(store)

  app.config.globalProperties.$Request = request({ app, router })
  app.config.errorHandler = (err, instance, info) => {
    console.error('error', err)
    console.error('error info', info)
    // store.commit('app/loadingSpin', false)
  }
}

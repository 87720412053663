import "core-js/modules/es.array.push.js";
import { Goods } from '@/utils/scrollWatch';
import { Tabs, Divider, Button, Space } from 'ant-design-vue/es';
import { defineComponent, ref, watch, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default defineComponent({
  name: 'LayoutHeader',
  components: {
    'a-tabs': Tabs,
    'a-button': Button,
    Divider,
    Space
  },
  setup(props, ctx) {
    const windowGlobal = Goods.getters('windowGlobal');
    const route = useRoute();
    const currentPage = route.path.split('/')[1];
    const activeKey = ref(currentPage);
    const router = useRouter();
    const active = ref(false);
    const activeSecondMenu = ref('coal');
    const activeModluesMenu = ref('');
    const currentRouteName = ref();
    const boxPadding = ref(windowGlobal.boxPadding);
    const state = reactive({
      boxPadding,
      tabBarGutter: 40,
      spaceSize: 20
    });
    if (windowGlobal.windowWidth > 1000 && windowGlobal.windowWidth < 1280) {
      state.tabBarGutter = 40;
      state.spaceSize = 10;
    } else if (windowGlobal.windowWidth > 1280 && windowGlobal.windowWidth < 1579) {
      state.tabBarGutter = 60;
      state.spaceSize = 20;
    } else if (windowGlobal.windowWidth > 1579) {
      state.tabBarGutter = 80;
    }
    //   console.log('clg windowGlobal.windowWidth', windowGlobal.windowWidth)
    watch(route, (n, o) => {
      // console.log('watch n', n)
      currentRouteName.value = n.name;
      const currentPage = route.path.split('/')[1];
      activeKey.value = currentPage;
    });
    const useTabEvent = () => {
      const tabChangeHandle = v => {
        Goods.dispatch('menuActive', {
          name: 'coal',
          active: true
        });
        if (v === 'product') {
          activeModluesMenu.value = 'figure-link';
          activeSecondMenu.value = 'coal';
        } else {
          activeModluesMenu.value = '';
          activeSecondMenu.value = '';
        }
        //   console.log('v', v)
        router.push(v);
      };
      const menuHoverHandle = type => {
        if (type === 'product') {
          active.value = true;
        } else {
          active.value = false;
        }
        //   console.log('menuHove')
      };

      const menuClickHandle = type => {
        activeSecondMenu.value = type;
        activeModluesMenu.value = type;
        let activeType = type;
        //   console.log('currentRouteName.value', currentRouteName.value)
        if (type === 'figure-finance') {
          router.push('finance');
        } else if (type === 'figure-link') {
          activeType = 'coal';
          router.push('product');
        } else if (type === 'figure-freight') {
          //数字货运
          router.push('freight');
        } else if (type === 'figure-enertgy') {
          // 数字能源
          router.push('figureEnertgy');
        } else {
          console.log('============>>type else ', activeType);
          Goods.dispatch('scrollToTop', {
            name: type,
            active: true
          });
          // console.log('=========>>currentRouteName.value')
          if (route.name !== 'product') {
            if (['coal', 'chemical', 'power', 'rail', 'energy'].includes(type)) {
              router.push('product');
            }
          }
          activeModluesMenu.value = 'figure-link';
        }
        Goods.dispatch('menuActive', {
          name: activeType,
          active: true
        });
      };
      return {
        menuClickHandle,
        tabChangeHandle,
        menuHoverHandle
      };
    };
    const useChildrenMenu = () => {
      const childrenMenuMouseLeave = () => {
        active.value = false;
      };
      return {
        active,
        childrenMenuMouseLeave
      };
    };
    const toLoginClickHandle = () => {
      window.open('https://qiye.yunxiaobao.com/#/login', '_blank');
    };
    return {
      toLoginClickHandle,
      currentRouteName,
      activeSecondMenu,
      activeModluesMenu,
      activeKey,
      ...toRefs(state),
      ...useTabEvent(),
      ...useChildrenMenu()
    };
  }
});
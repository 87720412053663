import { getServiceImagePath } from '@/utils'
// sectionImg: [require('@/assets/img/press/new20230928/2023-09-28-1.png')],
export function useNewsEnterpriseDetail() {
  const newsEnterpriseDetailData = {
    1: {
      title: '微光已现，何惧远航丨货达销售中心2023年终会议',
      date: '2024/01/19',
      article: [
        {
          section: [
            `1月18日，<span class="font-bold">西安货达网络科技有限公司</span>（以下简称“货达网络”）2023年销售中心年终会议圆满举办。来自各大区的销售团队、业务负责人等团聚一堂，携手回顾2023年丰硕工作成果，共同迎接甲辰龙年新气象，擘画未来发展蓝图！`,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-01.png')]],
        },
        {
          section: [
            `<span class="font-bold">微光已现，何惧远航。</span>年终会议在活动组精心布置的充满新年氛围的会场中热烈展开......此起彼伏的掌声与欢呼声掩不住大家高昂的情绪，伴随着主持人热情饱满的开场白，此次年终会议正式开始。
            `,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-02.png')]],
        },
        {
          section: [
            `
              回顾2023全年发展历程，外部市场环境动荡且行业上下游竞争激烈，货达全员始终坚守信念，不畏困难艰险，取得了良好成绩。<span class="font-bold">货达网络 CEO折大伟表示，</span>公司及团队发展始终初心如磐，目标明确，前路发展荆棘满布之时仍能勇毅前行。同时，对大家过去一年取得的工作成绩表示了充分肯定，并为新的一年加油鼓劲。
              `,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-03.png')]],
        },
        {
          section: [
            `
              新的一年，蕴藏着无限的机遇和挑战。<span class="font-bold">销售中心负责人 杨耿</span>对未来的发展做出展望，并向大家分享公司的战略规划和市场趋势。同时也承诺将继续听取员工们对于自身及公司发展的建议和期望，共同推动企业持续创新和进步。
                `,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-04.png')]],
        },
        {
          section: [
            `
              新技术、新产品和新模式的商业化演变、市场化验证离不开每位货达人的努力。嘉奖表彰环节又将现场活动气氛推向另一个高潮。年终会议为大家提供一个了解公司发展情况、感受成果的机会，同时也表达了对于各位辛勤付出的肯定。让我们一同为成功欢呼，为战果喝彩！
                  `,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-05.png')]],
        },
        {
          section: [
            `
            一场场精心编排、妙趣纷呈的节目让现场爆发出阵阵欢呼和掌声。无论是时下热门舞蹈还是传统腰鼓、曲艺等其他形式表演，都展示了大家的创意才华和团队凝聚力。更让我们相信，在货达，每一位员工都是独特的个体，拥有自己的才情和魅力。
                  `,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-06.png')]],
        },
        {
          section: [
            `
            作为加强员工之间的沟通与合作，增进团队凝聚力的素质拓展运动，为年终会议的下半场拉开帷幕。身着红蓝队服的众人进入活动赛场，为热血PK提前热身，投篮、拔河、射箭、跳绳.......一项项体育比赛，让大家挥洒汗水完全沉浸其中，放松心情的同时享受集体的欢乐时光，也为彼此之间的默契配合，为公司的发展打下团队合作基础。
                  `,
          ],
          sectionImg: [[require('@/assets/img/press/enterprise20240119/20240119-07.png')]],
        },
        {
          section: [
            `
            <span class="font-bold">起步就是冲刺，开局就是决战。</span>在新的一年里，货达全员将继续秉承创新引领的理念，保持全力奋进姿态，不断提升业务服务质量和客户满意程度，助力客户创造更大价值。为“货达十年”征程奏响高质量发展的乐章，迎接更加美好的明天！
                  `,
          ],
        },
      ],
    },
    2: {
      title: '鏖战年终丨货达集团2023年Q4季度销售大会启动冲刺之战',
      date: '2023/11/06',
      article: [
        {
          section: [
            `<span class="font-bold">夺魁战鼓阵阵高，秣马厉兵创新绩！</span>11月6日，货达集团销售中心第四季度冲刺大会在总部顺利召开，相关部门负责人、各大区负责人、区域负责人及业务骨干共同参会。<span class="light-red">总结成果，明确目标，激发动力，共同向Q4业绩目标达成发起冲锋！</span>`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20231106/20231106-1.png')]],
        },
        {
          section: [
            `<span class="font-bold">部门各业务单元负责人分别进行了专题汇报。</span>系统总结前季度业绩工作，同时对照年初目标深入剖析当前工作开展面临的痛难点，针对第四季度工作进行了详实规划。中台管理杜宁、销售运营葛思妤、HRBP李婧分别围绕销售团队目标设定与任务拆解、运营分析及重点把握、人力资源支持与相关制度规范进行了全体宣贯。`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20231106/20231106-2.png')]],
        },
        {
          section: [
            `<span class="font-bold">货达集团销售中心负责人杨耿在启动会上发表讲话。</span>围绕大会主题“鏖战年终，剑指巅峰”，对团队过往取得成绩给予充分肯定与评价，同时审视长期存在的问题，并针对第四季度冲刺工作进行了统筹安排和部署。鼓励动员大家以更加饱满的热情、更加充足的干劲锚定目标不松懈，大干四季度，打好收官战，为2023年划上圆满句号！`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20231106/20231106-3.png')]],
        },
        {
          section: [
            `<span class="font-bold">会上，对于前季度表现突出的个人及团队也进行了表彰和奖品颁发。</span>内蒙大区负责人陈子贤、陕西大区负责人解飞、山西大区负责人宫敏杰、集团KA折存等分别为其颁发奖项。这是对大家过去努力的肯定，同时鞭策全体员工，激励各位在年底冲关之时凝心聚力，奋力一搏！`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20231106/20231106-4.png')]],
        },
        {
          section: [
            `<span class="font-bold">军令如山，使命必达。</span>临近会议最后，各区域销售骨干们分别进行PK并签署军令状、喊响冲刺口号，昂扬士气、坚定信念，吹起决战冲锋号，燃起冲锋必胜心！各大区上下紧盯年底目标不动摇，保持“加压冲刺”的昂扬风貌、分秒必夺，真抓实干决战四季度、夺取全年红，奋力交出年度高质量发展的满意答卷。`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20231106/20231106-5.png')]],
        },
        {
          section: [
            `<div class="light-red font-bold row-flex-column-center">
            <span>满弓紧弦、枕戈待旦，</span>
            <span>决战的号角已经吹响。</span>
            <span>锚定目标、紧盯时序不放松，</span>
            <span>强化行动、保持信念不动摇。</span>
            <span>共饮一杯出征酒，雄心壮志战征途！</span>
            <span>冲刺Q4、赢战全年！</span>
            <span>加油，货达人！</span>
            </div>`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20231106/20231106-6.png')]],
        },
      ],
    },
    3: {
      title: '素拓团建丨货达网络项目中心夏日消暑计划，不负美好时光',
      date: '2023/08/07',
      article: [
        {
          // section: [
          //   '2023年7月10日，西安货达网络科技有限公司（以下简称“货达网络”）2023年中会议在西安高新希尔顿酒店圆满举办。来自全国各地的销售团队、大区经理等业务代表齐聚一堂，与总部同事们一起回顾上半年的工作情况，共同为开启下半场“接力赛”打响头阵！',
          // ],
          sectionImg: [getServiceImagePath('press/enterprise20230807/0807-1.png')],
        },
        {
          section: [
            `
            <div class="row-flex-column-center">
            <span>工作不止朝九晚五的匆匆忙碌</span>
            <span>还需要劳逸结合的休闲时光</span>
            <span>而生活有多美好</span>
            <span>取决于你对它有多热爱</span>
            <span style="font-weight:bold;">在货达</span>
            <span>有快乐的一群人</span>
            <span>在做一件快乐的事</span>
            </div>
            `,
            '按下“暂停键”，卸除快节奏带来的一身疲惫与倦怠，满怀热情，整装出发！炎炎夏日，骄阳无法抵挡满车的欢乐氛围，大家向着清凉的峡谷进发~',
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20230807/0807-2.png')]],
        },
        {
          section: [
            '蒸腾暑气带来的炎热与烦躁感，随着涓涓溪流，一扫而光......从低洼到高空，十几项惊险刺激的户外“运动项目”，令人一路欢笑、一路尖叫。此刻，在这里，所有的不快抛之脑后，所有的烦恼也烟消云散！',
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20230807/0807-3.png')]],
        },
        {
          section: [
            `在激情与挑战交织的活动中，既加强了项目中心各部门之间沟通和协作，培养了并肩作战的默契与合作精神，同时也让大家完成了素质拓展与自我挑战，深刻感受到了集体力量的美好与强大。`,
          ],
          sectionImg: [[getServiceImagePath('press/enterprise20230807/0807-4.png')]],
        },
        {
          section: [
            `个人的力量难免是有限的，团队的力量可以实现个人难以企及的目标，而团队的成功需要我们每个成员共同努力、付诸行动！这一次，我们洗去一身疲倦，拉近了彼此的距离；未来，我们将再接再厉、乘势而上，共同书写更加美好的明天`,
          ],
        },
      ],
    },
    4: {
      title: '“聚平凡，创不凡” —— 货达2023年中会议及团队拓展活动圆满结束！',
      date: '2023/07/10',
      article: [
        {
          section: [
            '2023年7月10日，西安货达网络科技有限公司（以下简称“货达网络”）2023年中会议在西安高新希尔顿酒店圆满举办。来自全国各地的销售团队、大区经理等业务代表齐聚一堂，与总部同事们一起回顾上半年的工作情况，共同为开启下半场“接力赛”打响头阵！',
          ],
          sectionImg: [
            getServiceImagePath('press/enterprise2023-07-10/2023-07-10-1.jpeg'),
            getServiceImagePath('press/enterprise2023-07-10/2023-07-10-2.jpeg'),
          ],
        },
        {
          section: [
            '本次会议在活动组精心准备的欢迎货达家人们入场仪式中“惊喜”展开......飞舞的礼花掩不住现场温暖热情的氛围，伴随着主持人状态饱满的开场白，活动正式拉开了序幕。',
            {
              style: 'margin-top:10px;',
              title: '1.致辞&发言',
            },
          ],
          sectionImg: [
            [
              getServiceImagePath('press/enterprise2023-07-10/2023-07-10-3.jpeg'),
              '▲ 货达 CEO-折大伟致辞',
            ],
          ],
        },
        {
          section: [
            '回顾2023上半年，煤炭等能源价格随市场行情波动，其间接影响大宗商品贸易及物流供应链业务展开。货达团队始终秉持对于深耕大宗领域的信心与决心，各销售大区凝心聚力、勇于突破，终于在上半年收官之际，圆满达成业绩预期！这不仅是每位货达人共同努力的成果，更彰显了面对一个又个“不可能”时，货达人迸发而出的实力与潜力！让我们以此为新起点，继续一路高歌、披荆斩棘。',
          ],
          sectionImg: [
            [
              getServiceImagePath('press/enterprise2023-07-10/2023-07-10-4.jpeg'),
              '▲ 货达 中台管理-杜宁发言',
            ],
            [
              getServiceImagePath('press/enterprise2023-07-10/2023-07-10-5.jpeg'),
              '▲ 货达 网货总经理-杨耿发言',
            ],
          ],
        },
        {
          section: [
            {
              title: '2.现场颁奖',
              style: 'margin-top:10px;',
            },
          ],
          sectionImg: [getServiceImagePath('press/enterprise2023-07-10/2023-07-10-6.jpeg')],
        },
        {
          section: [
            '荣誉的背后写满无数个日夜的坚持与辛勤的付出，荣誉的颁发是对他们所取得的辉煌成果的认可，同时也会成为激励大家继续追求卓越、超越自我的动力。在这里，向这些为公司做出杰出贡献的货达人致以诚挚的祝贺！',
            {
              title: '3.拓展分享',
              style: 'margin-top:10px;',
            },
          ],
          sectionImg: [getServiceImagePath('press/enterprise2023-07-10/2023-07-10-7.jpeg')],
        },
        {
          section: [
            '在个人分享环节，各大区业务代表先后上台，就个人职业发展路径、工作开展痛难点突破以及个人工作心得等进行了热忱分享。随后，无比烧脑却又十分考验团队精神的游戏——“盗梦空间”火热进行，分组成员们通过游戏迅速拉近了彼此的距离！满场回荡的喧嚣笑声此起彼伏，感染了众人情绪，也将现场热烈的氛围再次推向高潮。',
            {
              title: '4. 趣味运动会',
              style: 'margin-top:10px;',
            },
          ],
          sectionImg: [
            getServiceImagePath('press/enterprise2023-07-10/2023-07-10-8.jpeg'),
            getServiceImagePath('press/enterprise2023-07-10/2023-07-10-9.png'),
          ],
        },
        {
          section: [
            '随着一阵阵哨声响起，十几组成员们开始完成裁判发布的一系列限时运动项目“挑战”。大家在激烈而紧张的竞技氛围中不断尝试，在努力突破协同作战的过程中凝聚了团队力量，也加深了信任与了解。此时此刻，我们同心同行、同在同战；今后，我们将继续谱写美好未来！2023，货达人加油！',
          ],
        },
      ],
    },
    5: {
      title: '“码出未来，码到橙功”——NO BUG!1024货达程序员节',
      date: '2022/10/24',
      article: [
        {
          section: [],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail1/img1.png')],
        },
        {
          section: ['#1024程序员节日快乐#'],
          sectionImg: [],
        },
        {
          section: [
            '有人说，这个世界需要更多的疯子和偏执狂',
            '但真正将那些天马行空的想象变为现实的',
            '很大几率正是看似平凡的程序员',
            '你们保证了系统的稳定、高效',
            '不断深入、优化模型、提升用户体验',
            '在货达，程序员们至关重要',
            '你们的恪尽职守',
            '让业务有了生根发芽的基础',
            '让我们有了抵达用户的桥梁',
            '在这个属于程序员的特殊日子里，',
            '祝福每一位程序员：节日快乐！',
          ],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail1/img2.png')],
        },
        {
          section: [
            '“1024”是计算机操作系统的进制单位，也是专属程序员们的特殊纪念。“码出未来，码到橙功”——货达网络程序员节日主题专场活动如期而至，让我们一起致敬每一位在平凡岗位上创造不平凡的开发者们！',
          ],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail1/img3.png')],
        },
      ],
    },
    6: {
      title: '抓住八月的尾巴，DIY玩转集体生日会',
      date: '2022/8/30',
      article: [
        {
          section: [''],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail2/img1.png')],
        },
        {
          section: [
            '感谢所有奇妙的相遇',
            '让我们相聚在八月',
            '抓住夏天的尾巴',
            '在亲手制作蛋糕中收获的不止是快乐',
            '成就与幸福感是最好的礼物',
            '甜蜜，不仅仅是蛋糕',
            '更是货达满满的爱',
            '“简单、透明、有温度',
            '货达因我wonderful！',
            '8月的寿星们，',
            '生日快乐！',
          ],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail2/img2.png')],
        },
        {
          section: [
            '许愿、吹蜡烛、切蛋糕.......夏末初秋，一场温馨特别的集体生日会活动在货达举办。受邀参与的是十几位当月“寿星”，大家在DIY制作生日蛋糕的过程中、在彼此怀揣祝福许愿的见证下，共同完成了生日庆祝，品尝亲手制作的美味、享受忙里偷闲的轻松时光。在烛光莹莹、笑声阵阵的氛围中，大家感受到的是属于公司大家庭的温馨和关爱。',
          ],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail2/img3.png')],
        },
        {
          section: [
            '年年岁岁，承蒙时光不弃！所有馈赠和成长，激励着货达人在今后的工作中更加认真履职尽责。愿来岁，我们都有期有盼、无虑无忧~',
          ],
          sectionImg: [],
        },
      ],
    },
    7: {
      title: '温情中秋，暖意货达——直击中秋活动现场！',
      date: '2022/9/10',
      article: [
        {
          section: [''],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail3/img1.png')],
        },
        {
          sonTitle: '亲爱的货达伙伴们：',
        },
        {
          section: [
            '又是一年中秋佳节，回望六载风雨，历久弥新，货达走过的每一步，都是信念与梦想的展现；货达的每一次发展与进步，都离不开货达伙伴们的坚守与付出。在我们并肩前行的路上，遇到过挑战与彷徨，也收获了坚韧与成长。因为有你的付出，才有了不断壮大的货达！下半年我们更要坚定执行“占坑、链接、变现”的战略，为成为大宗商品数字供应链第一品牌持续努力，相信在未来的日子里，货达团队可以继续凝心聚力，共创辉煌！',
            '风柔雨润，花好月圆，温情中秋，暖意货达！又是一年中秋佳节之际，衷心祝福货达的伙伴和您的家人：团员美满、幸福安康！',
          ],
          sectionImg: [],
        },
        {
          inscribe: 'CEO  折大伟',
        },
        {
          section: [
            '中秋是我国三大灯节之一，过节当然要玩灯。玩转中秋第一波活动——"玩花灯"，西安&榆林团队梦幻联动，大家创作的过程中不仅收获了快乐，更唤醒了自己的艺术潜能！在彼此交流中传递快乐与温暖，在灵感碰撞中感受组织的温度。',
          ],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail3/img2.png')],
        },
        {
          section: [
            '“玩转中秋”第二波活动——“猜灯谜”。如果说DIY花灯是考验大家的动手能力，那么猜花灯就是拼脑力拼手速的时候啦！大家热情高涨、积极参与，开动脑筋的同时更有趣味小礼品相送。',
          ],
          sectionImg: [getServiceImagePath('press/enterpriseNewsDetail3/img3.png')],
        },
        {
          section: [
            '“玩转中秋”第三波—“派月饼”喽！月饼象征着大团圆，人们把它当作节日食品，用它祭月、赠送亲友；至今，吃月饼已经是中国南北各地过中秋节的必备习俗。货达小伙伴已经陆续拿到了今年的定制月饼，希望这份佳节礼物承载的不止是组织的甜蜜，更多的是货达六载的历久弥新和对每位货达人的感恩！',
          ],
          sectionImg: [],
        },
        {
          section: ['温情中秋，暖意货达。祝各位货达家人们：团圆美满、幸福安康！'],
          sectionImg: [],
        },
      ],
    },
  }

  return { newsEnterpriseDetailData }
}

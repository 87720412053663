import { Goods } from "../scrollWatch"
const coalScroll = (scroll) => {
    scroll('idff-content', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('idffContent', { showTime: showTime })
        const team = Goods.getters('idffContent')
    })
    scroll('transport-sale-system', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('transportSaleSystem', { showTime: showTime })
        const team = Goods.getters('transportSaleSystem')
    })
    scroll('soft-view', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('softView', { showTime: showTime })
        const team = Goods.getters('softView')
    })
    scroll('device-content', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('deviceContent', { showTime: showTime })
        const team = Goods.getters('deviceContent')
    })
    scroll('center-content', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('centerContent', { showTime: showTime })
        const team = Goods.getters('centerContent')
    })
}

export default coalScroll
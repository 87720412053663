import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useNewsList } from '../hooks/use-news-list';
import NewsListShow from '../../../newsList/index';
import { useNewsOneShow } from '../hooks/use-news-one-show';
export default defineComponent({
  components: {
    NewsListShow
  },
  setup() {
    const {
      newsCards_one,
      newsCards_two,
      newsCards_three,
      newsCards_four,
      newsCards_five,
      newsCards_Six
    } = useNewsList();
    const {
      newsCards
    } = useNewsOneShow();
    const newsGather = reactive({
      newsCards_one,
      newsCards_two,
      newsCards_three,
      newsCards_four,
      newsCards_five,
      newsCards_Six,
      newsCards
    });
    return {
      ...toRefs(newsGather)
    };
  }
});
import { createApp, App } from 'vue'
import appView from './App.vue'
// import Antd from 'ant-design-vue/es'
import router from './router'
import '@/styles/less/main.less'
import '@/styles/fonts/iconfont.css'
import setupRequest from '@/plugins/axios'
import countTo from 'vue3-count-to'
const app: App = createApp(appView)
app.use(countTo)
app.use(router)

setupRequest({ app, router })

// app.use(Antd)
app.mount('#app')

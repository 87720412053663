import JSEncrypt from 'jsencrypt-ext'
import { AES, mode, pad, enc } from 'crypto-js'

const sysEncryptor = new JSEncrypt()
sysEncryptor.setPublicKey('edae09eb15d46a70')
// const sysDecryptor = new JSEncrypt()
// sysDecryptor.setKey('edae09eb15d46a70')

export const encrypt = (message) => {
  return sysEncryptor.encrypt(message)
}

export const aesParse = function (data) {
  const keyWordArray = enc.Utf8.parse('edae09eb15d46a70')
  const text = enc.Utf8.parse(JSON.stringify(data))
  const encrypted = AES.encrypt(text, keyWordArray, {
    mode: mode.ECB,
    padding: pad.Pkcs7,
  }).toString()

  return encrypted
}

export const getLocal = (key, store?) => {
  if (key) {
    return localStorage.getItem(key)
  } else {
    throw new Error('key is null undefined')
  }
}

export const setLocal = (key, item) => {
  if (key) {
    localStorage.setItem(key, item)
  } else {
    throw new Error('key is null undefined')
  }
}

import { createGoods } from "@/utils/goods";

const chemicalGoods = createGoods({
    state: {
        stationChemical: {
            name: 'station-chemical',
            el: undefined,
            showTime: false,
            join: false,
          },
          managementChemical: {
            name: 'management-chemical',
            el: undefined,
            showTime: false,
            join: false,
          },
          advantage: {
            name: 'advantage',
            el: undefined,
            showTime: false,
            join: false,
          },
          interfaceChemical: {
            name: 'interfaceChemical',
            el: undefined,
            showTime: false,
            join: false,
          }
    },
    getter: {
        stationChemical(state) {
            return state.stationChemical
        },
        managementChemical(state) {
            return state.managementChemical
        },
        advantage(state) {
            return state.advantage
        },
        interfaceChemical(state) {
            return state.interfaceChemical
        }
    },
    action: {
        stationChemical(commit, value) {
            commit(value)
        },
        managementChemical(commit, value) {
            commit(value)
        },
        advantage(commit, value) {
            commit(value)
        },
        interfaceChemical(commit, value) {
            commit(value)
        }
    }
})

export default chemicalGoods
import freightView from '@/views/product/freight/index.vue'
import ProductView from '@/views/product/index.vue'
import FinanceView from '@/views/product/finance/index.vue'
import FigureEnertgyView from '@/views/product/figureEnertgy/index.vue'

const productRoute = [
  {
    path: '/freight',
    name: 'freight',
    component: freightView,
  },
  {
    path: '/product',
    name: 'product',
    component: ProductView,
  },
  {
    path: '/finance',
    name: 'finance',
    component: FinanceView,
  },
  {
    path: '/figureEnertgy',
    name: 'figureEnertgy',
    component: FigureEnertgyView,
  },
]

export default productRoute

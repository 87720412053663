import { createVNode as _createVNode } from "vue";
import { defineComponent, reactive, toRefs } from 'vue';
import { Table } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    'a-table': Table
  },
  setup() {
    const flowImg1 = require('@/assets/img/product/freight/referenceCenter01.png');
    const flowImg2 = require('@/assets/img/product/freight/referenceCenter02.png');
    const sharedOnCell = function (i) {
      if (i > 1) {
        return (_, index, column) => {
          //   console.log('index,', index, column)
          if (index >= 1) {
            return {
              colSpan: 0,
              rowSpan: 0
            };
          }
        };
      }
      return (_, index, column) => {
        //   console.log('index,', index, column)
        if (index === 1) {
          return {
            rowSpan: 4,
            colSpan: 9
          };
        }
        if (index >= 1) {
          return {
            colSpan: 0,
            rowSpan: 0
          };
        }
      };
    };
    const createTable2 = function () {
      const columnsSecond = [{
        title: '被投诉部门',
        dataIndex: 'dept',
        key: 'dept',
        width: '10%',
        customRender({
          text,
          record,
          index
        }) {
          if (text == '1') {
            return _createVNode("div", {
              "style": {
                opacity: 0
              }
            }, [text]);
          }
          return [text];
        }
      }, {
        title: '投诉时间',
        dataIndex: 'time',
        key: 'time',
        width: '10%',
        customCell: sharedOnCell(2)
      }, {
        title: '投诉客户账号',
        dataIndex: 'account',
        key: 'account',
        width: '10%',
        customCell: sharedOnCell(3)
      }, {
        title: '投诉联系人',
        dataIndex: 'M2',
        key: 'M2',
        width: '10%',
        customCell: sharedOnCell(4)
      }, {
        title: '联系方式',
        width: '10%',
        dataIndex: 'M3',
        key: 'M3',
        customCell: sharedOnCell(5)
      }, {
        title: '投诉类型',
        width: '10%',
        dataIndex: 'M4',
        key: 'M4',
        customCell: sharedOnCell(6)
      }, {
        title: '投诉内容',
        dataIndex: 'M5',
        key: 'M5',
        width: '10%',
        customCell: sharedOnCell(7)
      }, {
        title: '核实结果',
        width: '10%',
        dataIndex: 'M6',
        key: 'M6',
        customCell: sharedOnCell(8)
      }, {
        title: '判定责任部门/人',
        width: '10%',
        dataIndex: 'M7',
        key: 'M7',
        customCell: sharedOnCell(9)
      }, {
        title: '处理预案',
        width: '10%',
        dataIndex: 'M8',
        key: 'M8',
        customCell: sharedOnCell(1)
      }];
      const tableDataSecond = [{
        key: '1',
        dept: '1',
        time: '',
        account: '',
        M2: '',
        M3: '',
        M4: '',
        M5: '',
        M6: '',
        M7: '',
        M8: ''
      }, {
        key: '2',
        dept: '客户满意度',
        time: '',
        account: '',
        M2: '',
        M3: '',
        M4: '',
        M5: '',
        M6: '',
        M7: '',
        M8: ''
      }, {
        key: '3',
        dept: '客户对平台建议',
        time: '',
        account: '',
        M2: '',
        M3: '',
        M4: '',
        M5: '',
        M6: '',
        M7: '',
        M8: ''
      }, {
        key: '4',
        dept: '经办人/责任主管',
        time: '',
        account: '',
        M2: '',
        M3: '',
        M4: '',
        M5: '',
        M6: '',
        M7: '',
        M8: ''
      }, {
        key: '5',
        dept: '完成日期',
        time: '',
        account: '',
        M2: '',
        M3: '',
        M4: '',
        M5: '',
        M6: '',
        M7: '',
        M8: ''
      }];
      return {
        columnsSecond,
        tableDataSecond
      };
    };
    const state = reactive({
      ...createTable2()
    });
    return {
      flowImg1,
      flowImg2,
      ...toRefs(state)
    };
  }
});
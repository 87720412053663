import { defineComponent, reactive, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useNewsCompanyDetail } from './hooks/use-company-news-detail';
import { useNewsPartyDetail } from './hooks/use-party-news-detail';
import { useNewsEnterpriseDetail } from './hooks/use-enterprise-news-detail';
//   import ImgPanel from '@/components/ImgPanel/index.vue'
import { SkeletonImage } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    SkeletonImage
  },
  setup(props, {
    attrs,
    emit,
    slots,
    expose
  }) {
    const route = useRoute();
    const router = useRouter();
    let id = ref(Number(route.query.id));
    const pageType = route.query.pageType;
    const {
      newsCompanyDetailData
    } = useNewsCompanyDetail();
    const {
      newsPartyDetailData
    } = useNewsPartyDetail();
    const {
      newsEnterpriseDetailData
    } = useNewsEnterpriseDetail();
    const listLength = ref(Object.keys(newsCompanyDetailData).length);
    const IS = ref({});
    const upPage = () => {
      if (id.value === 1) {
        return;
      }
      id.value = id.value - 1;
      router.replace({
        path: '/newsDetail',
        query: {
          id: id.value,
          pageType
        }
      });
    };
    const nextPage = () => {
      if (+id.value === +listLength.value) {
        return;
      }
      id.value = id.value + 1;
      router.replace({
        path: '/newsDetail',
        query: {
          id: id.value,
          pageType
        }
      });
    };
    const imgOnload = (res, path, imgIndex) => {
      if (Reflect.has(IS.value, path)) {
        IS.value[path] = 'ok';
      }
    };
    const imageAddCtx = function (s) {
      IS.value[s] = '';
      return s;
    };
    const backList = () => {
      router.back();
    };
    const emus = {
      company: newsCompanyDetailData,
      party: newsPartyDetailData,
      enterprise: newsEnterpriseDetailData
    };
    const state = reactive({
      sectionDataType: 'string',
      getSectionDataType: function (data) {
        if (typeof data === 'string') {
          state.sectionDataType = 'string';
          return 'string';
        } else if (typeof data === 'object') {
          if (data.title) {
            state.sectionDataType = 'title';
            return 'title';
          }
        }
      }
    });
    //   console.log(' emus[pageType]', emus[pageType])
    const getDetailData = () => {
      const detailList = emus[pageType];
      // if (detailList[id.value]) {
      //   detailList[id.value].article.forEach((el) => {
      //     if (el && Array.isArray(el.sectionImg)) {
      //       el.sectionImg.forEach((el) => {
      //         if (typeof el === 'string') {
      //           IS.value[el] = ''
      //         }
      //       })
      //     }
      //   })
      // }
      // detail.article.forEach((element) => {})
      //   console.log('newsDetailData', newsDetailData[id.value])
      return ref(detailList);
    };
    return {
      newsDetailData: getDetailData(),
      IS,
      id,
      upPage,
      nextPage,
      backList,
      listLength,
      imgOnload,
      imageAddCtx,
      ...toRefs(state)
    };
  }
});
import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { getFigureEnertgyList } from '../schema/figureEnertgy';
import ConsultModal from '@/views/home/component/consultModal.vue';
import { Button } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    ConsultModal,
    'a-button': Button
  },
  setup() {
    const windowGlobal = Goods.getters('windowGlobal');
    const stationState = Goods.getters('station');
    const targetState = Goods.getters('target');
    const height = ref(Number(windowGlobal.windowHeight) - 60 + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const {
      stationList,
      targetList
    } = getFigureEnertgyList();
    const state = reactive({
      height,
      bannerHeight,
      boxPadding,
      stationList,
      targetList
    });
    const consultModalRef = ref();
    const useEvent = () => {
      const openConsultModdal = () => {
        consultModalRef.value.open();
      };
      return {
        openConsultModdal
      };
    };
    return {
      stationState,
      targetState,
      ...toRefs(state),
      consultModalRef,
      ...useEvent()
    };
  }
});
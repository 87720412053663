import { defineComponent, ref, reactive, toRefs } from 'vue';
import { Goods } from '@/utils/scrollWatch';
import { getFinanceCardList } from '../schema/finance';
import ConsultModal from '@/views/home/component/consultModal.vue';
import { Button } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    ConsultModal,
    'a-button': Button
  },
  setup() {
    const windowGlobal = Goods.getters('windowGlobal');
    const industryState = Goods.getters('industry');
    const themeState = Goods.getters('theme');
    const safenessState = Goods.getters('safeness');
    const loanState = Goods.getters('loan');
    const height = ref(Number(windowGlobal.windowHeight) - 60 + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const {
      industryList,
      safeness,
      loanList,
      loanAdv
    } = getFinanceCardList();
    const state = reactive({
      height,
      bannerHeight,
      industryList,
      safeness,
      boxPadding,
      loanList,
      loanAdv
    });
    const consultModalRef = ref();
    const useEvent = () => {
      const openConsultModdal = () => {
        consultModalRef.value.open();
      };
      return {
        openConsultModdal
      };
    };
    return {
      industryState,
      themeState,
      safenessState,
      loanState,
      ...toRefs(state),
      consultModalRef,
      ...useEvent()
    };
  }
});
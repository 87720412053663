import { getServiceImagePath } from '@/utils'

// newsImgSrc: require('@/assets/img/press/enterprise20240119/20240119-01.png'),
// new20240123
export function useNewsOneShow() {
  const newsCards = {
    newsImgSrc: require('@/assets/img/press/new20240123/20240123-01.png'),
    newsOneTitle: '货达网络携手陕西交控举办第二届“货车积分推广活动”圆满收官',
    newsTowTitle: '',
    newsContent: `
    1月23日，由陕西交通控股集团有限公司指导、<span class="font-bold">陕西交控运营管理公司主办，西安货达网络科技有限公司参与协办</span>的“货车积分推广”活动圆满落下帷幕。活动以回馈广大司乘为初心，以<span class="font-bold">“货车走高速，积分豪礼送不停”</span>为主题，通过“高速里程赚积分，积分可以当钱花”，为广大货车车主派送出5000余万元权益豪礼，带来实际益处和福利。`,
    newsYearAndMonth: '2024-01',
    newsDays: '23',
    newsId: 1,
  }
  //   const newsCards = {
  //     newsImgSrc: getServiceImagePath('press/new20231127/2023-11-27-1.png'),
  //     newsOneTitle: '全国示范丨货达入选工信部“第五批服务型制造示范”名单',
  //     newsTowTitle: '',
  //     newsContent:
  //       '近日，工业和信息化部公示了第五批服务型制造示范名单。货达旗下“数字供应链平台”（货达数智创新平台）成功上榜服务型制造示范平台名单，是陕西省唯一一个入选第五批示范平台的大宗供应链案例。',
  //     newsYearAndMonth: '2023-11',
  //     newsDays: '27',
  //     newsId: 1,
  //   }

  return { newsCards }
}

import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { getChemicalList } from '../schema/chemical';
export default defineComponent({
  setup(props, ctx) {
    const windowGlobal = Goods.getters('windowGlobal');
    const stationChemicalState = Goods.getters('stationChemical');
    const managementChemicalState = Goods.getters('managementChemical');
    const advantageState = Goods.getters('advantage');
    const interfaceChemicalState = Goods.getters('interfaceChemical');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const {
      chemicalList,
      manageList,
      advantageList
    } = getChemicalList();
    const state = reactive({
      bannerHeight,
      height,
      boxPadding,
      chemicalList,
      manageList,
      advantageList
    });
    return {
      stationChemicalState,
      managementChemicalState,
      interfaceChemicalState,
      advantageState,
      ...toRefs(state)
    };
  }
});
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { Table, Rate, Empty } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    Empty,
    'a-table': Table,
    'a-rate': Rate
  },
  setup() {
    const columns = [{
      title: '投诉人',
      width: 260,
      dataIndex: 'contactPersonName',
      key: 'contactPersonName'
    }, {
      title: '车牌号',
      dataIndex: 'vehicleNo',
      width: 100,
      key: 'vehicleNo'
    }, {
      title: '问题描述',
      width: 280,
      dataIndex: 'exceptionRemark',
      key: 'exceptionRemark'
    }, {
      title: '投诉单号',
      key: 'shipmentCode',
      width: 220,
      dataIndex: 'shipmentCode'
    }, {
      title: '反馈时间',
      dataIndex: 'replyTime',
      key: 'replyTime',
      width: 80
    }, {
      title: '处理状态',
      dataIndex: 'shipmentComplaintStatus',
      key: 'shipmentComplaintStatus',
      width: 120
    }, {
      title: '处理结果说明',
      dataIndex: 'replyRemark',
      key: 'replyRemark',
      width: 300
    }];
    function createTableData() {
      const list = [];
      // for (let index = 0; index < 100; index++) {
      //   const o = {
      //     key: index + '',
      //     name: 'John Brown ' + index,
      //     age: 5 + index,
      //     address: '',
      //     pjtime: '状态' + index,
      //     expect:
      //       index +
      //       '运费支付慢,装卸货地点不真实,克扣运费,卸货等待时间长,装货等待时间长时间长,装货等待时间长====',
      //   }
      //   list.push(o)
      // }
      return list;
    }
    const data = createTableData();
    const state = reactive({
      columns,
      data
    });
    onMounted(() => {
      // checkShipmentComplaint().then((res) => {
      //   //   console.log('checkShipmentComplaint', res)
      //   state.data = res
      // })
    });
    return {
      ...toRefs(state)
    };
  }
});
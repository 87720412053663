import "core-js/modules/es.array.push.js";
import { Divider, Button } from 'ant-design-vue/es';
import { defineComponent, computed, ref, toRefs } from 'vue';
import { Goods } from '@/utils/scrollWatch';
import { useRouter } from 'vue-router';
import { reactive } from 'vue';
import { mediaSchema } from './schema/footer';
export default defineComponent({
  components: {
    Divider,
    'a-button': Button
  },
  props: {
    from: {
      type: String,
      default: 'footer'
    }
  },
  setup(props, ctx) {
    //   console.log(Goods.footer())
    //   const footer = Goods.getters('footer')
    const currentMedialName = ref('wx');
    const windowGlobal = Goods.getters('windowGlobal');
    const boxPadding = ref(windowGlobal.boxPadding);
    const router = useRouter();
    const goLink = (path, menuTab) => {
      router.push(path);
      if (menuTab) {
        Goods.dispatch('menuActive', {
          name: menuTab,
          active: true
        });
      }
    };
    const footer = computed(() => {
      if (props.from === 'footer') {
        return Goods.getters('footer').value;
      } else {
        return {
          showTime: true
        };
      }
    });
    const useMedial = reactive({
      mediaSchema,
      mediaBlurHandle(type) {
        //   console.log('type,', type)
        if (type == 'wx') {
          currentMedialName.value = 'wx';
        } else if (type === 'dy') {
          currentMedialName.value = 'dy';
        } else if (type === 'wxsp') {
          currentMedialName.value = 'wxsp';
        } else if (type === 'ks') {
          currentMedialName.value = 'ks';
        }
      }
    });
    return {
      boxPadding,
      footer,
      goLink,
      ...toRefs(useMedial),
      currentMedialName
    };
  }
});
import { Goods } from "../scrollWatch"
const powerScroll = (scroll) => {
    scroll('electricity-plight', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('electricityPlight', { showTime: showTime })
        const team = Goods.getters('electricityPlight')
    })
    scroll('intelligence-system', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('intelligenceSystem', { showTime: showTime })
        const team = Goods.getters('intelligenceSystem')
    })
    scroll('power-detailed', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('powerDetailed', { showTime: showTime })
        const team = Goods.getters('powerDetailed')
    })
    scroll('software-interface', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('softwareInterface', { showTime: showTime })
        const team = Goods.getters('softwareInterface')
    })
    scroll('hardware-equipment', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('hardwareEquipment', { showTime: showTime })
        const team = Goods.getters('hardwareEquipment')
    })
    scroll('core-advantage', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('coreAdvantage', { showTime: showTime })
        const team = Goods.getters('coreAdvantage')
    })
}

export default powerScroll
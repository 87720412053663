import { createGoods } from "@/utils/goods";

const coalGoods = createGoods({
    state: {
        idffContent: {
            name: 'idff-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        transportSaleSystem: {
            name: 'transport-sale-system',
            el: undefined,
            showTime: false,
            join: false,
        },
        softView: {
            name: 'soft-view',
            el: undefined,
            showTime: false,
            join: false,
        },
        deviceContent: {
            name: 'device-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        centerContent: {
            name: 'center-content',
            el: undefined,
            showTime: false,
            join: false,
        },
    },
    getter: {
        idffContent(state) {
            return state.idffContent
        },
        transportSaleSystem(state) {
            return state.transportSaleSystem
        },
        softView(state) {
            return state.softView
        },
        deviceContent(state) {
            return state.deviceContent
        },
        centerContent(state) {
            return state.centerContent
        },

    },
    action: {
        idffContent(commit, value) {
            commit(value)
        },
        transportSaleSystem(commit, value) {
            commit(value)
        },
        softView(commit, value) {
            commit(value)
        },
        deviceContent(commit, value) {
            commit(value)
        },
        centerContent(commit, value) {
            commit(value)
        },
    }
})

export default coalGoods
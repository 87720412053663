const getProductInfoList = () => {
  const list = [
    {
      active: true,
      title: '协同管理',
      name: '业务、财务单据票据电子化，全程、全域、全端的协同办公能力，成就企业高效组织',
      img: require('@/assets/img/product/freight/freight01.png'),
    },
    {
      active: false,
      title: '监控定位',
      name: '实时查看车辆位置，播放行驶轨迹路线，运输全程可视化管理，时效、安全尽在掌握',
      img: require('@/assets/img/product/freight/freight02.png'),
    },
    {
      active: false,
      title: '智能识别',
      name: '结合智能AI技术，运输票据拍照上传，系统自动识别，高效易用，识别率高，方便企业审核与结算',
      img: require('@/assets/img/product/freight/freight03.png'),
    },
    {
      active: false,
      title: '数据报表',
      name: '多维度数据分析报表，无论是办公还是出差，可随时随地，查看业务相关数据，帮助企业管理决策',
      img: require('@/assets/img/product/freight/freight04.png'),
    },
  ]

  return list
}

const getSceneCardList = () => {
  const list = [
    {
      title: '业务合规',
      content: '五流合一，业务真实有迹可循，正规的运输增值税发票，降低企业税务风险',
      img: require('@/assets/img/product/freight/业务合规.png'),
    },
    {
      title: '灵活便捷',
      content: '操作简单，三步走全部完成；及时结算，按需办理且流程顺畅',
      img: require('@/assets/img/product/freight/灵活便捷.png'),
    },
    {
      title: '资金安全',
      content: '专用资金账户，安全有保障；智能运营风控，紧贴监管要求',
      img: require('@/assets/img/product/freight/资金安全.png'),
    },
    {
      title: '服务支撑',
      content: '丰富的资源匹配 ，强大的运营系统，专业的技术团队',
      img: require('@/assets/img/product/freight/服务支撑.png'),
    },
  ]
  return list
}

export { getProductInfoList, getSceneCardList }

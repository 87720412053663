import { createGoods } from "@/utils/goods";




const pressGoods = createGoods({
    state: {
        sessionPress: {
            name: 'session-press',
            el: undefined,
            showTime: false,
            join: false,
        },
        pressGrid: {
            name: 'press-session-grid',
            el: undefined,
            showTime: false,
            join: true,
        },
        arcicalDetail: {
            name: 'arcical-detail',
            el: undefined,
            showTime: false,
            join: false,
        }
    },
    getter: {
        sessionPress(state) {
            return state.sessionPress
        },
        pressGrid(state) {
            return state.pressGrid
        },
        arcicalDetail(state) {
            return state.arcicalDetail
        }
    },
    action: {
        sessionPress(commit, value) {
            commit(value)
        },
        pressGrid(commit, value) {
            commit(value)
        },
        arcicalDetail(commit, value) {
            commit(value)
        }
    }
})

export default pressGoods
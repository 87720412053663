import { getServiceImagePath } from '@/utils'

export function useNewsList() {
  const newsCards_one = [
    {
      newsImgSrc: getServiceImagePath('press/party20230629/2023-06-29-1.jpeg'),
      newsTitle: '“迎七一，庆祝建党102周年”——货达党支部“凝聚红色力量、弘扬革命精神”主题党日活动',
      newsYearAndMonth: '2023-06',
      newsDays: '29',
      newsId: 1,
    },
    /**====================》》》》》》二次添加 start */
    {
      newsImgSrc: getServiceImagePath('press/newsList/party/2023-04-28.png'),
      newsTitle:
        '组织党员观看学习中共陕西省委组织部远程教育片——《七大 七大》、《二十大风采录》教育视频',
      newsYearAndMonth: '2023-04',
      newsDays: '28',
      newsId: 2,
    },
    {
      newsImgSrc: getServiceImagePath('press/part20230417/4-17-1.png'),
      newsTitle:
        '以篮球之名，展铿锵之采--货达党支部组织参加“闪耀未来 幸福高新”首届“高新杯”篮球联赛',
      newsYearAndMonth: '2023-04',
      newsDays: '17',
      newsId: 3,
    },
    /**====================》》》》》》二次添加 end */

    {
      newsImgSrc: getServiceImagePath('press/newsList/party/news1.png'),
      newsTitle: '货达网络倾力打造“司机之家”，顺利通过陕西省总工会、交通厅和陕物联联合验收！',
      newsYearAndMonth: '2022-12',
      newsDays: '21',
      newsId: 4,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/party/news2.png'),
      newsTitle: '货达网络公司组织党员观看“二十大”开幕会！',
      newsYearAndMonth: '2022-10',
      newsDays: '18',
      newsId: 5,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/party/news3.png'),
      newsTitle: '货达党支部开展员工慰问活动',
      newsYearAndMonth: '2022-1',
      newsDays: '8',
      newsId: 6,
    },
  ]

  const newsCards_two = []
  return { newsCards_one, newsCards_two }
}

import _ from 'lodash'
import * as operator from './operator'

const SERVICE_IMAGE_PATH = 'https://huoda-tms-public.oss-cn-beijing.aliyuncs.com/hdd-video/press/'
function getServiceImagePath(path) {
  return `${SERVICE_IMAGE_PATH}${path}`
}

const Logger = (() => {
  const red = 'color:#f5222d;'
  const green = 'color:#52c41a;'
  const orange = 'color:#faad14;'
  const blue = 'color:#faad14;'
  return {
    gray: console.log.bind(
      this,
      `%c%s%c%s`,
      'color:#909399;font-size:16px;font-weight:bold;',
      'Logger ==>> ',
      'color:#409EFF;font-size:14px;margin-right:5px;',
    ),
    red: console.log.bind(
      this,
      `%c%s%c%s%o`,
      `${red} font-size:16px;font-weight:bold;`,
      'Logger ==>> ',
      'color:#409EFF;font-size:14px;margin-right:5px;',
    ),
    green: console.log.bind(
      this,
      `%c%s%c%s%o`,
      `${green} font-size:16px;font-weight:bold;`,
      'Logger ==>> ',
      'color:#409EFF;font-size:14px;margin-right:5px;',
    ),
    orange: console.log.bind(
      this,
      `%c%s%c%s%o`,
      `${orange} font-size:16px;font-weight:bold;`,
      'Logger ==>> ',
      'color:#409EFF;font-size:14px;margin-right:5px;',
    ),
    blue: console.log.bind(
      this,
      `%c%s%c%s%o`,
      `${blue} font-size:16px;font-weight:bold;`,
      'Logger ==>> ',
      'color:#409EFF;font-size:14px;margin-right:5px;',
    ),
  }
})()
const deepClone = (target: any) => {
  return _.cloneDeep(target)
}

const getDataType: Function = (obj: any): string => {
  // const obj = Array.prototype.shift.apply(arguments);
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
    '[object Error]': 'error',
    '[object Blob]': 'blob',
    '[object WeakMap]': 'weakMap',
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}

export { operator, deepClone, Logger, getDataType, getServiceImagePath }

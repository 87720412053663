const getCoalCardList = function () {
  const list = [
    {
      title: '业务流程关联性低',
      content: '系统与合同、收款没有关联， 无法按照合同计划实现精准调拨',
      img: require('@/assets/img/product/coal-card01.png'),
    },
    {
      title: '运输进场秩序混乱',
      content: '车辆排队全靠人工指挥干预， 常见插队、乱停乱放等现场乱象',
      img: require('@/assets/img/product/coal-card02.png'),
    },
    {
      title: '人工环节管理困难',
      content: '伪造称重数据、结算计量等环节人员冗杂、人工成本高且易出错',
      img: require('@/assets/img/product/coal-card03.png'),
    },
    {
      title: '数据流转流通滞后',
      content: '煤炭销售、库存、财务结算等数据缺少整合共享，一致性低',
      img: require('@/assets/img/product/coal-card04.png'),
    },
  ]

  return list
}

const getCenterAdvanceList = function () {
  const list = [
    {
      title: '合同管理电子化',
      content: '合同计划关联，执行情况自动跟踪；星级 信用评价制度，可针对客户进行精细管理',
      img: require('@/assets/img/product/center-01.png'),
    },
    {
      title: '调拨计划精准化',
      content: '依据合同自动生成计划， 客户/承运商根据计划派车，杜绝超发现象',
      img: require('@/assets/img/product/center-02.png'),
    },
    {
      title: '现场管理智能化',
      content: '车辆进入区域自动排队叫号，自动识别 验票有序入厂',
      img: require('@/assets/img/product/center-03.png'),
    },
    {
      title: '磅房称重无人化',
      content: '上磅前自动识别车辆信息，红外对射防作弊， 称重数据自动采集传输',
      img: require('@/assets/img/product/center-04.png'),
    },
    {
      title: '远程装车自动化',
      content: '通过在煤仓下装车点安装自动化设备， 实现智能化远程装车作业',
      img: require('@/assets/img/product/center-05.png'),
    },
    {
      title: '财务结算线上化',
      content: '财务收款、退款审核管理，一键生成煤款 结算单，货款、运费结算简洁明了',
      img: require('@/assets/img/product/center-06.png'),
    },
  ]

  return list
}

export { getCoalCardList, getCenterAdvanceList }

import { defineComponent, h, createVNode, ref, nextTick, } from 'vue'
import { Card } from 'ant-design-vue/es';
import Blank from '@/components/blank/index.vue'



export function createNameComponent(component: Function, opName?) {
    return () => {
        return new Promise((res) => {
            component().then((comm: any) => {
                // const name: string = (comm.default.name || 'box') + '$' + Date.now()
                const name: string = comm.default.name || opName
                // appStore.setCacheTab(name)
                // active.push(name)
                // console.log('createNode.tsx name', active);
                const tempComm = defineComponent({
                    name,
                    setup() {
                        const isReload = ref(false)
                        let timeOut: any = null
                        const handleReload = () => {
                            isReload.value = true
                            timeOut && clearTimeout(timeOut)
                            //   NProgress.start();
                            timeOut = setTimeout(() => {
                                nextTick(() => {
                                    //   NProgress.done();
                                    isReload.value = false
                                })
                            }, 260)
                        }


                        return {
                            top,
                            isReload,
                            handleReload,
                        }
                    },
                    render: function () {
                        // console.log('isReload====>h', tempComm, comm.default)
                        if (this.isReload) {
                            // return h('div', { class: 'main-container' }, [h(reload)])
                        } else {
                            // console.log('Card', Card);
                            return h(Blank, { class: 'card-container' }, () => {
                                return createVNode(comm.default)
                            })
                        }
                    },
                })
                res(tempComm)
            })
        })
    }
}

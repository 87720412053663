import { createGoods } from "@/utils/goods";

const powerGoods = createGoods({
    state: {
        electricityPlight: {
            name: 'electricity-plight',
            el: undefined,
            showTime: false,
            join: false,
        },
        intelligenceSystem: {
            name: 'intelligence-system',
            el: undefined,
            showTime: false,
            join: false,
        },
        powerDetailed: {
            name: 'power-detailed',
            el: undefined,
            showTime: false,
            join: false,
        },
        softwareInterface: {
            name: 'software-interface',
            el: undefined,
            showTime: false,
            join: false,
        },
        hardwareEquipment: {
            name: 'hardware-equipment',
            el: undefined,
            showTime: false,
            join: false,
        },
        coreAdvantage: {
            name: 'core-advantage',
            el: undefined,
            showTime: false,
            join: false,
        },
    },
    getter: {
        electricityPlight(state) {
            return state.electricityPlight
        },
        intelligenceSystem(state) {
            return state.intelligenceSystem
        },
        powerDetailed(state) {
            return state.powerDetailed
        },
        softwareInterface(state) {
            return state.softwareInterface
        },
        hardwareEquipment(state) {
            return state.hardwareEquipment
        },
        coreAdvantage(state) {
            return state.coreAdvantage
        },

    },
    action: {
        electricityPlight(commit, value) {
            commit(value)
        },
        intelligenceSystem(commit, value) {
            commit(value)
        },
        powerDetailed(commit, value) {
            commit(value)
        },
        softwareInterface(commit, value) {
            commit(value)
        },
        hardwareEquipment(commit, value) {
            commit(value)
        },
        coreAdvantage(commit, value) {
            commit(value)
        },
    }
})

export default powerGoods
import { getServiceImagePath } from '@/utils'
// require('@/assets/img/press/enterprise20240119/20240119-01.png'),
export function useNewsList() {
  const newsCards_one = [
    {
      newsImgSrc: require('@/assets/img/press/new20240123/20240123-01.png'),
      newsTitle: '货达网络携手陕西交控举办第二届“货车积分推广活动”圆满收官',
      newsYearAndMonth: '2024-01',
      newsDays: '23',
      newsId: 1,
    },

    {
      newsImgSrc: getServiceImagePath('press/new20231127/2023-11-27-1.png'),
      newsTitle: '全国示范丨货达入选工信部“第五批服务型制造示范”名单',
      newsYearAndMonth: '2023-11',
      newsDays: '27',
      newsId: 2,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20231121/2023-11-21-1.png'),
      newsTitle: '喜讯丨货达网络荣获2023年陕西省“专精特新”企业荣誉',
      newsYearAndMonth: '2023-11',
      newsDays: '21',
      newsId: 3,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20231103/20231103-1.png'),
      newsTitle: '发展动态丨货达集团参与制定《煤炭数字化仓库基本要求》团体标准顺利通过审查',
      newsYearAndMonth: '2023-11',
      newsDays: '03',
      newsId: 4,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20231013/20231013-1.png'),
      newsTitle: '货达网络CEO折大伟受邀出席榆林市青年民营企业家代表座谈会并作发言',
      newsYearAndMonth: '2023-10',
      newsDays: '13',
      newsId: 5,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230928/2023-09-28-1.png'),
      newsTitle: '数实融合 链创未来丨货达网络受邀参加2023第四届中国供应链管理年会',
      newsYearAndMonth: '2023-09',
      newsDays: '28',
      newsId: 6,
    },
  ]

  const newsCards_two = [
    {
      newsImgSrc: getServiceImagePath('press/new20230914/2023-09-14-1.png'),
      newsTitle: '货达网络荣登2023年《财富》中国最具社会影响力的创业公司榜单',
      newsYearAndMonth: '2023-09',
      newsDays: '14',
      newsId: 7,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230911/2023-09-11-1.png'),
      newsTitle: '货达网络创始人、CEO折大伟荣登2023科创板日报“未来数智领袖榜”',
      newsYearAndMonth: '2023-09',
      newsDays: '11',
      newsId: 8,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230906/2023-9-6-1.png'),
      newsTitle: '货达网络与芜湖江北新区招商引资项目签约仪式成功举行',
      newsYearAndMonth: '2023-09',
      newsDays: '06',
      newsId: 9,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230904/2023-9-4-1.png'),
      newsTitle: '喜讯！货达网络旗下子公司通过5A级网络货运平台企业现场评估',
      newsYearAndMonth: '2023-09',
      newsDays: '04',
      newsId: 10,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230816/0816-1.png'),
      newsTitle: '精益管理丨货达网络携手金蝶，共同推进业财融合的企业数字化建设',
      newsYearAndMonth: '2023-08',
      newsDays: '16',
      newsId: 11,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230814/0814-1.png'),
      newsTitle: '2023海纳汇软件产业联盟全国巡展西安站圆满召开，货达网络精彩亮相',
      newsYearAndMonth: '2023-08',
      newsDays: '14',
      newsId: 12,
    },
  ]

  const newsCards_three = [
    {
      newsImgSrc: getServiceImagePath('press/new20230811/0811-1.png'),
      newsTitle: '凝心聚力，砥砺前行，货达2023项目中心年中会议召开',
      newsYearAndMonth: '2023-08',
      newsDays: '11',
      newsId: 13,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230724/2023-07-24/2023-07-24-1.jpeg'),
      newsTitle: '喜讯！货达网络与托克托县政府签署合作协议，共同推动县域智慧物流高质量发展',
      newsYearAndMonth: '2023-07',
      newsDays: '24',
      newsId: 14,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230719/2023-07-19-1.png'),
      newsTitle: '货达网络亮相第18届中国新疆国际煤炭工业博览会，以数智科技赋能大宗商品物流',
      newsYearAndMonth: '2023-07',
      newsDays: '19',
      newsId: 15,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230616/06-16-1.jpeg'),
      newsTitle:
        '第四届西部数博会盛大召开！货达网络接待吉林省、内蒙古自治区、高新区工信局等多位领导莅临调研',
      newsYearAndMonth: '2023-06',
      newsDays: '16',
      newsId: 16,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230529/2023-05-29-1.jpeg'),
      newsTitle: '呼包鄂榆乌物流产业联盟正式启动：货达网络CEO折大伟应邀出席并代表榆物联发言',
      newsYearAndMonth: '2023-05',
      newsDays: '29',
      newsId: 17,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230508/2023-05-08-1.jpeg'),
      newsTitle: '鄂尔多斯煤博会“C位首秀”丨货达网络以数智赋能，引领煤炭数字化转型',
      newsYearAndMonth: '2023-05',
      newsDays: '08',
      newsId: 18,
    },
  ]

  const newsCards_four = [
    {
      newsImgSrc: getServiceImagePath('press/new20230422/04-22-1.png'),
      newsTitle:
        '货达网络接待格尔木市委副书记、副市长严仕吉，发改委、工信局、陆港办等多位领导莅临调研',
      newsYearAndMonth: '2023-04',
      newsDays: '22',
      newsId: 19,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230410/04-10-1.png'),
      newsTitle: '以“煤”会友丨货达网络携多款数字化产品与解决方案亮相第十七届煤博会',
      newsYearAndMonth: '2023-04',
      newsDays: '10',
      newsId: 20,
    },
    {
      newsImgSrc: getServiceImagePath('press/new20230301/03-01-1.png'),
      newsTitle: '西安货达网络数字化成果交流座谈会成功举办！',
      newsYearAndMonth: '2023-03',
      newsDays: '01',
      newsId: 21,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news1.png'),
      newsTitle: '陕西省交通运输厅副厅长戴滨华一行莅临我司调研指导工作',
      newsYearAndMonth: '2022-5',
      newsDays: '27',
      newsId: 22,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news2.png'),
      newsTitle: '货达网络入选我省“上云”平台服务商 助力企业数字化转型',
      newsYearAndMonth: '2022-5',
      newsDays: '11',
      newsId: 23,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news3.png'),
      newsTitle: '货达CEO折大伟：“双碳”背景下大宗商品供应链该如何发展',
      newsYearAndMonth: '2022-3',
      newsDays: '10',
      newsId: 24,
    },
  ]
  const newsCards_five = [
    {
      newsImgSrc: getServiceImagePath('press/newsList/news4.jpeg'),
      newsTitle: '践行劳模精神，数字化赋能陕西物流行业高质量发展',
      newsYearAndMonth: '2022-4',
      newsDays: '29',
      newsId: 25,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news5.png'),
      newsTitle: '助力疫情防控，行动书写担当—货达【战疫】出发',
      newsYearAndMonth: '2022-1',
      newsDays: '5',
      newsId: 26,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news6.png'),
      newsTitle: '北奔重汽党委书记、董事长范志平带队考察西安货达网络科技有限公司',
      newsYearAndMonth: '2021-4',
      newsDays: '30',
      newsId: 27,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news7.png'),
      newsTitle:
        '货达网络受邀参加第二届交通智慧物流高端论坛暨2020（第十二届）中国物流与供应链信息化大会',
      newsYearAndMonth: '2020-11',
      newsDays: '7',
      newsId: 28,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news8.png'),
      newsTitle: '交通运输部副部长刘小明调研货达网络',
      newsYearAndMonth: '2020-9',
      newsDays: '22',
      newsId: 29,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news9.png'),
      newsTitle: '货达网络通过5A级网络货运平台物流企业评估',
      newsYearAndMonth: '2020-08',
      newsDays: '18',
      newsId: 30,
    },
  ]

  const newsCards_Six = [
    {
      newsImgSrc: getServiceImagePath('press/newsList/news10.png'),
      newsTitle: '货达网络战略合作中国煤炭市场网，布局大宗商品数字供应链新未来',
      newsYearAndMonth: '2019-12',
      newsDays: '05',
      newsId: 31,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news11.png'),
      newsTitle: '2019年“数字供应链中国行——西安站”成功召开',
      newsYearAndMonth: '2019-06',
      newsDays: '24',
      newsId: 32,
    },
    {
      newsImgSrc: getServiceImagePath('press/newsList/news12.png'),
      newsTitle: '陕西省物流与采购联合会网络货运专业委员会成立！',
      newsYearAndMonth: '2019-05',
      newsDays: '9',
      newsId: 33,
    },
  ]
  return {
    newsCards_one,
    newsCards_two,
    newsCards_three,
    newsCards_four,
    newsCards_five,
    newsCards_Six,
  }
}

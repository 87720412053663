import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fc2b15ce"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "boxRef"
};
const _hoisted_2 = {
  class: "custom-slick-arrow",
  style: {
    "left": "10px",
    "z-index": "999"
  }
};
const _hoisted_3 = {
  class: "custom-slick-arrow",
  style: {
    "right": "10px",
    "z-index": "999"
  }
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "dis-content"
};
const _hoisted_6 = {
  class: "dis-space-2 dis-title"
};
const _hoisted_7 = {
  class: "dis-space-1 me-content"
};
const _hoisted_8 = {
  class: "dis-space-1 me-content-lt"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_left_circle_outlined = _resolveComponent("left-circle-outlined");
  const _component_right_circle_outlined = _resolveComponent("right-circle-outlined");
  const _component_a_carousel = _resolveComponent("a-carousel");
  const _component_a_modal = _resolveComponent("a-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.visible = $event),
    wrapClassName: "warp-container",
    width: "65%",
    destroyOnClose: "",
    footer: null,
    getContainer: () => _ctx.boxRef
  }, {
    default: _withCtx(() => [_createVNode(_component_a_carousel, {
      arrows: "",
      class: "carousel-content",
      autoplay: "",
      dotsClass: "dots-panel"
    }, {
      prevArrow: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_left_circle_outlined, {
        style: {
          "{\n                  color": "'red'"
        }
      })])]),
      nextArrow: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_right_circle_outlined)])]),
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailsData.images, (item, index) => {
        return _openBlock(), _createElementBlock("img", {
          key: index,
          src: item,
          style: {
            width: '100%',
            height: '500px'
          }
        }, null, 8, _hoisted_4);
      }), 128))]),
      _: 1
    }), _createElementVNode("div", _hoisted_5, [_createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.detailsData.title), 1), _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.detailsData.content), 1), _createElementVNode("section", _hoisted_8, _toDisplayString(_ctx.detailsData.detail), 1)])]),
    _: 1
  }, 8, ["visible", "getContainer"])], 512);
}
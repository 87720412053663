import { getServiceImagePath } from '@/utils'

export function useNewsPartyDetail() {
  const newsPartyDetailData = {
    1: {
      title: '“迎七一，庆祝建党102周年”——货达党支部“凝聚红色力量、弘扬革命精神”主题党日活动',
      date: '2023/06/29',
      article: [
        {
          section: [
            '2023年6月29日，为迎接中国共产党成立102周年，西安货达党支部在公司党建会议室开展“迎七一，庆祝建党102周年”、“凝聚红色力量、弘扬革命精神”主题党日活动。全体党员重温入党誓词，学习《习近平新时代中国特色社会主义思想》，“迎七一，送温暖”—肠癌检测试剂盒赠送党员，并于7月1日赴照金红色教育基地—陕甘边革命根据地照金纪念馆开展实践研学。',
            {
              style: 'margin-top:10px;',
              title: '1.重温誓词，初心不忘',
            },
          ],
          sectionImg: [getServiceImagePath('press/party20230629/2023-06-29-1.jpeg')],
        },
        {
          section: [
            '党支部党员重新宣誓入党誓词，牢记初心使命，矢志不渝奋斗。用入党誓词约束自己，把入党誓词各项内容落实到行动上、落实到各项事业中。党员认真学习《习近平新时代中国特色社会主义思想三十讲》，习近平新时代中国特色社会主义思想指引下，以开展主题教育为契机，学思想、强党性、重实践、建新功，让初心薪火相传，把使命永担在肩，为党的事业增添新的荣光。',
          ],
          sectionImg: [getServiceImagePath('press/party20230629/2023-06-29-2.png')],
        },
        {
          section: [
            '为贯彻落实党的二十大精神，积极响应“共建共享、全民健康”的战略号召，高新区企业党委联合华大基因党支部举办“迎七一，送温暖”为党员们谋福利，进行了肠癌检测试剂盒捐赠活动，货达党员们收到了肠癌检测试剂，感受党组织关怀及对爱心企业的捐赠表达感激之情。',
          ],
          sectionImg: [getServiceImagePath('press/party20230629/2023-06-29-3.jpeg')],
        },
        {
          section: [
            {
              title: '2.红色照金，砥砺前行',
            },
          ],
          sectionImg: [getServiceImagePath('press/party20230629/2023-06-29-4.jpeg')],
        },
        {
          section: [
            '7月1日，货达网络党员们前往照金红色教育基地—陕甘边革命根据地照金纪念馆及陈家坡会议遗址开展实践研学。照金纪念馆用详实的历史资料、图片、文物和各种现代的展陈技术，再现了创建以照金为中心的陕甘边革命根据地的艰难历程，党员们通过参观，缅怀先烈，弘扬革命传统。',
          ],
          sectionImg: [
            getServiceImagePath('press/party20230629/2023-06-29-5.jpeg'),
            getServiceImagePath('press/party20230629/2023-06-29-6.jpeg'),
          ],
        },
        {
          section: [
            '“党建引领，激发活力”以照金为中心的陕甘边革命根据地，在中国革命史上写下了光辉的一页，在研学基地通过多媒体的影像资料，学习百年党史和革命英雄事迹，加强对革命根据地历史的研究，总结历史经验，发扬革命精神和优良作风，传承红色基因，弘扬革命精神。',
          ],
          sectionImg: [getServiceImagePath('press/party20230629/2023-06-29-7.jpeg')],
        },
        {
          section: [
            {
              title: '3.场外素拓，拼搏进取',
            },
          ],
          sectionImg: [getServiceImagePath('press/party20230629/2023-06-29-8.jpeg')],
        },
        {
          section: [
            '党员们进行了“真人cs军事对抗”。通过沉浸式的剧情模式开展游击战、阵地战、运动战等多种军事对抗决出胜负。让党员同志在模拟军队作战训练体验中追忆峥嵘岁月，在真实对抗情境中激发出勇气和信念，磨练战胜困难的毅力，凝聚团队合作力量和坚毅的品质。',
          ],
          sectionImg: [
            getServiceImagePath('press/party20230629/2023-06-29-9.jpeg'),
            getServiceImagePath('press/party20230629/2023-06-29-10.jpeg'),
          ],
        },
        {
          section: [
            '通过此次活动，货达党员懂得了烽火硝烟，初心如磐。革命先辈以不怕牺牲、顽强拼搏的英雄气概，独立自主、开拓进取的创新勇气，开展了艰苦卓绝的革命斗争实践，孕育形成了伟大的照金精神。接下来，货达党支部也将不断增强党员同志们的凝聚力、战斗力和号召力，更进一步激励大家踔厉奋发、笃行不怠，持续坚定跟党走的决心和信心！',
          ],
        },
      ],
    },
    2: {
      title:
        '组织党员观看学习中共陕西省委组织部远程教育片 ——《七大 七大》、《二十大风采录》教育视频',
      date: '2023/04/28',
      article: [
        {
          section: [
            '2023年以来，我省以迎接党的二十大胜利召开和学习宣传贯彻党的二十大精神为主线，切实发挥党员干部现代远程教育在宣传、服务、助推党的建设和组织工作中的积极作用，不断为党员教育工作赋能增效。省委组织部坚持远程教育“固定学习日”制度，西安货达党支部高度重视。',
          ],
          sectionImg: [getServiceImagePath('press/part20230428/28-1.png')],
        },
        {
          section: [
            '2023年4月28日下午15时，货达党支部组织全体党员汇聚于党建会议室，认真聆听《七大 七大》延安精神系列微党课，观看《二十大代表风采录》。',
          ],
          sectionImg: [[getServiceImagePath('press/part20230428/28-2.png')]],
        },
        {
          section: [
            '中共七大是中国共产党在新民主主义革命时期极其重要的一次代表大会，它总结中国新民主主义革命20多年曲折发展的历史经验，制定了正确的路线、纲领和策略，克服了党内的错误思想，使全党特别是党的高级干部对于中国民主革命的发展规律有了比较明确的认识，从而使全党在马克思列宁主义、毛泽东思想的基础上达到了空前的团结。这次大会作为“团结的大会，胜利的大会”而载入史册。它为党领导人民去争取抗日战争的胜利和新民主主义革命在全国的胜利，奠定了政治上、思想上、组织上的基础。',
          ],
          sectionImg: [[getServiceImagePath('press/part20230428/28-3.png')]],
        },
        {
          section: [
            '在以习近平同志为核心的党中央坚强领导下，按照党章规定和党中央统一部署，全国38个选举单位选举产生了2296名党的二十大代表。党的二十大代表是共产党员中的优秀分子，具有先进性和广泛代表性，是经各级党组织逐级遴选产生，肩负着9600多万名党员、490多万个基层党组织的重托，凝聚着全党的意志，承载着人民的期望，将光荣地出席党的第二十次全国代表大会。他们中很多是来自生产和工作第一线、在各行各业作出突出贡献的先进模范党员。',
          ],
        },
      ],
    },
    3: {
      title: '以篮球之名，展铿锵之采--货达党支部组织参加“闪耀未来 幸福高新”首届“高新杯”篮球联赛',
      date: '2023/04/17',
      article: [
        {
          section: [
            '为深入贯彻党的二十大精神，加快推进体育强国建设，4月16日，由高新区教育体育局主办的2023年首届“高新杯”三人篮球联赛小组赛于当日进行。此次比赛，秉持促进“全民健身”深入发展、彰显运动风采、提升全民幸福感的理念，货达党支部积极响应，号召人员参与比赛，此次比赛货达战队的战友们发挥了不怕困难、团结拼搏、永不言弃的体育精神。',
          ],
          sectionImg: [
            getServiceImagePath('press/part20230417/4-17-1.png'),
            getServiceImagePath('press/part20230417/4-17-2.png'),
          ],
        },
        {
          section: ['女子小组对抗赛中货达与高新区工信局、钱学森学校等一众交手，精彩接连不断'],
          sectionImg: [
            getServiceImagePath('press/part20230417/4-17-3.png'),
            getServiceImagePath('press/part20230417/4-17-4.png'),
          ],
        },
        {
          section: ['男子小组对抗赛中货达与三星、联通、高科集团等强队在赛场激烈交锋，场下友好交流'],
          sectionImg: [
            getServiceImagePath('press/part20230417/4-17-5.png'),
            getServiceImagePath('press/part20230417/4-17-6.png'),
          ],
        },
        {
          section: [
            '高新区举办的首届篮球联赛在大家的积极参与下，不仅为现场观众带来一场视觉盛宴，更为未来共同开展政企联建奠定了深厚友谊基础，增强了团队的凝聚力，也让大家更好地融入到了高新区的文化氛围中。期待后续赛事，同时预祝2023年首届“高新杯”三人篮球赛圆满成功！',
          ],
        },
      ],
    },
    4: {
      title: '货达网络倾力打造“司机之家”，顺利通过陕西省总工会、交通厅和陕物联联合验收！',
      date: '2022/12/21 12:34:56',
      article: [
        {
          section: [
            '喜讯！货达网络主建西安北三环六村堡加油站“司机之家”获选入陕西省总工会、省交通运输厅和省物流与采购联合会共同公布 —— 2022年度陕西省“司机之家”名单。',
          ],
          sectionImg: [getServiceImagePath('press/partyNewsDetail1/img1.png')],
        },
        {
          section: [
            '西安北三环六村堡加油站位于西安市未央区北三环西段丰源石材城东侧，占地面积约1200㎡。在此落地的“司机之家”，整体严格按照省总工会、省交通厅和省物联下发的《关于在全省开展创建“司机之家”活动的通知》文件中相关“陕西省‘司机之家’建设服务标准”要求进行建设，并于年底顺利通过完成验收工作，目前已进入常态化运营阶段。',
          ],
          sectionImg: [
            getServiceImagePath('press/partyNewsDetail1/img2.png'),
            getServiceImagePath('press/partyNewsDetail1/img3.png'),
          ],
        },
        {
          section: [
            '该站点“司机之家”主要服务于来往的货车司乘人员及货达网络平台的百万注册司机群体，日均过路车流量可达600辆次左右。站内提供包括24小时免费休息、淋浴、洗衣干衣、饮水就餐等温馨、便捷的多功能服务，增设了吧台、微波炉、洗烘一体机、图书角等软硬件设施，切实改善了广大货车司机休息环境和条件，增强了货车司机们的从业获得感。',
          ],
          sectionImg: [getServiceImagePath('press/partyNewsDetail1/img4.png')],
        },
        {
          section: [
            '北三环六村堡加油站“司机之家”站点内外均设置了大量醒目标识及宣传图文、张贴摆放了各类警示提醒标语。不仅做到方便司机们能够一目了然地看到站点位置以享受服务，同时也能够积极引导货车司机建会入会，推动实现“规范经营 依法承运；安全驾驶 文明出行；共创共建 和谐之家”。',
          ],
          sectionImg: [getServiceImagePath('press/partyNewsDetail1/img5.png')],
        },
        {
          section: [
            '除了提供“司机之家”基础服务之外，货达网络同时通过自主研发“运销宝APP”平台，在线上为货车司机们提供了全生态服务，主要包括：1.车货智能匹配方案（货源与运力撮合，将派单承运、运费结算等全流程线上化）；2.信息共享服务（货源/排队/在途等数据信息，实时在线更新）；3.场景增值服务（主要提供加油加气等车后服务及运费保理、融资贷款等金融服务）',
            '未来，我们将以“一流设施、一流管理、一流服务”原则为抓手，强化“司机之家”规范管理与运营，持续提高服务质量和水平，同时以“喝口热水、吃口热饭、洗个热水澡、睡个安稳觉”为实现目标，不断完善服务设施、创新服务模式，为促进道路货运行业与陕西高质量健康发展而贡献力量！',
          ],
          sectionImg: [],
        },
      ],
    },
    5: {
      title: '货达网络公司组织党员观看“二十大”开幕会！',
      date: '2022/10/18 12:34:56',
      article: [
        {
          section: [''],
          sectionImg: [getServiceImagePath('press/partyNewsDetail2/img1.png')],
        },
        {
          section: [
            '2022年10月16日上午10:00，中国共产党第二十次全国代表大会在北京人民大会堂隆重开幕。货达党支部积极号召组织全体党员干部观看了开幕盛况，学习聆听习总书记代表第十九届中央委员会向大会所作的报告。',
          ],
          sectionImg: [getServiceImagePath('press/partyNewsDetail2/img2.png')],
        },
        {
          section: [
            '中国共产党第二十次全国代表大会，是在全党全国各族人民迈上全面建设社会主义现代化国家新征程、向第二个百年奋斗目标进军的关键时刻召开的一次十分重要的大会。习近平总书记所作报告思想深刻，内涵丰富，深刻阐述了过去五年的工作和新时代十年的伟大变革，对鼓舞和动员全党全军全国各族人民坚持和发展中国特色社会主义、全面建设社会主义现代化国家、全面推进中华民族伟大复兴具有重要意义。',
            '货达党支部党员干部在收看了开幕会后一致表示，要把学习贯彻党的二十大精神作为当前和今后一段时间的首要政治任务，不忘初心，牢记使命，守正创新、奋发进取，传承好、发扬好、宣传好党的思想与优良传统。',
          ],
          sectionImg: [getServiceImagePath('press/partyNewsDetail2/img3.png')],
        },
        {
          section: [
            '货达网络公司将深刻领会党的二十大报告的深刻内涵，切实提高政治站位，把思想和行动统一到党的二十大精神上来。将党的建设引领工作与企业的实际业务相结合，持续打造以数字供应链为核心的大宗商品生态圈，为新型基础设施建设再立新功。努力成为工业互联网基础设施的建设者，大宗商品数字供应链的推动者，网络货运行业标准化的制定者，为推动陕西物流业高质量发展，做出新的贡献！',
          ],
          sectionImg: [],
        },
      ],
    },
    6: {
      title: '货达党支部开展员工慰问活动',
      date: '2022/1/8',
      article: [
        {
          sonTitle: '疫情无情人有情，做员工最坚强的后盾',
        },
        {
          inscribeTitle: '--货达党支部开展员工慰问活动',
        },
        {
          section: [
            '2021年岁末，古都西安突遭疫情，来势汹汹，牵动了亿万中国人的心。为防止疫情扩散，政府紧急下令全城封锁。货达党支部响应号召，第一时间通知所有员工居家防疫。',
            '01月08日，封城已有20天，货达党支部开展了“防疫情，有温情”活动，慰问居家隔离员工。鉴于疫情封控的局限性，党支部迅速组织党员干部及积极分子对公司近300名员工线上发送《致员工的一封信》并逐一进行电话慰问。',
            '党支部积极了解员工的居家隔离情况，电话慰问员工及其家属的身体状况、隔离情况以及心理状态，倾听员工想法和诉求，了解他们在生活上是否存在困难，需要什么样的帮助，鼓励他们克服暂时的不便，保持良好心态，以此来缓解他们的紧张情绪，以确保员工队伍稳定，全力打造众志成城抗击新冠疫情的良好氛围。',
            '党支部叮嘱员工们一定要遵守社区有关防疫制度，悉心照顾好自己及家人，按时准确上报体温，安排好隔离期间的日常生活，祝愿疫情早日清除，大家重新相聚。',
          ],
          sectionImg: [getServiceImagePath('press/partyNewsDetail3/img1.png')],
        },
      ],
    },
    // 5: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 6: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 7: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 8: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 9: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 10: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 11: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
    // 12: {
    //   title: '',
    //   date: '// ::',
    //   article: [
    //     {
    //       section: [''],
    //       sectionImg: []
    //     },

    //   ]
    // },
  }

  return { newsPartyDetailData }
}

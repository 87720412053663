import "ant-design-vue/es/button/style/css";
import _Button from "ant-design-vue/es/button";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted } from 'vue';
import { Goods } from '@/utils/scrollWatch';
export default defineComponent({
  components: {
    'a-button': _Button
  },
  props: {
    tabList: {
      type: Array,
      required: true
    },
    componentName: {
      type: String,
      required: true
    }
  },
  emits: ['cutTab'],
  setup(props, {
    emit
  }) {
    const currentIndex = ref(0);
    const currentClickIndex = ref('null');
    const handleCutTab = (item, index) => {
      currentIndex.value = index;
      currentClickIndex.value = index;
      emit('cutTab', {
        componentName: item.componentName,
        currentIndex
      }, e => {});
    };
    onMounted(() => {
      const bannerTabs = document.getElementById('banner-tabs');
      if (bannerTabs && bannerTabs.offsetParent) {
        Goods.dispatch('elementHeight', {
          bannerTabsOffsetTop: bannerTabs.offsetTop,
          bannerTabsParentHeight: bannerTabs.offsetParent.clientHeight
        });
      }
    });
    const newsGather = reactive({
      tabList: props.tabList,
      handleCutTab,
      currentIndex,
      currentClickIndex
    });
    const cm = computed(() => {
      return props.componentName;
    });
    watch(cm, (n, o) => {
      const index = props.tabList.findIndex(el => el.componentName === n);
      if (index > -1) {
        currentIndex.value = index;
      }
    });
    const setActiveTab = name => {
      const index = props.tabList.findIndex(el => el.componentName === name);
      if (index > -1) {
        currentIndex.value = index;
      }
    };
    return {
      ...toRefs(newsGather),
      setActiveTab
    };
  }
});
import "ant-design-vue/es/carousel/style/css";
import _Carousel from "ant-design-vue/es/carousel";
import "ant-design-vue/es/modal/style/css";
import _Modal from "ant-design-vue/es/modal";
import { defineComponent, ref } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: {
    'a-modal': _Modal,
    'a-carousel': _Carousel,
    LeftCircleOutlined,
    RightCircleOutlined
  },
  setup(props, ctx) {
    const visible = ref(false);
    const detailsData = ref({});
    const boxRef = ref();
    const useEvent = () => {
      return {
        open(data) {
          detailsData.value = {
            ...data
          };
          visible.value = true;
        }
      };
    };
    return {
      visible,
      detailsData,
      boxRef,
      ...useEvent()
    };
  }
});
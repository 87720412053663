import { createGoods } from '@/utils/goods'

const homeGoods = createGoods({
  state: {
    menuActive: {
      name: 'coal',
      active: true,
    },
    elementHeight: {},
    footer: {
      name: 'footer_content',
      el: undefined,
      showTime: false,
    },

    teamWork: {
      name: 'team-work',
      el: undefined,
      showTime: false,
      position: undefined,
    },
    aboutHd: {
      name: 'aboutHd',
      el: undefined,
      showTime: false,
    },
    // 首页四模块
    dateStaticState: {
      name: 'date-static',
      el: undefined,
      showTime: false,
      position: undefined,
    },
    projectColumnState: {
      name: 'project-column',
      el: undefined,
      showTime: false,
      position: undefined,
    },
    aboutHdState: {
      name: 'about-hd',
      el: undefined,
      showTime: false,
      position: undefined,
    },
    teamWorkState: {
      name: 'team-work',
      el: undefined,
      showTime: false,
      position: undefined,
    },
  },
  getter: {
    menuActive(state) {
      return state.menuActive
    },
    elementHeight(state) {
      return state.elementHeight
    },
    footer(state) {
      return state.footer
    },
    teamWork(state) {
      return state.teamWork
    },
    aboutHd(state) {
      return state.aboutHd
    },
    // 首页四模块
    dateStaticState(state) {
      return state.dateStaticState
    },
    projectColumnState(state) {
      return state.projectColumnState
    },
    aboutHdState(state) {
      return state.aboutHdState
    },
    teamWorkState(state) {
      return state.teamWorkState
    },
  },
  action: {
    menuActive(commit, value) {
      commit(value)
    },
    elementHeight(commit, value) {
      commit(value)
    },
    footer(commit, value) {
      commit(value)
    },
    teamWork(commit, value) {
      commit(value)
    },
    aboutHd(commit, value) {
      commit(value)
    },
    // 首页四模块
    dateStaticState(commit, value) {
      commit(value)
    },
    projectColumnState(commit, value) {
      commit(value)
    },
    aboutHdState(commit, value) {
      commit(value)
    },
    teamWorkState(commit, value) {
      commit(value)
    },
  },
})

export default homeGoods

import "core-js/modules/es.array.push.js";
import { defineComponent, ref, watch } from 'vue';
import drawer from '@/components/drawer/index.vue';
import { Menu, SubMenu, MenuItem, MenuItemGroup } from 'ant-design-vue/es';
import { useRouter } from 'vue-router';
export default defineComponent({
  components: {
    'i-drawer': drawer,
    'a-menu': Menu,
    'a-sub-menu': SubMenu,
    'a-menu-item-group': MenuItemGroup,
    'a-menu-item': MenuItem
  },
  setup(props) {
    const navigationIcon = require('@/assets/img/navigation-icon.png');
    const router = useRouter();
    const visible = ref(false);
    const useDrawer = function () {
      const openMenuHandle = () => {
        //   console.log('openMenuHandle')
        visible.value = true;
      };
      return {
        visible,
        openMenuHandle
      };
    };
    const useMenu = () => {
      const menuClickHandle = e => {
        console.log('useMenu', useMenu);
      };
      return {
        menuClickHandle
      };
    };
    const selectedKeys = ref(['home']);
    const openKeys = ref(['']);
    const handleClick = e => {
      router.push(`/${e.key}`);
      setTimeout(() => {
        visible.value = false;
      }, 200);
    };
    const titleClick = e => {
      console.log('titleClick', e);
    };
    watch(() => openKeys, val => {
      console.log('openKeys', val);
    });
    return {
      navigationIcon,
      ...useDrawer(),
      ...useMenu(),
      selectedKeys,
      openKeys,
      handleClick,
      titleClick
    };
  }
});
import { Goods } from "../scrollWatch"
const pressScroll = (scroll) => {
    scroll('team-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('teamContent', { showTime: showTime })
    })
    scroll('welfare-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('welfareContent', { showTime: showTime })
    })
    scroll('develop-content', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('developContent', { showTime: showTime })
    })
}

export default pressScroll
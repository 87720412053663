import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { getEnergyCardList, getCenterAdvanceList } from '../schema/energy';
export default defineComponent({
  setup(props, ctx) {
    const windowGlobal = Goods.getters('windowGlobal');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const state = reactive({
      bannerHeight,
      height,
      boxPadding
    });
    const useBusiness = reactive({
      energyCardList: getEnergyCardList(),
      centerAdvanceList: getCenterAdvanceList(),
      sysImg1: require('@/assets/img/product/energy/sysimg1.png'),
      sysImg2: require('@/assets/img/product/energy/sysimg2.png'),
      sysImg3: require('@/assets/img/product/energy/sysimg3.png')
    });
    const energyIdffContent = Goods.getters('energyIdffContent');
    const energyPlatformContent = Goods.getters('energyPlatformContent');
    const energyChartContent = Goods.getters('energyChartContent');
    const energySoftwareContent = Goods.getters('energySoftwareContent');
    const energyAdvanceContent = Goods.getters('energyAdvanceContent');
    const energyUseContainer = reactive({
      energyIdffContent,
      energyPlatformContent,
      energyChartContent,
      energySoftwareContent,
      energyAdvanceContent
    });
    return {
      ...toRefs(energyUseContainer),
      ...toRefs(state),
      ...toRefs(useBusiness)
    };
  }
});
import { createGoods } from "@/utils/goods";

const homeGoods = createGoods({
    state: {
        companyInfo: {
            name: 'company-info',
            el: undefined,
            showTime: false,
            join: false,
        },
        honorContent: {
            name: 'honor-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        courseContent: {
            name: 'course-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        connectUs: {
            name: 'connect-us',
            el: undefined,
            showTime: false,
            join: false,
        },
    },
    getter: {
        companyInfo(state) {
            return state.companyInfo
        },
        honorContent(state) {
            return state.honorContent
        },
        courseContent(state) {
            return state.courseContent
        },
        connectUs(state) {
            return state.connectUs
        },
    },
    action: {
        companyInfo(commit, value) {
            commit(value)
        },
        honorContent(commit, value) {
            commit(value)
        },
        courseContent(commit, value) {
            commit(value)
        },
        connectUs(commit, value) {
            commit(value)
        }
    }
})

export default homeGoods
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_ConfigProvider = _resolveComponent("ConfigProvider");
  return _openBlock(), _createBlock(_component_ConfigProvider, {
    "prefix-cls": "ant",
    locale: _ctx.locale,
    getPopupContainer: _ctx.getPopupContainer
  }, {
    renderEmpty: _withCtx(() => []),
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(({
        Component
      }) => [_createVNode(_Transition, {
        mode: "out-in"
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
        _: 2
      }, 1024)]),
      _: 1
    })]),
    _: 1
  }, 8, ["locale", "getPopupContainer"]);
}
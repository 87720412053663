import { unref } from 'vue'
import { Goods } from '../scrollWatch'
import { Debounce, Throttle } from '../operator'
const homeScroll = (scroll) => {
  scroll('footer_content', function (flag) {
    const { showTime, join } = flag
    Goods.dispatch('footer', { showTime: showTime })
  })
  scroll('project-column', function (flag) {
    const { showTime, join } = flag
    Goods.dispatch('projectColumnState', { showTime: showTime })
  })
  scroll('date-static', function (flag) {
    const { showTime, join } = flag
    Goods.dispatch('dateStaticState', { showTime: showTime })
  })

  scroll('about-hd', function (flag) {
    const { showTime, join, el, position } = flag

    const team = Goods.getters('aboutHdState')
    if (!unref(team).join) {
      if (join) {
        // position()
      }
    }
    Goods.dispatch('aboutHdState', { showTime: showTime, join })
  })

  const th = Throttle(function (flag) {
    const { showTime, join, el, position } = flag
    const team = Goods.getters('teamWorkState')
    if (!unref(team).join) {
      if (join) {
        // position()
      }
    }
    //  else if (unref(team).join) {
    // }
    Goods.dispatch('teamWorkState', { showTime: showTime, join })
  }, 800)
  scroll('team-work', th)
}

export default homeScroll

import { Goods } from "../scrollWatch"
import { unref } from 'vue'
const pressScroll = (scroll) => {
    scroll('press-session-grid', function (flag) {
        const { showTime } = flag
        Goods.dispatch('pressGrid', { showTime: showTime })
    })

    scroll('session-press', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('sessionPress', { showTime: showTime })
    })
    scroll('arcical-detail', function (flag) {
        const { showTime, join } = flag
        Goods.dispatch('arcicalDetail', { showTime: showTime })
    })
}

export default pressScroll
function getPowerList() {
  const powerList = [
    // {
    //   img: require('@/assets/img/product/power/power1.png'),
    //   title: '缺乏有效监管',
    //   text: '煤源地监管机制不健全， 质量及安全治理无法保障'
    // },
    {
      img: require('@/assets/img/product/power/power2.png'),
      title: '在途状态不可控',
      text: '货物路途安全未知，无法实时跟踪监控'
    },
    {
      img: require('@/assets/img/product/power/power3.png'),
      title: '信息共享不及时',
      text: '运输进度信息滞后，无法实现精准调运'
    },
    {
      img: require('@/assets/img/product/power/power4.png'),
      title: '核心环节耗时长',
      text: '车辆入厂环节繁琐，排队待位、人工验票效率低'
    },
    {
      img: require('@/assets/img/product/power/power5.png'),
      title: '精细化管理困难',
      text: '数据分析不全面，无法深入业务环节展开精细管理'
    },
  ]
  return powerList
}

function getPowerSuperList() {
  const superList = [
    {
      img: require('@/assets/img/product/power/power-super1.png'),
      title: '精准调运',
      text: '根据合同订单及实际需求制定调运方案， 加强电厂、承运商、司机间协作能力， 提高调运计划的执行效率，保证计划时效性'
    },
    {
      img: require('@/assets/img/product/power/power-super2.png'),
      title: '煤源地监管',
      text: '北斗/GPS双模精准定位，煤源地电子 围栏监管'
    },
    {
      img: require('@/assets/img/product/power/power-super3.png'),
      title: '矿发数据采集',
      text: '在电子围栏区域内将发货重量以点对点 加密方式写入车载定位设备'
    },
    {
      img: require('@/assets/img/product/power/power-super4.png'),
      title: '在途实时监管',
      text: '通过车载定位设备实时监控汽运过程， 及时感知风险并进行报警，通过可视化 路径分析等手段提升风险防控水平'
    },
    {
      img: require('@/assets/img/product/power/power-super5.png'),
      title: '现场管理自动化',
      text: '自动排队叫号、入厂自动验票，提升电厂 接卸过程的自动化、无纸化水平'
    },
    {
      img: require('@/assets/img/product/power/power-super6.png'),
      title: '软硬件一体化',
      text: '智运盒、智运天线、防拆盒等国家专利 电子设备产品，超长待机使用'
    },
  ]
  return superList
}




export {
  getPowerList,
  getPowerSuperList
}

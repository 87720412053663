export const getHomeCardList = () => {
  const cooperativeCustomer = [
    require('@/assets/img/home/customer/customer-01.png'),
    require('@/assets/img/home/customer/customer-02.png'),
    require('@/assets/img/home/customer/customer-03.png'),
    require('@/assets/img/home/customer/customer-04.png'),
    require('@/assets/img/home/customer/customer-05.png'),
    require('@/assets/img/home/customer/customer-06.png'),
    require('@/assets/img/home/customer/customer-07.png'),
    require('@/assets/img/home/customer/customer-08.png'),
    require('@/assets/img/home/customer/customer-09.png'),
    require('@/assets/img/home/customer/customer-10.png'),
    require('@/assets/img/home/customer/customer-11.png'),
    require('@/assets/img/home/customer/customer-12.png'),
    require('@/assets/img/home/customer/customer-13.png'),
    require('@/assets/img/home/customer/customer-14.png'),
    require('@/assets/img/home/customer/customer-15.png'),
    require('@/assets/img/home/customer/customer-16.png'),
    require('@/assets/img/home/customer/customer-17.png'),
    require('@/assets/img/home/customer/customer-18.png')
  ]
  return { cooperativeCustomer }
}

import { createGoods } from "@/utils/goods";

const financeGoods = createGoods({
    state: {
        industry: {
            name: 'industry',
            el: undefined,
            showTime: false,
            join: false,
        },
        theme: {
            name: 'theme',
            el: undefined,
            showTime: false,
            join: false,
        },
        safeness: {
            name: 'safeness',
            el: undefined,
            showTime: false,
            join: false,
        },
        loan: {
            name: 'loan',
            el: undefined,
            showTime: false,
            join: false,
        }
    },
    getter: {
        industry(state) {
            return state.industry
        },
        theme(state) {
            return state.theme
        },
        safeness(state) {
            return state.safeness
        },
        loan(state) {
            return state.loan
        }
    },
    action: {
        industry(commit, value) {
            commit(value)
        },
        theme(commit, value) {
            commit(value)
        },
        safeness(commit, value) {
            commit(value)
        },
        loan(commit, value) {
            commit(value)
        }
    }
})

export default financeGoods
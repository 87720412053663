import { defineComponent, ref, computed } from 'vue';
import { SkeletonImage } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    SkeletonImage
  },
  props: {
    path: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const IS = ref({
      [props.path]: ''
    });
    //   console.log('props.path', props.path)
    const isActive = computed(() => {
      // console.log('IS.value[props.path]', IS.value, IS.value[props.path])
      return IS.value[props.path] === 'ok' ? true : false;
    });
    const imgOnload = res => {
      if (Reflect.has(IS.value, props.path)) {
        IS.value[props.path] = 'ok';
      }
    };
    return {
      isActive,
      IS,
      imgOnload
    };
  }
});
import { Goods } from "../scrollWatch"
const figureEnertgyScroll = (scroll) => {
    scroll('industry', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('industry', { showTime: showTime })
        const team = Goods.getters('industry')
    })
    scroll('theme', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('theme', { showTime: showTime })
        const team = Goods.getters('theme')
    })
    scroll('safeness', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('safeness', { showTime: showTime })
        const team = Goods.getters('safeness')
    })
    scroll('loan', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('loan', { showTime: showTime })
        const team = Goods.getters('loan')
    })
}

export default figureEnertgyScroll
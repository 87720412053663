import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { getRailCardList, getCenterAdvanceList } from '../schema/rail';
export default defineComponent({
  setup(props, ctx) {
    const windowGlobal = Goods.getters('windowGlobal');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const state = reactive({
      bannerHeight,
      height,
      boxPadding
    });
    const railContainer = Goods.getters('railContainer');
    const idffContent = Goods.getters('idffContent');
    const platformContent = Goods.getters('platformContent');
    const chartContent = Goods.getters('chartContent');
    const softwareContent = Goods.getters('softwareContent');
    const advanceContent = Goods.getters('advanceContent');
    const useContainer = reactive({
      railContainer,
      idffContent,
      platformContent,
      chartContent,
      softwareContent,
      advanceContent
    });
    const useBusiness = reactive({
      railCardList: getRailCardList(),
      centerAdvanceList: getCenterAdvanceList(),
      sysImg1: require('@/assets/img/product/rail/sysimg1.png'),
      sysImg2: require('@/assets/img/product/rail/sysimg2.png'),
      sysImg3: require('@/assets/img/product/rail/sysimg3.png')
    });
    return {
      ...toRefs(useContainer),
      ...toRefs(state),
      ...toRefs(useBusiness)
    };
  }
});
import { createGoods } from "@/utils/goods";




const projectGoods = createGoods({
    state: {
        customProject: {
            name: 'team-work',
            el: undefined,
            showTime: false,
            position: undefined
        }
    },
    getter: {
        customProject(state) {
            return state.customProject
        }
    },
    action: {
        customProject(commit, value) {
            commit(value)
        }
    }
})

export default projectGoods
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import index from '@/pages/layout/mobile.vue'
import { createNameComponent } from './createNode'

import mobileProductRoute from './modules/mobileProductRoute'
import mobilePressRoute from './modules/mobilePressRoute'
import mobileUsRoute from './modules/mobileUsRoute'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: index,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: createNameComponent(() => import('../views-mobile/home/index.vue')),
      },
      ...mobileProductRoute,
      {
        path: '/project',
        name: 'Project',
        component: createNameComponent(() => import('../views-mobile/project/index.vue')),
      },
      {
        path: '/press',
        name: 'Press',
        component: createNameComponent(() => import('../views-mobile/press/index.vue')),
      },
      ...mobilePressRoute,
      {
        path: '/us',
        name: 'Us',
        component: createNameComponent(() => import('../views-mobile/us/index.vue')),
      },
      ...mobileUsRoute
    ],
  },
]

// console.log('process.env.BASE_URL', createWebHistory(process.env.BASE_URL))
const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior: (to, form, savePosition) => {
    return {
      top: 0,
    }
  },
})

export default router

const iwindow: IWindow = window

const deviceChange = function (e) {
  console.error('ddeviceChange', e, 1)
  window.location.reload()
}

document.addEventListener('deviceChange', deviceChange)
window.onunload = function () {
  document.removeEventListener('deviceChange', deviceChange)
}

if (!iwindow.MobileProps || !iwindow.MobileProps.isMobile) {
  require('./app')
} else {
  require('./mobile')
}

import Project from '@/views/project/index.vue'

const projectRoute = [
  {
    path: '/project',
    name: 'Project',
    component: Project,
  },
]

export default projectRoute

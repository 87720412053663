import { defineComponent, ref, reactive, toRefs } from 'vue';
import { Goods } from '@/utils/scrollWatch';
import { getCoalCardList, getCenterAdvanceList } from '../schema/coal';
export default defineComponent({
  setup() {
    const windowGlobal = Goods.getters('windowGlobal');
    const height = ref(Number(windowGlobal.windowHeight) - 60 + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const idffContentState = Goods.getters('idffContent');
    const transportSaleSystemState = Goods.getters('transportSaleSystem');
    const softViewState = Goods.getters('softView');
    const deviceContentState = Goods.getters('deviceContent');
    const centerContentState = Goods.getters('centerContent');
    const state = reactive({
      height,
      projectList: [],
      coalCardList: getCoalCardList(),
      centerAdvanceList: getCenterAdvanceList(),
      boxPadding,
      bannerHeight,
      sysImg: require('@/assets/img/product/syste-img.png'),
      coalFlowlabelActive: 1,
      coalFlowImgActive: 1,
      coalFlowImg1: require('@/assets/img/product/coal-flow-img01.png'),
      coalFlowImg2: require('@/assets/img/product/coal-flow-img02.png'),
      businessImg1: require('@/assets/img/product/business-img01.png'),
      businessImg2: require('@/assets/img/product/business-img02.png'),
      softViewImg: require('@/assets/img/product/soft-view01.png')
    });
    //   Goods.getters()
    const useEvent = () => {
      const coalFlowMouseEnterHandle = index => {
        state.coalFlowlabelActive = index;
        state.coalFlowImgActive = index;
      };
      const coalFlowMouseClickHandle = index => {
        state.coalFlowlabelActive = index;
        state.coalFlowImgActive = index;
      };
      return {
        coalFlowMouseEnterHandle,
        coalFlowMouseClickHandle
      };
    };
    return {
      ...toRefs(state),
      idffContentState,
      transportSaleSystemState,
      softViewState,
      deviceContentState,
      centerContentState,
      ...useEvent()
    };
  }
});
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09e0dad0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "session-1",
  id: "session-press"
};
const _hoisted_2 = {
  class: "label-left"
};
const _hoisted_3 = {
  class: "title-content me-sub-title dis-space-1"
};
const _hoisted_4 = {
  style: {
    "margin-top": "5px"
  }
};
const _hoisted_5 = {
  class: "dis dis-space-1 me-content-lt"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  class: "more"
};
const _hoisted_8 = {
  class: "day"
};
const _hoisted_9 = {
  clsss: "year-month me-content"
};
const _hoisted_10 = {
  class: "img-right"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  class: "session-grid",
  id: "press-session-grid"
};
const _hoisted_13 = {
  class: "custom-slick-arrow",
  style: {
    "left": "10px",
    "z-index": "1"
  }
};
const _hoisted_14 = {
  class: "custom-slick-arrow",
  style: {
    "right": "10px"
  }
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = {
  class: "item-img-box"
};
const _hoisted_17 = ["src", "alt"];
const _hoisted_18 = {
  class: "item-info-box dis-space-2"
};
const _hoisted_19 = {
  class: "info-day me-ml-title"
};
const _hoisted_20 = {
  class: "info-year-month"
};
const _hoisted_21 = {
  key: 0,
  class: "press-card"
};
const _hoisted_22 = ["onClick"];
const _hoisted_23 = {
  class: "item-img-box"
};
const _hoisted_24 = ["src", "alt"];
const _hoisted_25 = {
  class: "item-info-box dis-space-2"
};
const _hoisted_26 = {
  class: "me-content"
};
const _hoisted_27 = {
  class: "info-day me-ml-title"
};
const _hoisted_28 = {
  class: "info-year-month me-content-lt"
};
const _hoisted_29 = {
  key: 1,
  class: "press-card"
};
const _hoisted_30 = ["onClick"];
const _hoisted_31 = {
  class: "item-img-box"
};
const _hoisted_32 = ["src", "alt"];
const _hoisted_33 = {
  class: "item-info-box dis-space-2"
};
const _hoisted_34 = {
  class: "me-content"
};
const _hoisted_35 = {
  class: "info-day me-ml-title"
};
const _hoisted_36 = {
  class: "info-year-month me-content-lt"
};
const _hoisted_37 = {
  key: 2,
  class: "press-card"
};
const _hoisted_38 = ["onClick"];
const _hoisted_39 = {
  class: "item-img-box"
};
const _hoisted_40 = ["src", "alt"];
const _hoisted_41 = {
  class: "item-info-box dis-space-2"
};
const _hoisted_42 = {
  class: "me-content"
};
const _hoisted_43 = {
  class: "info-day me-ml-title"
};
const _hoisted_44 = {
  class: "info-year-month me-content-lt"
};
const _hoisted_45 = {
  key: 3,
  class: "press-card"
};
const _hoisted_46 = ["onClick"];
const _hoisted_47 = {
  class: "item-img-box"
};
const _hoisted_48 = ["src", "alt"];
const _hoisted_49 = {
  class: "item-info-box dis-space-2"
};
const _hoisted_50 = {
  class: "me-content"
};
const _hoisted_51 = {
  class: "info-day me-ml-title"
};
const _hoisted_52 = {
  class: "info-year-month me-content-lt"
};
const _hoisted_53 = {
  key: 4,
  class: "press-card"
};
const _hoisted_54 = ["onClick"];
const _hoisted_55 = {
  class: "item-img-box"
};
const _hoisted_56 = ["src", "alt"];
const _hoisted_57 = {
  class: "item-info-box dis-space-2"
};
const _hoisted_58 = {
  class: "me-content"
};
const _hoisted_59 = {
  class: "info-day me-ml-title"
};
const _hoisted_60 = {
  class: "info-year-month me-content-lt"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_button = _resolveComponent("a-button");
  const _component_left_circle_outlined = _resolveComponent("left-circle-outlined");
  const _component_right_circle_outlined = _resolveComponent("right-circle-outlined");
  const _component_a_carousel = _resolveComponent("a-carousel");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["content", {
      'session-1-entry': _ctx.ssessionPressShowTime.showTime
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["title me-content-lt", {
      'me-sub-title': _ctx.ssessionPressShowTime.showTime
    }])
  }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(_ctx.newsCards.newsOneTitle), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.newsCards.newsTowTitle), 1)])], 2), _createElementVNode("div", _hoisted_5, [_ctx.getSectionDataType(_ctx.newsCards.newsContent) === 'string' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    innerHTML: _ctx.newsCards.newsContent
  }, null, 8, _hoisted_6)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("label", null, [_createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.newsCards.newsDays), 1), _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.newsCards.newsYearAndMonth), 1)]), _createVNode(_component_a_button, {
    type: "default",
    class: "btn-more",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.goNewsDetail(_ctx.newsCards.newsId))
  }, {
    default: _withCtx(() => [_createTextVNode("了解更多")]),
    _: 1
  })])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
    src: _ctx.newsCards.newsImgSrc
  }, null, 8, _hoisted_11)])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_a_carousel, {
    arrows: "",
    class: _normalizeClass(["carousel-content", {
      'session-grid-entry': _ctx.grid.showTime
    }]),
    dotsClass: "dots-panel"
  }, {
    prevArrow: _withCtx(() => [_createElementVNode("div", _hoisted_13, [_createVNode(_component_left_circle_outlined)])]),
    nextArrow: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createVNode(_component_right_circle_outlined)])]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["press-card", {
        'session-grid-entry': _ctx.grid.showTime
      }])
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCards_one, (item, index) => {
      return _openBlock(), _createElementBlock("label", {
        class: "card-item",
        key: index,
        onClick: $event => _ctx.goNewsDetail(item.newsId)
      }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("img", {
        class: "news-img",
        src: item.newsImgSrc,
        alt: item.newsTitle
      }, null, 8, _hoisted_17)]), _createElementVNode("span", _hoisted_18, [_createElementVNode("label", null, _toDisplayString(item.newsTitle), 1), _createElementVNode("label", null, [_createElementVNode("span", _hoisted_19, _toDisplayString(item.newsDays), 1), _createElementVNode("span", _hoisted_20, _toDisplayString(item.newsYearAndMonth), 1)])])], 8, _hoisted_15);
    }), 128))], 2), _ctx.newsCards_two.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_21, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCards_two, (item, index) => {
      return _openBlock(), _createElementBlock("label", {
        class: "card-item",
        key: index,
        onClick: $event => _ctx.goNewsDetail(item.newsId)
      }, [_createElementVNode("div", _hoisted_23, [_createElementVNode("img", {
        class: "news-img",
        src: item.newsImgSrc,
        alt: item.newsTitle
      }, null, 8, _hoisted_24)]), _createElementVNode("span", _hoisted_25, [_createElementVNode("label", _hoisted_26, _toDisplayString(item.newsTitle), 1), _createElementVNode("label", null, [_createElementVNode("span", _hoisted_27, _toDisplayString(item.newsDays), 1), _createElementVNode("span", _hoisted_28, _toDisplayString(item.newsYearAndMonth), 1)])])], 8, _hoisted_22);
    }), 128))])) : _createCommentVNode("", true), _ctx.newsCards_three.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_29, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCards_three, (item, index) => {
      return _openBlock(), _createElementBlock("label", {
        class: "card-item",
        key: index,
        onClick: $event => _ctx.goNewsDetail(item.newsId)
      }, [_createElementVNode("div", _hoisted_31, [_createElementVNode("img", {
        class: "news-img",
        src: item.newsImgSrc,
        alt: item.newsTitle
      }, null, 8, _hoisted_32)]), _createElementVNode("span", _hoisted_33, [_createElementVNode("label", _hoisted_34, _toDisplayString(item.newsTitle), 1), _createElementVNode("label", null, [_createElementVNode("span", _hoisted_35, _toDisplayString(item.newsDays), 1), _createElementVNode("span", _hoisted_36, _toDisplayString(item.newsYearAndMonth), 1)])])], 8, _hoisted_30);
    }), 128))])) : _createCommentVNode("", true), _ctx.newsCards_four.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_37, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCards_four, (item, index) => {
      return _openBlock(), _createElementBlock("label", {
        class: "card-item",
        key: index,
        onClick: $event => _ctx.goNewsDetail(item.newsId)
      }, [_createElementVNode("div", _hoisted_39, [_createElementVNode("img", {
        class: "news-img",
        src: item.newsImgSrc,
        alt: item.newsTitle
      }, null, 8, _hoisted_40)]), _createElementVNode("span", _hoisted_41, [_createElementVNode("label", _hoisted_42, _toDisplayString(item.newsTitle), 1), _createElementVNode("label", null, [_createElementVNode("span", _hoisted_43, _toDisplayString(item.newsDays), 1), _createElementVNode("span", _hoisted_44, _toDisplayString(item.newsYearAndMonth), 1)])])], 8, _hoisted_38);
    }), 128))])) : _createCommentVNode("", true), _ctx.newsCards_five.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_45, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCards_five, (item, index) => {
      return _openBlock(), _createElementBlock("label", {
        class: "card-item",
        key: index,
        onClick: $event => _ctx.goNewsDetail(item.newsId)
      }, [_createElementVNode("div", _hoisted_47, [_createElementVNode("img", {
        class: "news-img",
        src: item.newsImgSrc,
        alt: item.newsTitle
      }, null, 8, _hoisted_48)]), _createElementVNode("span", _hoisted_49, [_createElementVNode("label", _hoisted_50, _toDisplayString(item.newsTitle), 1), _createElementVNode("label", null, [_createElementVNode("span", _hoisted_51, _toDisplayString(item.newsDays), 1), _createElementVNode("span", _hoisted_52, _toDisplayString(item.newsYearAndMonth), 1)])])], 8, _hoisted_46);
    }), 128))])) : _createCommentVNode("", true), _ctx.newsCards_Six.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_53, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsCards_Six, (item, index) => {
      return _openBlock(), _createElementBlock("label", {
        class: "card-item",
        key: index,
        onClick: $event => _ctx.goNewsDetail(item.newsId)
      }, [_createElementVNode("div", _hoisted_55, [_createElementVNode("img", {
        class: "news-img",
        src: item.newsImgSrc,
        alt: item.newsTitle
      }, null, 8, _hoisted_56)]), _createElementVNode("span", _hoisted_57, [_createElementVNode("label", _hoisted_58, _toDisplayString(item.newsTitle), 1), _createElementVNode("label", null, [_createElementVNode("span", _hoisted_59, _toDisplayString(item.newsDays), 1), _createElementVNode("span", _hoisted_60, _toDisplayString(item.newsYearAndMonth), 1)])])], 8, _hoisted_54);
    }), 128))])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["class"])])], 2);
}
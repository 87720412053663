import "ant-design-vue/es/button/style/css";
import _Button from "ant-design-vue/es/button";
import "core-js/modules/es.array.push.js";
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue';
import { Goods } from '@/utils/scrollWatch';
import Cooperation from '../businessWidget/cooperation.vue';
import { getHomeCardList } from './schema/homeCard';
import ConsultModal from '@/views/home/component/consultModal.vue';
import { useRouter } from 'vue-router';
import footer from '@/pages/layout/components/footer.vue';
export default defineComponent({
  components: {
    'a-button': _Button,
    Cooperation,
    ConsultModal,
    'b-footer': footer
  },
  setup() {
    //   console.log(Goods.getters('windowGlobal'))
    const windowGlobal = Goods.getters('windowGlobal');
    const boxPadding = ref(windowGlobal.boxPadding);
    const height = ref(Number(windowGlobal.windowHeight) - 60 + 'px');
    const home01 = require('@/assets/img/home-01.png');
    const media = require('@/assets/img/home-01.png');
    const {
      cooperativeCustomer
    } = getHomeCardList();
    const state = reactive({
      aboutLineImg: require('@/assets/img/about-hd/line.png'),
      aboutPlatformImg: require('@/assets/img/about-hd/platfrom.png'),
      windowHeight: windowGlobal.windowHeight - 40,
      windowWidth: windowGlobal.windowWidth,
      boxPadding,
      height,
      home01,
      projectList: [],
      cooperativeCustomer,
      toElementContainer: () => {},
      activeSliderIndex: 0
    });
    state.projectList = [{
      title: '煤炭行业',
      name: '智能运销管理系统',
      rightContent: {
        title: '煤炭行业',
        sonTitle: '智能运销管理系统',
        describe: '构建煤炭运销业务全流程闭环管理模式，杜绝人为作弊行为，实现煤炭运 销业务精细化管理，帮助企业降本增效。',
        img: require('@/assets/img/home/scheme/img1.png'),
        path: 'coal'
      }
    }, {
      title: '电力行业',
      name: '智能调运管理系统',
      rightContent: {
        title: '电力行业',
        sonTitle: '智能调运管理系统',
        describe: '通过打造软硬件一体化解决方案，实现车辆运输过程的实时动态监控，有效防控货物风险，保证运单时效性。',
        img: require('@/assets/img/home/scheme/img1.png'),
        path: 'power'
      }
    }, {
      title: '化工行业',
      name: '采销管理系统',
      rightContent: {
        title: '化工行业',
        sonTitle: '采销管理系统',
        describe: '提供采购和销售线上管理服务，优化业务流程，实现课上管理、无人值守称重管理、结算支付等管控目标。',
        img: require('@/assets/img/home/scheme/img3.png'),
        path: 'chemical'
      }
    }, {
      title: '铁路货运',
      name: '数字化铁路站台',
      rightContent: {
        title: '铁路货运',
        sonTitle: '数字化铁路站台',
        describe: '为铁路集运站整车、散货装运，采购备货，站内短倒等全流程业务设计研发完备的系统功能，支持多端协作，实现站台场景数字化。',
        img: require('@/assets/img/home/scheme/img4.png'),
        path: 'rail'
      }
    }
    // {
    //   title: '能源监管',
    //   name: '能源行业综合管理系统',
    //   rightContent: {
    //     title: '能源监管',
    //     sonTitle: '能源行业综合管理系统',
    //     describe:
    //       '优化能源行业监管 模式，打造“车单码”三流合一管理架构，规范企业生产及纳税行为，堵塞税费漏洞，增加财政收入',
    //     img: require('@/assets/img/home/scheme/img5.png'),
    //     path: 'energy',
    //   },
    // },
    ];

    const currentScheme = ref({
      title: '煤炭行业',
      sonTitle: '智能运销管理系统',
      describe: '构建煤炭运销业务全流程闭环管理模式，杜绝人为作弊行为，实现煤炭运 销业务精细化管理，帮助企业降本增效。',
      img: require('@/assets/img/home/scheme/img1.png')
    });
    const currentSchemeIndex = ref(0);
    const consultModalRef = ref();
    const router = useRouter();
    const useEvent = () => {
      return {
        openConsultModdal: () => {
          consultModalRef.value.open();
        },
        cutScheme: (item, index) => {
          currentScheme.value = item.rightContent;
          currentSchemeIndex.value = index;
        },
        goLink: (item, url = '/product') => {
          router.push(url);
          Goods.dispatch('menuActive', {
            name: item.rightContent.path,
            active: true
          });
        }
      };
    };
    const dateStaticState = Goods.getters('dateStaticState');
    const projectColumnState = Goods.getters('projectColumnState');
    const aboutHdState = Goods.getters('aboutHdState');
    const teamWorkState = Goods.getters('teamWorkState');
    const useContainer = reactive({
      dateStaticState,
      projectColumnState,
      aboutHdState,
      teamWorkState
    });
    onMounted(() => {
      const _window = window;
      const Swiper = _window.Swiper;
      const certifySwiper = new Swiper('#home-container .swiper-container', {
        height: window.innerHeight,
        direction: 'vertical',
        //   slidesPerView: 'auto', //自适应宽度
        spaceBetween: 0,
        speed: 800,
        loop: false,
        //   loopedSlides: 3,
        mousewheel: {
          eventsTarged: '#home-container'
        },
        freeModeMomentumBounceRatio: 3,
        mousewheelControl: true,
        scrollbar: {
          el: '.swiper-scrollbar'
        },
        on: {
          slideChangeTransitionStart: function (e) {
            //   const el: HTMLElement = e.$el[0]
            //   console.log('touchEnd', this.activeIndex)
            setTimeout(() => {
              state.activeSliderIndex = this.activeIndex;
            }, 200);
          }
        }
      });
    });
    return {
      ...toRefs(state),
      ...toRefs(useContainer),
      consultModalRef,
      currentScheme,
      currentSchemeIndex,
      ...useEvent()
    };
  }
});
import "ant-design-vue/es/config-provider/style/css";
import _ConfigProvider from "ant-design-vue/es/config-provider";
import { defineComponent } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import scrollWatch from './utils/scrollWatch';
export default defineComponent({
  name: 'AppView',
  components: {
    ConfigProvider: _ConfigProvider
  },
  setup(props, ctx) {
    scrollWatch();
    const getPopupContainer = dialogContext => {
      dayjs.locale('zh-cn');
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    };
    const transformCellText = ({
      text,
      column,
      record,
      index
    }) => {
      // console.log('transformCellText', text, index)
      return record;
    };
    return {
      locale: zhCN,
      transformCellText,
      getPopupContainer
    };
  }
});
//   import { CloseOutlined } from '@ant-design/icons-vue/es'
import { CloseOutlined } from '@ant-design/icons-vue';
import { defineComponent, watchEffect } from 'vue';
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 200
    }
  },
  components: {
    CloseOutlined
  },
  setup(props, {
    emit
  }) {
    console.log(props);
    //   watch(props.visible, (n, o) => {
    //     console.log('n', n)
    //   })
    watchEffect(() => {
      if (props.visible) {
        unScroll();
      } else {
        removeUnScroll();
      }
    }, {
      flush: 'post'
    });
    const useEvent = function () {
      return {
        closeHandle() {
          emit('update:visible', false);
        },
        wallClick() {
          emit('update:visible', false);
        }
      };
    };
    function unScroll() {
      const outer = document.createElement('div');
      const inner = document.createElement('div');
      outer.style.overflow = 'scroll';
      document.body.appendChild(outer);
      outer.appendChild(inner);
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
      document.body.removeChild(outer);
      $(document.body).css('padding-right', scrollbarWidth);
      $(document.body).css('overflow', 'hidden');
    }
    function removeUnScroll() {
      $(document.body).css('overflow-Y', 'auto');
      $(document.body).css('padding-right', '0px');
    }
    return {
      ...useEvent()
    };
  }
});
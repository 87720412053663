import { defineComponent, ref, reactive, toRefs } from 'vue';
import { Button, Row, Col, Tabs, TabPane } from 'ant-design-vue/es';
import { Goods } from '@/utils/scrollWatch';
import Cooperation from '@/views/businessWidget/cooperation.vue';
import { getProductInfoList, getSceneCardList } from '../schema/freight';
import { SwapRightOutlined } from '@ant-design/icons-vue';
import ConsultModal from '@/views/home/component/consultModal.vue';
import ReferenceTable1 from './components/ReferenceTable1.vue';
import ReferenceTable2 from './components/ReferenceTable2.vue';
import ReferenceInfo1 from './components/ReferenceInfo1.vue';
import ReferenceInfo2 from './components/ReferenceInfo2.vue';
import ReferenceInfo3 from './components/ReferenceInfo3.vue';
755800266571;
export default defineComponent({
  components: {
    'a-tab-pane': TabPane,
    'a-tabs': Tabs,
    'a-row': Row,
    'a-col': Col,
    SwapRightOutlined,
    Cooperation,
    'a-button': Button,
    ConsultModal,
    ReferenceTable1,
    ReferenceTable2,
    ReferenceInfo1,
    ReferenceInfo2,
    ReferenceInfo3
  },
  setup() {
    const windowGlobal = Goods.getters('windowGlobal');
    const height = ref(Number(windowGlobal.windowHeight) - 60 + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const state = reactive({
      height,
      boxPadding,
      bannerHeight,
      productInfoList: getProductInfoList(),
      productInfoRightImg: require('@/assets/img/product/freight/freight01.png'),
      sceneCardList: getSceneCardList(),
      currentImg: require('@/assets/img/product/freight/number/img1.png'),
      currentIndex: 0,
      imgArr: [require('@/assets/img/product/freight/number/img1.png'), require('@/assets/img/product/freight/number/img2.png'), require('@/assets/img/product/freight/number/img3.png'), require('@/assets/img/product/freight/number/img4.png'), require('@/assets/img/product/freight/number/img5.png')]
    });
    const consultModalRef = ref();
    const activeKey = ref('1');
    const useEvent = () => {
      const productInfoMouseHandle = item => {
        state.productInfoList.forEach(el => el.active = false);
        state.productInfoRightImg = item.img;
        item.active = true;
      };
      const clickLink = index => {
        state.currentImg = state.imgArr[index];
        state.currentIndex = index;
      };
      const openConsultModdal = () => {
        consultModalRef.value.open();
      };
      return {
        productInfoMouseHandle,
        clickLink,
        openConsultModdal
      };
    };
    return {
      activeKey,
      ...toRefs(state),
      consultModalRef,
      ...useEvent()
    };
  }
});
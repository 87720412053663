const getRailCardList = function () {
    const list = [
        {
            title: '全流程管控困难',
            content: '采购、销售、代发、站内短倒、站台租用业务种类流程复杂，难以实现统一管理',
            img: require('@/assets/img/product/rail/img1.png'),
        },
        {
            title: '进出场运输混乱',
            content: '车辆拉运、装卸任务不同导致装卸错货等问题时有发生，“杂乱差”痛点突显',
            img: require('@/assets/img/product/rail/img2.png'),
        },
        {
            title: '人工成本居高不下',
            content: '伪造称重数据、结算计量等环节人员冗杂、人工成本高且易出错',
            img: require('@/assets/img/product/rail/img3.png'),
        },
        {
            title: '业务转型面临挑战',
            content: '路站台治理运营模式陈旧，数字化发展需求迫切，传统架构亟待转型',
            img: require('@/assets/img/product/rail/img4.png'),
        },
    ]

    return list
}

const getCenterAdvanceList = function () {
    const list = [
        {
            title: '合同线上管理',
            content: '采购、销售、代发合同计划关联，执行情况 自动跟踪，可针对客户进行精细管理',
            img: require('@/assets/img/product/center-01.png'),
        },
        {
            title: '站台业务统一管理',
            content: '实现站台销售、代发、短倒业务统一流程管 理，实现从汽运到铁运运输过程信息化管理',
            img: require('@/assets/img/product/center-02.png'),
        },
        {
            title: '车辆、集装箱在线调度',
            content: '对运输车辆、集装箱进行实时全局调度管理， 避免人工错误',
            img: require('@/assets/img/product/center-05.png'),
        },
        {
            title: '现场管理智能化',
            content: '车辆进入区域自动排队叫号，自动识别验票 有序入厂',
            img: require('@/assets/img/product/center-03.png'),
        },
        {
            title: '磅房称重无人化',
            content: '上磅前自动识别车辆信息，红外对射防作弊， 称重数据自动采集传输',
            img: require('@/assets/img/product/center-04.png'),
        },
        {
            title: '铁运计划与请车计划',
            content: '实现铁运计划与装火车信息化管理，请车计 划实时查看',
            img: require('@/assets/img/product/rail/center6.png'),
        },
    ]

    return list
}

export { getRailCardList, getCenterAdvanceList }

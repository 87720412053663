import { createGoods } from "@/utils/goods";

const figureEnertgyGoods = createGoods({
    state: {
        station: {
            name: 'station',
            el: undefined,
            showTime: false,
            join: false,
        },
        target: {
            name: 'target',
            el: undefined,
            showTime: false,
            join: false,
        },
    },
    getter: {
        station(state) {
            return state.station
        },
        target(state) {
            return state.target
        }
    },
    action: {
        station(commit, value) {
            commit(value)
        },
        target(commit, value) {
            commit(value)
        }
    }
})

export default figureEnertgyGoods
import "ant-design-vue/es/carousel/style/css";
import _Carousel from "ant-design-vue/es/carousel";
import "ant-design-vue/es/button/style/css";
import _Button from "ant-design-vue/es/button";
import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { getProjectCardList } from './schema/projectCard';
import ConsultModal from '@/views/home/component/consultModal.vue';
import detailModal from './component/projectModal.vue';
export default defineComponent({
  components: {
    'a-button': _Button,
    'a-carousel': _Carousel,
    LeftCircleOutlined,
    RightCircleOutlined,
    detailModal,
    ConsultModal
  },
  setup() {
    const windowGlobal = Goods.getters('windowGlobal');
    const customProjectState = Goods.getters('customProject');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 60 + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const detailModalRef = ref();
    const {
      projectListOne,
      projectListTwo
    } = getProjectCardList();
    const state = reactive({
      bannerHeight,
      height,
      boxPadding,
      projectListOne,
      projectListTwo
    });
    const consultModalRef = ref();
    const useEvent = () => {
      return {
        openDetailHandle: item => {
          detailModalRef.value.open(item);
        },
        openConsultModdal: () => {
          consultModalRef.value.open();
        }
      };
    };
    return {
      detailModalRef,
      consultModalRef,
      customProjectState,
      ...toRefs(state),
      ...useEvent()
    };
  }
});
import { ref, unref } from 'vue'
import { Logger } from './index'
import { Debounce } from './operator'

import agoods from '@/goods/home'
import financeGoods from '@/goods/product/finance'
import chemicalGoods from '@/goods/product/chemical'
import railGoods from '@/goods/product/rail'
import energyGoods from '@/goods/product/energy'
import figureEnertgyGoods from '@/goods/product/figureEnertgy'
import aboutGoods from '@/goods/us/about'
import joinGoods from '@/goods/us/join'
import projectGoods from '@/goods/project'
import coalGoods from '@/goods/product/coal'
import powerGoods from '@/goods/product/power'

import preesGoods from '@/goods/press'
import homeScroll from './scrollEvent/home'
import pressScroll from './scrollEvent/press'
import financeScroll from './scrollEvent/finance'
import chemicaScroll from './scrollEvent/chemical'
import energyScroll from './scrollEvent/energy'
import coalScroll from './scrollEvent/coal'
import powerScroll from './scrollEvent/power'
import figureEnertgyScroll from './scrollEvent/figureEnertgy'
import projectScroll from './scrollEvent/project'
import railScroll from './scrollEvent/rail'
import about from './scrollEvent/about'
import join from './scrollEvent/join'
const Goods = (function () {
  const windowHeight = document.documentElement.clientHeight
  const windowWidth = document.documentElement.clientWidth

  const bannerTabs = document.getElementById('banner-tabs')

  const ag: any = agoods()
  agoods()
  preesGoods()
  financeGoods()
  chemicalGoods()
  railGoods()
  energyGoods()
  figureEnertgyGoods()
  aboutGoods()
  joinGoods()

  projectGoods()
  coalGoods()
  powerGoods()

  // 1299   660
  const state = {}

  const getters = {
    windowGlobal(): IwindowGlobal {
      const windowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let padidngValue = 120
      if (windowHeight < 800) {
        padidngValue = 90
      }
      if (windowWidth < 1200) {
        padidngValue = 50
      }
      return {
        windowScrollTop,
        windowWidth,
        windowHeight,
        windowBlockHeight: windowHeight - 60,
        boxPadding: `0 ${padidngValue}px`,
      }
    },
  }

  const dispatch = {}

  function dispatchState(key, value) {
    const action = {
      scrollToTop() {
        // console.log('  ag.elementHeight', ag.elementHeight.value)
        const elementHeight = ag.elementHeight.value
        const bannerTabsParentHeight = elementHeight.bannerTabsParentHeight
        const bannerTabsOffsetTop = elementHeight.bannerTabsOffsetTop
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        const _window: any = window

        if (bannerTabsOffsetTop < scrollTop + 30) {
          if (_window.$) {
            const $ = _window.$
            $('html').animate(
              {
                scrollTop: bannerTabsParentHeight - 70,
              },
              500,
            )
          }
        }
      },
    }

    return action[key]
  }

  return {
    getters: function (key) {
      if (getters[key]) {
        return getters[key]()
      } else {
        return ag[key]
      }
    },
    dispatch: (key, value) => {
      const fn = dispatchState(key, value)
      if (fn) {
        fn()
      } else {
        ag.dispatch(key, value)
      }
    },
  }
})()

function elementAction() {}

function checkElementExist(id, cb) {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  const windowHeight = document.documentElement.clientHeight // 视窗高度-也就是浏览器可视区域高度
  const curh = windowHeight + scrollTop
  //   console.log('curh', curh, y)
  const el = document.getElementById(id)
  if (el) {
    var { x, y } = getElementTopByScroll(id)
    const elHeight = el.offsetHeight
    // console.log('el', document.documentElement.scrollTop)

    // console.log(
    //   'elHeight',
    //   elHeight,
    //   'y ==== ' + y,
    //   'window height==' + windowHeight + ' scrollTop ' + scrollTop + ' all height ' + curh,
    // )

    // Logger.red(id + '', curh - y, curh)
    // console.log('scrollTop/', 2.001 / 2)

    if (curh - y > 10) {
      //   console.log('hahahahahahahah=====.')
      let join = false
      if (curh - y > 100) {
        join = true
      }

      //   console.log('(curh - y) / curh', (curh - y) / curh)

      //   if ((curh - y) / elHeight > 1.1) {
      //     console.log('超出自己', (curh - y) / elHeight)
      //     join = false
      //   }
      cb({
        el,
        showTime: true,
        join,
      })
    } else if (Number((scrollTop / elHeight / 2).toFixed(3)) > 1.001) {
      cb({
        showTime: false,
        join: false,
      })
    } else {
      cb({
        showTime: false,
        join: false,
      })
    }
  }
}

function getElementTopByScroll(id) {
  const elem = document.getElementById(id)
  const scrollPos = elementPosition(elem)
  //   console.log('scrollPos', scrollPos)
  return scrollPos
}

function elementPosition(obj) {
  let curleft = 0,
    curtop = 0
  if (obj.offsetParent) {
    curleft = obj.offsetLeft
    curtop = obj.offsetTop
    while ((obj = obj.offsetParent)) {
      curleft += obj.offsetLeft
      curtop += obj.offsetTop
    }
  }
  return { x: curleft, y: curtop }
}

export default function () {
  window.onscroll = function (e) {
    homeScroll(checkElementExist)
    pressScroll(checkElementExist)
    financeScroll(checkElementExist)
    chemicaScroll(checkElementExist)
    figureEnertgyScroll(checkElementExist)
    energyScroll(checkElementExist)
    railScroll(checkElementExist)
    about(checkElementExist)
    join(checkElementExist)
    projectScroll(checkElementExist)
    coalScroll(checkElementExist)
    powerScroll(checkElementExist)
  }
}

export { Goods }

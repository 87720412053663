import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/index.vue'
import Press from '@/views/press/index.vue'
// import Us from '@/views/us/index.vue'

import productRoute from './modules/productRoute'
import projectRoute from './modules/projectRoute'

import index from '@/pages/layout/index.vue'

import NewsDetailView from '@/views/press/components/newsDetail/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: index,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
      },
      ...productRoute,
      ...projectRoute,

      {
        path: '/press',
        name: 'Press',
        component: Press,
      },
      {
        path: '/newsDetail',
        name: 'NewsDetail',
        component: NewsDetailView,
      },
      {
        path: '/us',
        name: 'Us',
        component: () => import(/* webpackChunkName: "us" */ '@/views/us/index.vue'),
      },
    ],
  },
]

// console.log('process.env.BASE_URL', createWebHistory(process.env.BASE_URL))
const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior: (to, form, savePosition) => {
    return {
      top: 0,
    }
  },
})

export default router

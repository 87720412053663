export const tabList = [
    {
        label: "公司新闻",
        componentName: 'CompanyNews',
        isBright: true
    },
    {
        label: "党群建设",
        componentName: 'PartyNews',
        isBright: false
    },
    {
        label: "企业文化",
        componentName: 'EnterpriseNews',
        isBright: false
    },
]

import { createGoods } from "@/utils/goods";

const homeGoods = createGoods({
    state: {
        teamContent: {
            name: 'team-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        welfareContent: {
            name: 'welfare-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        developContent: {
            name: 'develop-content',
            el: undefined,
            showTime: false,
            join: false,
        }
    },
    getter: {
        teamContent(state) {
            return state.teamContent
        },
        welfareContent(state) {
            return state.welfareContent
        },
        developContent(state) {
            return state.developContent
        }
    },
    action: {
        teamContent(commit, value) {
            commit(value)
        },
        welfareContent(commit, value) {
            commit(value)
        },
        developContent(commit, value) {
            commit(value)
        }
    }
})

export default homeGoods
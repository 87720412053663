import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-beabefa0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "banner-tabs",
  id: "banner-tabs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_button = _resolveComponent("a-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tabItem, tabIndex) => {
    return _openBlock(), _createBlock(_component_a_button, {
      onClick: $event => _ctx.handleCutTab(tabItem, tabIndex),
      key: tabIndex,
      type: "link",
      class: _normalizeClass(["tabs-btn", [_ctx.currentIndex === tabIndex ? 'font-color-primary' : 'tab-basic', _ctx.currentClickIndex === tabIndex ? 'animation-class' : '']])
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(tabItem.label), 1)]),
      _: 2
    }, 1032, ["onClick", "class"]);
  }), 128))]);
}
import "ant-design-vue/es/button/style/css";
import _Button from "ant-design-vue/es/button";
import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs, watch, onMounted } from 'vue';
import coal from './coal/index.vue';
import chemical from './chemical/index.vue';
import energy from './energy/index.vue';
import power from './power/index.vue';
import rail from './rail/index.vue';
import Cooperation from '../businessWidget/cooperation.vue';
import SonBannerTabs from '@/components/SonBannerTabs';
import ConsultModal from '@/views/home/component/consultModal.vue';
export default defineComponent({
  name: 'productView',
  components: {
    'a-button': _Button,
    coal,
    power,
    chemical,
    rail,
    energy,
    Cooperation,
    SonBannerTabs,
    ConsultModal
  },
  setup() {
    const windowGlobal = Goods.getters('windowGlobal');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const menuActive = Goods.getters('menuActive');
    const bannerTabsRef = ref();
    const state = reactive({
      bannerHeight,
      height,
      boxPadding,
      menuActive,
      currentActiveMenu: 'coal'
    });
    //   console.log('currentActiv')
    const useTabs = function () {
      const tabList = [{
        label: '煤炭行业',
        componentName: 'coal',
        isBright: true
      }, {
        label: '电力行业',
        componentName: 'power',
        isBright: false
      }, {
        label: '化工行业',
        componentName: 'chemical',
        isBright: false
      }, {
        label: '铁路货站',
        componentName: 'rail',
        isBright: false
      }
      //   {
      //     label: '能源监管',
      //     componentName: 'energy',
      //     isBright: false,
      //   },
      ];

      const componentName = ref(tabList[0].componentName);
      const handleCutTab = (data, cbFn) => {
        console.log('data', data);
        componentName.value = data.componentName;
        bannerTabsRef.value.setActiveTab(data.componentName);
        state.currentActiveMenu = data.componentName;
      };
      onMounted(() => {
        //   console.log('onMounted menuActive.value.name', menuActive.value.name)
        bannerTabsRef.value.setActiveTab(menuActive.value.name);
        state.currentActiveMenu = menuActive.value.name;
      });
      watch(menuActive, (n, e) => {
        state.currentActiveMenu = n.name;
        bannerTabsRef.value.setActiveTab(n.name);
      });
      return {
        tabList,
        componentName,
        handleCutTab
      };
    };
    const consultModalRef = ref();
    const useEvent = () => {
      const openConsultModdal = () => {
        consultModalRef.value.open();
      };
      return {
        openConsultModdal
      };
    };
    return {
      bannerTabsRef,
      consultModalRef,
      ...useEvent(),
      ...toRefs(state),
      ...toRefs(useTabs())
    };
  }
});
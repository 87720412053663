import { SERVICE_TYPE, getRequest } from '@/plugins/axios'
import { aesParse, encrypt } from '@/utils/dataParse'
import { WH_CONFIG } from '../../../config/service'
import { AES, mode, pad, enc } from 'crypto-js'

// import { WH_CONFIG } from 'config/service'

export const saveInfo = async (params) => {
  const request = getRequest()
  const res = await request.post('/venus-desk/deskHuodada/save', params)
  if (res.success) {
    return res
  }
  return false
  // https://laas.yunxiaobao.com/api/venus-desk/deskHuodada/save
}

const getUrl = (url) => {
  const baseURL = WH_CONFIG[process.env.NODE_ENV]
  //   return baseURL + url

  //   return 'http://hdd-inner-api.hdd-yunxiaobao-pre:8281' + url
  return baseURL + url
  //   return url
}
export const checkShipmentCommentDriver = async () => {
  const axios = getRequest().axios
  const data = { aaa: '1111' }
  const as = aesParse(data)

  const res = await axios({
    url: getUrl('/shipment/checkShipmentCommentDriver'),
    method: 'post',
    data: {
      data: as,
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      merchantId: '24882f837d054354b9e1bf93fd3b0f11',
    },
  })

  if (res && Number(res.code) === 1000) {
    return res.data
  }
  // https://laas.yunxiaobao.com/api/venus-desk/deskHuodada/save
}
export const checkShipmentComplaint = async () => {
  const axios = getRequest().axios
  const data = { aaa: '1111' }
  const as = aesParse(data)

  const res = await axios({
    url: getUrl('/shipment/checkShipmentComplaint'),
    method: 'post',
    data: {
      data: as,
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      merchantId: '24882f837d054354b9e1bf93fd3b0f11',
    },
  })

  if (res && Number(res.code) === 1000) {
    return res.data
  }
}

// export { saveInfo }

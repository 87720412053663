const getChemicalList = function () {
    const chemicalList = [
      {
        title: '人为干扰影响收益',
        content: '现场管理比较粗放，人为因素影响大，容易产生误差、作弊等现象',
        img:require('@/assets/img/product/chemical/chemical1.png')
      },
      {
        title: '管理手段方式原始',
        content: '线上资料填录上传，0元免押一键申请，满足货车主出行需求',
        img:require('@/assets/img/product/chemical/chemical2.png')
      },
      {
        title: '信息孤岛问题严重',
        content: '业务流程不够规范，无法形成标准化内部控制体系以完成闭环管理',
        img:require('@/assets/img/product/chemical/chemical3.png')
      },
      {
        title: '产销周转效率低下',
        content: '产能与市场需求匹配不对位，成品周转效率低下影响整体利润表现',
        img:require('@/assets/img/product/chemical/chemical4.png')
      },
    ]
    const manageList = [
      { img:require('@/assets/img/product/chemical/chemical5.png') },
      { img:require('@/assets/img/product/chemical/chemical6.png') },
      { img:require('@/assets/img/product/chemical/chemical7.png') }
    ]
    const advantageList = [
      {
        title: '采销业务一体化',
        content: '采购与销售业务环节实现流程化、规范化管控，有效提高了采销效率',
      },
      {
        title: '数据信息电子化',
        content: '物流运输信息化程度低，非标纸质单据，数据共享难，形成数据孤岛',
      },
      {
        title: '场区管理智能化',
        content: '自动识别车辆信息及拉运任务，进场有序高效，防作弊计量',
      },
      {
        title: '资金结算线上化',
        content: '财务结算在线快速完成，过程安全有保障，流程清晰透明',
      },
    ]
    return { chemicalList, manageList, advantageList}
  }
  
  export {  getChemicalList }
import { defineComponent, reactive, toRefs } from 'vue';
import { Table } from 'ant-design-vue/es';
export default defineComponent({
  components: {
    'a-table': Table
  },
  setup() {
    const sharedOnCell = (_, index) => {
      if (index === 0) {
        return {
          rowSpan: 4
        };
      }
      if (index < 4) {
        return {
          colSpan: 0
        };
      }
    };
    const createTable1 = function () {
      const columnsOne = [{
        title: '评价指标',
        dataIndex: 'name',
        key: 'name',
        width: '30%'
      }, {
        title: '指标说明',
        dataIndex: 'info',
        key: 'info',
        width: '30%'
      }, {
        title: '评价方法',
        dataIndex: 'M',
        key: 'M',
        customCell: sharedOnCell
      }];
      const tableDataOne = [{
        key: '1',
        name: '准时装货',
        info: '司机到达装货地并完成装货的时效性',
        M: '由货主根据实际运输业务情况主观评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }, {
        key: '2',
        name: '准时卸货',
        info: '司机到达卸货地并完成卸货的时效性',
        M: '由货主根据实际运输业务情况主观评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }, {
        key: '3',
        name: '运输安全',
        info: '司机是否安全将货物运输至目的地',
        M: '由货主根据实际运输业务情况主观评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }, {
        key: '4',
        name: '服务态度',
        info: '货主对司机运输服务的满意程度',
        M: '由货主根据实际运输业务情况主观评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }];
      return {
        columnsOne,
        tableDataOne
      };
    };
    const createTable2 = function () {
      const columnsSecond = [{
        title: '评价指标',
        dataIndex: 'name',
        key: 'name',
        width: '30%'
      }, {
        title: '指标说明',
        dataIndex: 'info',
        key: 'info',
        width: '30%'
      }, {
        title: '评价方法',
        dataIndex: 'M',
        key: 'M',
        customCell: sharedOnCell
      }];
      const tableDataSecond = [{
        key: '1',
        name: '装卸货效率',
        info: '货主装卸货物的效率',
        M: '由司机根据实际运输业务情况主动评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }, {
        key: '2',
        name: '运费支付时效',
        info: '完成运输业务后货主支付运费的效率',
        M: '由司机根据实际运输业务情况主动评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }, {
        key: '3',
        name: '货主满意度',
        info: '司机对货主整体交易过程的满意程度',
        M: '由司机根据实际运输业务情况主动评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }, {
        key: '4',
        name: '平台满意度',
        info: '司机对平台相关服务的满意程度',
        M: '由司机根据实际运输业务情况主动评价，采取1-5星打分机制，单次评价星数取各维度星数平均值。'
      }];
      return {
        columnsSecond,
        tableDataSecond
      };
    };
    const state = reactive({
      ...createTable1(),
      ...createTable2()
    });
    return {
      ...toRefs(state)
    };
  }
});
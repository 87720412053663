import { ref, unref, isRef } from 'vue'

const createGoods = (goods) => {
  const { state, getter, action } = goods
  state && Goods.richState(state)
  getter && Goods.richGetter(getter)
  action && Goods.richAction(action)
  return () => {
    return Goods.getRichState()
  }
}

const Goods = (function () {
  const states = {}

  const getters = {}

  const actions = {}

  return {
    richAction: (action) => {
      // console.log('richaction', this);
      Object.entries(action).forEach(([key, value]) => {
        if (typeof value === 'function') {
          const fn = value.bind(states)
          actions[key] = fn
        }
      })
    },
    richGetter(getter) {
      Object.entries(getter).forEach(([key, value]) => {
        // console.log('key', key, value)
        if (typeof value === 'function') {
          // console.log('isfn');
          // const fn = value.bind(states)
          getters[key] = value(states)
        }
      })
    },
    richState(state) {
      Object.entries(state).forEach(([key, value]) => {
        // console.log('key', key, value)
        const o = ref(value)
        states[key] = o
      })
      // console.log('state', states);
    },
    getRichState() {
      return {
        ...getters,
        dispatch(key, value) {
          // console.log(key, states);
          if (states[key] && isRef(states[key])) {
            // console.log('dispatch, ', value);
            // unref(states[key])
            // states[key].value = value

            if (actions[key]) {
              actions[key](function (v) {
                states[key].value = v
              }, value)
              // console.log('actionskey', );
            }

            // unref(states[key]) = value
          }
        },
      }
    },
  }
})()

export { createGoods }

import "ant-design-vue/es/back-top/style/css";
import _BackTop from "ant-design-vue/es/back-top";
import { Layout, LayoutHeader, LayoutFooter, LayoutContent } from 'ant-design-vue/es';
import { defineComponent } from 'vue';
import footer from './components/mobile-footer.vue';
import header from './components/mobile-header.vue';
export default defineComponent({
  components: {
    Layout,
    LayoutHeader,
    LayoutFooter,
    LayoutContent,
    'bottom-footer': footer,
    'top-header': header,
    'a-back-top': _BackTop
  },
  setup(props, ctx) {
    return {};
  }
});
import { createNameComponent } from '../createNode'
const mobilePressRoute = [
  {
    path: '/companyNews',
    name: 'CompanyNews',
    component: createNameComponent(() => import('@/views-mobile/press/companyNews/index.vue')),
  },
  {
    path: '/enterpriseNews',
    name: 'EnterpriseNews',
    component: createNameComponent(() => import('@/views-mobile/press/enterpriseNews/index.vue')),
  },
  {
    path: '/partyNews',
    name: 'PartyNews',
    component: createNameComponent(() => import('@/views-mobile/press/partyNews/index.vue')),
  },
  {
    path: '/newsDetails',
    name: 'NewsDetails',
    component: createNameComponent(() => import('@/views-mobile/press/newsDetails.vue')),
  },
]
export default mobilePressRoute

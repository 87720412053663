const getEnergyCardList = function () {
    const list = [
        {
            title: '无票销售',
            content: '生产销售单位存在无票销售行为， 避税漏税问题严峻',
            img: require('@/assets/img/product/energy/img1.png'),
        },
        {
            title: '以次充好',
            content: '存在高价商品按低价商品销售现象， 干扰市场价格',
            img: require('@/assets/img/product/energy/img2.png'),
        },
        {
            title: '多拉少开',
            content: '拉运车辆实际拉运吨数与磅单吨数不符，运费结算不透明',
            img: require('@/assets/img/product/energy/img3.png'),
        },
        {
            title: '监管漏洞',
            content: '在监管方法上缺少技术抓手， 稽查过程存在漏洞',
            img: require('@/assets/img/product/energy/img4.png'),
        },
    ]

    return list
}

const getCenterAdvanceList = function () {
    const list = [
        {
            title: '称重数据准确化',
            content: '企业磅房系统与综合监管系统对接，称重数据实时 更新上传，杜绝人工填写防止作弊',
            img: require('@/assets/img/product/energy/center2.png'),
        },
        {
            title: '逃逸报警自动查',
            content: '矿厂、煤检站、边境等关键卡口/出口安装摄像机， 自动抓拍识别过往运输车辆',
            img: require('@/assets/img/product/energy/center3.png'),
        },
        {
            title: '磅单票据电子化',
            content: '将纸质运单转变为电子运单，降低其使用的损坏、 丢失、调换等风险，实现票据在线管控',
            img: require('@/assets/img/product/energy/center4.png'),
        },
        {
            title: '稽查便捷化',
            content: '稽查工作人员可通过小程序、APP等实时扫码查验 车辆信息，提高移动继超效率和准确性',
            img: require('@/assets/img/product/energy/center5.png'),
        },
    ]

    return list
}

export { getEnergyCardList, getCenterAdvanceList }

import { getServiceImagePath } from '@/utils'

export function useNewsOneShow() {
  const newsCards = {
    newsImgSrc: getServiceImagePath('press/party20230629/2023-06-29-1.jpeg'),
    newsOneTitle: '迎七一，庆祝建党102周年',
    newsTowTitle: '——货达党支部“凝聚红色力量、弘扬革命精神”主题党日活动',
    newsContent: '“迎七一，庆祝建党102周年”——货达党支部“凝聚红色力量、弘扬革命精神”主题党日活动。',
    newsYearAndMonth: '2023-06',
    newsDays: '29',
    newsId: 1,
  }
  return { newsCards }
}

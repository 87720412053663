import { Goods } from '@/utils/scrollWatch';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { getPowerList, getPowerSuperList } from '../schema/power';
export default defineComponent({
  setup(props, ctx) {
    const windowGlobal = Goods.getters('windowGlobal');
    const bannerHeight = ref(Number(windowGlobal.windowBlockHeight) - 90 + 'px');
    const height = ref(Number(windowGlobal.windowBlockHeight) + 'px');
    const boxPadding = ref(windowGlobal.boxPadding);
    const electricityPlightState = Goods.getters('electricityPlight');
    const intelligenceSystemState = Goods.getters('intelligenceSystem');
    const powerDetailedState = Goods.getters('powerDetailed');
    const softwareInterfaceState = Goods.getters('softwareInterface');
    const hardwareEquipmentState = Goods.getters('hardwareEquipment');
    const coreAdvantageState = Goods.getters('coreAdvantage');
    const state = reactive({
      bannerHeight,
      height,
      boxPadding
    });
    const useBusiness = reactive({
      powerList: getPowerList(),
      powerSuperList: getPowerSuperList(),
      sysImg1: require('@/assets/img/product/power/sysimg1.png'),
      sysImg2: require('@/assets/img/product/power/sysimg2.png'),
      sysImg3: require('@/assets/img/product/power/sysimg3.png'),
      equipmentImg1: require('@/assets/img/product/power/equipmentImg1.png'),
      equipmentImg2: require('@/assets/img/product/power/equipmentImg2.png'),
      equipmentImg3: require('@/assets/img/product/power/equipmentImg3.png')
    });
    return {
      ...toRefs(state),
      electricityPlightState,
      intelligenceSystemState,
      powerDetailedState,
      softwareInterfaceState,
      hardwareEquipmentState,
      coreAdvantageState,
      ...toRefs(useBusiness)
    };
  }
});
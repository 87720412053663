import { createGoods } from "@/utils/goods";

const homeGoods = createGoods({
    state: {
        railContainer: {
            name: 'rail-container',
            el: undefined,
            showTime: false,
            join: false,
        },
        idffContent: {
            name: 'idff-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        platformContent: {
            name: 'platform-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        chartContent: {
            name: 'chart-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        softwareContent: {
            name: 'software-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        advanceContent: {
            name: 'advance-content',
            el: undefined,
            showTime: false,
            join: false,
        }
    },
    getter: {
        railContainer(state) {
            return state.railContainer
        },
        idffContent(state) {
            return state.idffContent
        },
        platformContent(state) {
            return state.platformContent
        },
        chartContent(state) {
            return state.chartContent
        },
        softwareContent(state) {
            return state.softwareContent
        },
        advanceContent(state) {
            return state.advanceContent
        }
    },
    action: {
        railContainer(commit, value) {
            commit(value)
        },
        idffContent(commit, value) {
            commit(value)
        },
        platformContent(commit, value) {
            commit(value)
        },
        chartContent(commit, value) {
            commit(value)
        },
        softwareContent(commit, value) {
            commit(value)
        },
        advanceContent(commit, value) {
            commit(value)
        }
    }
})

export default homeGoods
import freightView from '@/viewsMobile/product/freight/index.vue'
// import ProductView from '@/viewsMobile/product/index.vue'
import FinanceView from '@/viewsMobile/product/finance/index.vue'
import FigureEnertgyView from '@/viewsMobile/product/figureEnertgy/index.vue'
import { createNameComponent } from '../createNode'
const mobileProductRoute = [
  {
    path: '/freight',
    name: 'Freight',
    component: createNameComponent(() => import('@/views-mobile/product/freight/index.vue')),
  },
  {
    path: '/finance',
    name: 'Finance',
    component: createNameComponent(() => import('@/views-mobile/product/finance/index.vue')),
  },
  {
    path: '/figureEnertgy',
    name: 'FigureEnertgy',
    component: createNameComponent(() => import('@/views-mobile/product/figureEnertgy/index.vue')),
  },
  {
    path: '/figureEnertgy',
    name: 'FigureEnertgy',
    component: createNameComponent(() => import('@/views-mobile/product/figureEnertgy/index.vue')),
  },
  /**  产品子菜单 */
  {
    //化工
    path: '/chemical',
    name: 'Chemical',
    component: createNameComponent(() => import('@/views-mobile/product/chemical/index.vue')),
  },
  {
    //铁路
    path: '/rail',
    name: 'Rail',
    component: createNameComponent(() => import('@/views-mobile/product/rail/index.vue')),
  },
  {
    //电力
    path: '/power',
    name: 'Power',
    component: createNameComponent(() => import('@/views-mobile/product/power/index.vue')),
  },
  {
    //煤炭
    path: '/coal',
    name: 'Coal',
    component: createNameComponent(() => import('@/views-mobile/product/coal/index.vue')),
  },
]

export default mobileProductRoute

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2b7f1482"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CloseOutlined = _resolveComponent("CloseOutlined");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: _normalizeClass(["drawer-container", {
      'drawer-container-show': _ctx.visible
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CloseOutlined, {
    class: "close-icon",
    onClick: _ctx.closeHandle
  }, null, 8, ["onClick"])]), _createElementVNode("section", null, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2), _createElementVNode("div", {
    class: _normalizeClass(["drawer-wall", {
      'drawer-wall-show': _ctx.visible
    }]),
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.wallClick && _ctx.wallClick(...args))
  }, null, 2)], 64);
}
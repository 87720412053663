const mediaSchema = [
  {
    hotline: '400-993-7878',
    imgPath: require('@/assets/img/home/footer/wximg.png'),
    info: '微信公众号',
    type: 'wx',
  },
  {
    hotline: '400-993-7878',
    imgPath: require('@/assets/img/home/footer/dy.png'),
    info: '抖音号：96936901772',
    type: 'dy',
  },
  {
    hotline: '400-993-7878',
    imgPath: require('@/assets/img/home/footer/wxsp.png'),
    info: '微信视频号',
    type: 'wxsp',
  },
  {
    hotline: '400-993-7878',
    imgPath: require('@/assets/img/home/footer/ks.png'),
    info: '快手ID：3696970748',
    type: 'ks',
  },
]

export { mediaSchema }

export const config = {
  development: 'http://laas.yunxiaobao.com/api',
  dev: 'http://laas.yunxiaobao.com',
  production: 'https://laas.yunxiaobao.com/api',
}

// http://39.97.232.126/

export const WH_CONFIG = {
  development: 'http://39.105.90.57:8281',
  dev: 'http://39.105.90.57:8281',
  production: 'https://inner-api.yunxiaobao.com',
  //   production: 'http://hdd-inner-api.hdd-yunxiaobao-prod:8281',
  //   production: 'https://test-inner-api.yunxiaobao.com',
}

// production: 'https://inner-api.yunxiaobao.com',

// https://laas.yunxiaobao.com/api/venus-desk/deskHuodada/save
// http://alpha-laas.yunxiaobao.com:8015

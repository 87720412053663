import { Goods } from "../scrollWatch"
const chemicalScroll = (scroll) => {
    scroll('station-chemical', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('stationChemical', { showTime: showTime })
        const team = Goods.getters('stationChemical')
    })
    scroll('management-chemical', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('managementChemical', { showTime: showTime })
        const team = Goods.getters('managementChemical')
    })
    scroll('advantage', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('advantage', { showTime: showTime })
        const team = Goods.getters('advantage')
    })
    scroll('interface-chemical', function (flag) {
        const { showTime, join, el } = flag
        Goods.dispatch('interfaceChemical', { showTime: showTime })
        const team = Goods.getters('interfaceChemical')
    })
}

export default chemicalScroll
import { createGoods } from "@/utils/goods";

const homeGoods = createGoods({
    state: {
        energyIdffContent: {
            name: 'energy-idff-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        energyPlatformContent: {
            name: 'energy-platform-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        energyChartContent: {
            name: 'energy-chart-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        energySoftwareContent: {
            name: 'energy-software-content',
            el: undefined,
            showTime: false,
            join: false,
        },
        energyAdvanceContent: {
            name: 'energy-advance-content',
            el: undefined,
            showTime: false,
            join: false,
        }
    },
    getter: {
        energyIdffContent(state) {
            return state.energyIdffContent
        },
        energyPlatformContent(state) {
            return state.energyPlatformContent
        },
        energyChartContent(state) {
            return state.energyChartContent
        },
        energySoftwareContent(state) {
            return state.energySoftwareContent
        },
        energyAdvanceContent(state) {
            return state.energyAdvanceContent
        }
    },
    action: {
        energyIdffContent(commit, value) {
            commit(value)
        },
        energyPlatformContent(commit, value) {
            commit(value)
        },
        energyChartContent(commit, value) {
            commit(value)
        },
        energySoftwareContent(commit, value) {
            commit(value)
        },
        energyAdvanceContent(commit, value) {
            commit(value)
        }
    }
})

export default homeGoods
import "ant-design-vue/es/back-top/style/css";
import _BackTop from "ant-design-vue/es/back-top";
import { Layout, LayoutHeader, LayoutFooter, LayoutContent } from 'ant-design-vue/es';
import { defineComponent, ref, watch } from 'vue';
import header from './components/header.vue';
import footer from './components/footer.vue';
import { useRoute } from 'vue-router';
import { Goods } from '@/utils/scrollWatch';
export default defineComponent({
  components: {
    Layout,
    LayoutHeader,
    LayoutFooter,
    LayoutContent,
    'bottom-footer': footer,
    'top-header': header,
    'a-back-top': _BackTop
  },
  setup(props, ctx) {
    const windowGlobal = Goods.getters('windowGlobal');
    const currentRouteName = ref();
    const route = useRoute();
    const boxPadding = ref(windowGlobal.boxPadding);
    watch(route, (n, o) => {
      // console.log('watch n', n)
      currentRouteName.value = n.name;
    });
    return {
      boxPadding,
      currentRouteName
    };
  }
});